import Router from 'public/components/Router'
import { Layout } from 'antd'
import { StyledPage } from './style'
import DefaultHeader from './DefaultHeader'
import PageContent from './PageContent'
import PageLayout from './PageLayout'
import useQueryApiClient from 'utils/useQueryApiClient'
import DefaultSecondHeader from './DefaultSecondHeader'
import Banner from './Banner'
import { useBannerDispatch, useProductDispatch } from '../../context'
import { Spinner } from 'ui'
import { useCategoryDispatch } from '../../context'
import DefaultFooter from './DefaultFooter/DefaultFooter'
import MobileHeader from '../MobileLayout/MobileHeader/MobileHeader'
import { useFarmDispatch } from 'public/context/FarmContext'
import MobileMenu from '../MobileLayout/MobileMenu/MobileMenu'
import CookieConsent from './CookieConsent'

const { Content } = Layout

const DefaultLayout = () => {
  const dispatchBanners = useBannerDispatch()
  const dispatchProducts = useProductDispatch()
  const dispatchCategory = useCategoryDispatch()

  const dispatchFarm = useFarmDispatch()

  const {
    refetch: refetchFarmCoordinates,
    isLoading: isLoadingFarmCoordinates,
  } = useQueryApiClient({
    request: {
      url: 'api/v2/map/farms',
    },
    onSuccess: (response) => {
      dispatchFarm({
        type: 'SAVE_PAYLOAD',
        payload: {
          farmsCoordinates: response,
          refetch: refetchFarmCoordinates,
        },
        isLoading: isLoadingFarmCoordinates,
      })
    },
  })

  const { refetch: refetchBanners, isLoading: isLoadingBanners } =
    useQueryApiClient({
      request: {
        url: 'api/v2/banners',
      },
      onSuccess: (response) => {
        dispatchBanners({
          type: 'SAVE_PAYLOAD',
          payload: { banners: response, refetch: refetchBanners },
          isLoading: isLoadingBanners,
        })
      },
    })

  const { refetch: refetchProducts, isLoading: isLoadingProducts } =
    useQueryApiClient({
      request: {
        url: 'api/v2/products/popular',
      },
      onSuccess: (response) => {
        dispatchProducts({
          type: 'SAVE_PAYLOAD',
          payload: { products: response, refetch: refetchProducts },
          isLoading: isLoadingProducts,
        })
      },
    })

  const {
    refetch: refetchProductsDiscount,
    isLoading: isLoadingProductsDiscount,
  } = useQueryApiClient({
    request: {
      url: 'api/v2/products/discounted',
    },
    onSuccess: (response) => {
      dispatchProducts({
        type: 'SAVE_PAYLOAD',
        payload: {
          productsDiscounted: response,
          refetch: refetchProductsDiscount,
        },
        isLoading: isLoadingProductsDiscount,
      })
    },
  })

  const { refetch: refetchCategory, isLoading: isLoadingCategory } =
    useQueryApiClient({
      request: {
        url: 'api/v2/categories/menu/desktop',
      },
      onSuccess: (response) => {
        dispatchCategory({
          type: 'SAVE_PAYLOAD',
          payload: { category: response, refetch: refetchCategory },
          isLoading: isLoadingCategory,
        })
      },
    })

  const {
    refetch: refetchPopularCategories,
    isLoading: isLoadingPopularCategories,
  } = useQueryApiClient({
    request: {
      url: 'api/v2/categories/popular',
    },
    onSuccess: (response) => {
      dispatchCategory({
        type: 'SAVE_PAYLOAD',
        payload: {
          popularCategory: response,
          refetch: refetchPopularCategories,
        },
        isLoading: isLoadingPopularCategories,
      })
    },
  })

  const { refetch: refetchProductsCount, isLoading: isLoadingProductsCount } =
    useQueryApiClient({
      request: {
        url: 'api/v2/products/get/count',
      },
      onSuccess: (response) => {
        dispatchProducts({
          type: 'SAVE_PAYLOAD',
          payload: {
            productsCounted: response,
            refetch: refetchProductsCount,
          },
          isLoading: isLoadingProductsCount,
        })
      },
    })

  const isLoading =
    isLoadingBanners ||
    isLoadingProducts ||
    isLoadingCategory ||
    isLoadingPopularCategories ||
    isLoadingProductsDiscount ||
    isLoadingProductsCount

  return (
    <>
      <CookieConsent />
      <StyledPage>
        <Layout className="layout">
          <Spinner spinning={isLoading} dontRender size={'large'}>
            <MobileHeader />
            <DefaultHeader />
            <DefaultSecondHeader />
            <Content>
              <Router />
            </Content>
            <DefaultFooter />
            <MobileMenu />
          </Spinner>
        </Layout>
      </StyledPage>
    </>
  )
}

DefaultLayout.PageLayout = PageLayout
DefaultLayout.PageContent = PageContent
DefaultLayout.Banner = Banner

export default DefaultLayout
