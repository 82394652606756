import styled from 'styled-components'

export const StyledProductView = styled.div`
  .desc {
    color: ${(props) => props.theme.backgroundColorFarmButton};
  }

  .product-tags {
    position: absolute;
    display: flex;
    top: 5px;
    width: 100%;
    justify-content: space-between;
    padding: 2px 0 0 8px;
    z-index: 10;
    align-items: center;

    .tags {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;

      .discount-tag {
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: ${(props) => props.theme.discountTagBg};
        border-radius: 2px;
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.white};
        width: 75px;
        height: 22px;
        background-color: ${(props) => props.theme.topPriceBgColor};
        font-size: 13px;
        border-radius: 2px;
      }
    }

    .add-to-favorites {
      color: ${(props) => props.theme.white};
      padding-right: 7px;
    }
  }

  .content-container {
    display: flex;
    flex-direction: column;
    .breadcrumb-mobile {
      display: none;
    }
    .mobile-product-images {
      display: none;
    }
    .product-slider {
      display: none;
    }

    .product-slider-container {
      .custom-title,
      .price {
        padding: 0 10px;
      }
      .slick-slide.slick-active {
        display: flex;
        justify-content: center;

        div div div {
          margin: 0;
        }
      }

      .slick-dots {
        padding: 30px 0 0;
      }

      .banner-image-fix {
        padding: 0 20px;
        div div {
          padding: 0 !important;

          .width-100 {
            height: 250px;
            border-radius: 5px;
          }
        }
      }
    }

    .show-all-products {
      display: none;
    }

    .d-flex {
      display: flex;
    }

    .flex-row {
      flex-direction: row;
    }

    .flex-col {
      flex-direction: column;
    }

    .flex-rcol {
      flex-direction: column-reverse;
    }

    .flex-center {
      align-items: center;
      justify-content: center;
    }

    .flex-gap {
      gap: 10px;
    }

    .ariel-sans {
      font-family: ${(props) => props.theme.fontText};
    }

    .light-gray {
      color: ${(props) => props.theme.grey};
    }

    .light-gray-background {
      background-color: ${(props) => props.theme.lightGrey};
    }

    .white-background {
      background-color: ${(props) => props.theme.white};
    }

    .view-product {
      padding: 30px;
      font-size: 16px;
      font-weight: 300;
    }

    .goto-btn {
      padding: 30px;
      font-size: 16px;
      font-weight: 700;
      width: 259px;
    }
    .customized {
      height: 52px !important;
    }

    .image-gallery-wrapper-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: ${(props) => props.theme.backgroundOverlay};
      z-index: 1000;
    }

    .image-gallery-wrapper {
      position: relative;
      width: 100%;
      max-width: 1000px;
      border-radius: 8px;
      padding: 20px;

      .close-image-btn {
        position: absolute;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 44px;
        height: 44px;
        border-radius: 4px;
        border: 1px solid ${(props) => props.theme.lightGrey};
        background-color: ${(props) => props.theme.white};
        top: 20px;
        right: -40px;
      }
    }

    .product-name-bread-wrapper {
      flex-grow: 1;
      border-bottom: 1px solid ${(props) => props.theme.borderBottom};
      padding-bottom: 20px;

      .breadcrumbs-container {
        padding: 0;

        .breadcrumb {
          cursor: pointer;
        }

        div {
          span {
            a {
              text-decoration: none;
              color: ${(props) => props.theme.black};
            }
          }
        }
      }

      .product-name-and-qmarks-container {
        justify-content: space-between;
        align-items: center;
        padding: 40px 0 0 0;

        .product-name {
          display: block !important;
          font-weight: 500;
          font-size: 42px;
        }

        .qmarks {
          img {
            width: 36px;
            height: 24px;
          }
        }
      }
    }

    .product-container {
      padding: 50px 0px;

      .photo-desc-price {
        display: flex;
        flex: 1;
        justify-content: space-between;
        gap: 15px;
        width: 100%;

        .photo {
          flex: 1;
          position: relative;
          margin-bottom: auto;

          img {
            width: 100%;
          }
          .zoom-svg-wrapper {
            position: absolute;
            right: 10px;
            bottom: 10px;
            img {
              height: auto;
            }
            .zoom-svg {
              cursor: pointer;
            }
          }
        }

        .desc-price {
          justify-content: space-between;
          flex: 2;

          .owner {
            color: ${(props) => props.theme.grey};
            text-decoration: underline;
            padding-bottom: 50px;
          }

          .mobile-name {
            display: none;
          }

          .price-grey {
            gap: 5px;
            font-size: 16px;
            font-weight: 400;
            color: ${(props) => props.theme.grey};
          }

          .price-main {
            gap: 5px;
            font-size: 30px;
            font-weight: 400;
          }
        }
      }

      .breadcrumb-add-to-cart-btn {
        justify-content: space-between;
        align-items: end;
        flex: 3;

        .select-ammount-container {
          width: 189px;
        }

        .breadcrumb {
          flex-grow: 1;
          min-width: max-content;

          .icon-bread {
            display: flex;
            flex-direction: row;
            gap: 5px;
            cursor: pointer;

            p {
              margin: 0;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .menu-container {
      padding: 0 0 50px 0;

      .ant-menu-item-selected > span {
        color: ${({ theme }) => theme.black};
        font-weight: 500;
      }

      .ant-menu-title-content {
        color: ${({ theme }) => theme.grey};
        font-weight: 500;
        font-family: ${(props) => props.theme.fontText};
      }
    }

    .content-section {
      padding: 15px;
      border-top: 1px solid ${(props) => props.theme.lightGrey};

      .ingredients,
      .delivery,
      .farm-owner,
      .reviews {
        margin-top: 20px;
        display: flex;
        flex-direction: column;

        .address {
          font-weight: normal;
        }

        .farm-wrap {
          display: flex;
          flex-direction: column;

          .goto {
            padding-top: 30px;
          }
        }

        .delivery-dropdown {
          position: relative;
          width: 100%;
        }

        .delivery-wrapper {
          min-height: 47px;
          align-items: center;

          .distribution-points,
          .parcel-terminals,
          .couriers {
            width: 100%;
          }

          .dropdown-header {
            min-height: 47px;
            align-items: center;
            justify-content: space-between;
            padding: 15px;
            cursor: pointer;
            font-weight: bold;
            border: 1px solid ${(props) => props.theme.lightGrey};
          }

          .dropdown-content {
            position: relative;
            width: 100%;
            border: 1px solid ${(props) => props.theme.lightGrey};

            table {
              margin: 15px;
              font-family: ${(props) => props.theme.fontText};
              font-size: 16px;
              min-width: 321px;
              border-spacing: 0;

              .td-day {
                padding-left: 15px;
                max-width: 65px;
              }

              tr {
                height: 44px;
              }

              td {
              }
            }

            p {
              margin: 0;
              padding: 15px;
            }
          }
        }

        .delivery {
          display: flex;
          flex-direction: column;
        }

        .text-center {
          display: flex;
          justify-content: center;
          align-items: center;
        }

        p {
          font-weight: 500;
          font-size: 16px;
        }
      }

      .nutrition-values {
        .value-nutrition {
          justify-content: center;
          align-items: center;
          border: 1px solid ${(props) => props.theme.lightGrey};

          .nutrition {
            flex: 1;
            padding: 13px;
          }

          .value {
            flex: 1;
          }
        }
      }
    }
  }

  .popular-products {
    padding: 66px 0 86px 0;

    .title h1 {
      margin-top: 0;
    }

    .grid-view {
      gap: 33px;
      padding: 0 !important;
      justify-content: start;

      @media only screen and (max-width: 1180px) {
        justify-content: center;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .menu-container {
      padding: 50px 0 !important;
    }

    .product-container {
      padding: 50px 0 !important;
    }

    .title {
      padding: 0 50px !important;
    }
    .mobile-name {
      display: block !important;
      order: -1;
      margin-bottom: 10px;
      font-weight: 800;
    }
  }

  @media only screen and (max-width: 1024px) {
    .breadcrumb {
      display: none;
    }
    .content-container {
      .breadcrumb-mobile {
        display: block;
        padding: 20px 50px;

        .icon-bread {
          display: flex;
          font-size: 14px;
          font-weight: 400;
          color: ${(props) => props.theme.black};
        }
      }
      .product-container {
        padding: 0 !important;

        .breadcrumb-add-to-cart-btn {
          justify-content: end !important;
        }
      }
    }
  }

  @media only screen and (max-width: 876px) {
    .content-container .product-container .photo-desc-price {
      grid-template-columns: repeat(2, 1fr);
      gap: 10px;
    }
    .breadcrumb-add-to-cart-btn {
      align-items: start !important;
    }
    .price-wrapp {
      margin-bottom: -50px;
    }
  }

  @media only screen and (max-width: 768px) {
    .content-container {
      .title {
        padding: 0 20px !important;
      }
      .product-container {
        padding: 0 !important;
      }
      .breadcrumb {
        display: none;
      }
      .photo img {
        border-radius: 0;
        width: 100%;
      }
    }
    .photo {
      display: flex;
      justify-content: start !important;
      align-items: start !important;
    }
    .breadcrumb-add-to-cart-btn {
    }
    .desc,
    .owner {
      text-align: start;
    }
    .menu-container {
      padding: 0 20px !important;
    }
    .content-container {
      .breadcrumb-mobile {
        display: block;
        padding: 0 20px !important;
      }
    }
    .zoom-svg {
    }
  }

  @media only screen and (max-width: 545px) {
    .content-container .product-container {
      .photo-desc-price {
        display: flex;
        flex-direction: column;
        width: 100%;
      }
      img {
        width: 100%;
        border-radius: 0;
        height: 231px;
      }
      .breadcrumb-add-to-cart-btn {
        align-items: end !important;
      }
    }
  }

  @media only screen and (max-width: 440px) {
    .content-container {
      background-color: ${(props) => props.theme.white};
      .mobile-product-images {
        display: block !important;
        img {
          height: 231px;
          width: 100%;
        }

        .slick-arrow {
          width: 32px;
          height: 32px;
          border-radius: 50%;
          background-color: black;
          display: flex;
          justify-content: center;
          align-items: center;
          &::after {
            position: initial;
          }
        }
      }
      .view-all-items-button {
        text-align: center;
        padding: 0 20px 20px !important;
        width: 100%;
        a {
          width: 100%;

          button {
            width: 100%;
            height: 30px;
          }
        }
      }

      .product-slider {
        display: block;
      }

      .banner-image-wrapper {
        padding: 0 20px;
      }
    }

    .content-container .product-container {
      padding: 0 0 !important;

      .photo-desc-price {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }

      .desc-price {
        display: flex;
        flex-direction: row;
        width: 100%;

        .price,
        .type-of-price {
          font-size: 16px;
          font-weight: 600;
        }

        .owner,
        .desc {
          display: none;
        }

        .mobile-name {
          display: block !important;
          font-size: 16px;
          font-weight: 500;
          margin: 0 !important;
        }
      }

      .photo {
        display: none;
      }
      .breadcrumb-add-to-cart-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 !important;

        .select-ammount-container {
          width: 100%;
        }

        .btn-add-to-cart {
          width: 100%;
          margin-top: 10px;
          div {
            width: 100%;
            height: 45px;
          }
        }
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        margin: 0 !important;
      }

      .view-product {
        background-color: ${(props) => props.theme.white};
        border: 1px solid ${(props) => props.theme.darkRed};
        color: ${(props) => props.theme.darkRed};
        height: 48px;
        padding: 0;
        width: 100%;
        font-size: 16px;
      }
    }

    .content-container .menu-container {
      padding: 0 !important;

      ul {
        display: flex;
        padding: 10px 20px;
        justify-content: space-between;
        &::before,
        &::after {
          display: none;
        }

        li {
          padding: 0;
          &::after {
            border-bottom: none;
          }
        }
      }
    }

    .content-section {
      background-color: ${(props) => props.theme.brightGrey};
      padding: 20px 20px 40px;

      .open-hours-table {
        margin: 0 !important;
        padding: 15px;
        min-width: 100% !important;
      }
    }

    .other-products-container {
      display: none;
    }
  }
`
