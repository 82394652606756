import React from 'react';
import { Form, ColorPicker as AntdColorPicker } from 'antd';
import { Dayjs } from 'dayjs';
import { Validations } from '../../interfaces';
import useFormValidation from '../../utils/useFormValidation';

export interface ColorPickerProps extends Validations {
  size?: 'large' | 'middle' | 'small' | undefined;
  disabled?: boolean;
  name?: string;
  label?: string;
  initialValue?: Dayjs;
  className?: string;
  format?: 'rgb' | 'hex' | 'hsb';
  value?: string;
}

export const ColorPicker = ({
  className,
  disabled,
  validations,
  size = 'large',
  name,
  label,
  initialValue,
  format,
  value,
}: ColorPickerProps) => {
  const { formValidations } = useFormValidation();

  const rules = validations ? formValidations(validations) : [];

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={initialValue}
      rules={rules}
      getValueFromEvent={(color) => {
        return '#' + color.toHex();
      }}
    >
      <AntdColorPicker
        className={className}
        disabled={disabled}
        size={size}
        format={format}
        value={value}
      />
    </Form.Item>
  );
};
