import { StyledLoginPage } from './style'
import { LoginForm } from '../../../ui/Form/auth/LoginForm'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '../../../hooks/useAuth'
import { useEffect } from 'react'
import { Spinner } from '../../../ui'

export const Login = () => {
  const { user, isFetchingUser, login, isLoginLoading } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (user?.authenticated && !isFetchingUser) {
      navigate('/')
    }
  }, [user, isFetchingUser, navigate])

  if (user?.authenticated) return null

  return (
    <Spinner spinning={isFetchingUser} dontRender>
      <StyledLoginPage>
        <div className={'content'}>
          <LoginForm appendData={login} isLoading={isLoginLoading} />
        </div>
      </StyledLoginPage>
    </Spinner>
  )
}
