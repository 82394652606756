import styled from 'styled-components'

export const StyledHeader = styled.div`
  width: 100%;
  height: ${(props) => props.theme.mainHeaderHeight};
  background-color: ${(props) => props.theme.brand01};
  position: relative;
  z-index: 100;
  min-width: 0;

  .content {
    max-width: ${(props) => props.theme.contentWidth};
    margin: auto;
    height: ${(props) => props.theme.mainHeaderHeight};
    min-width: 0;

    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-side-header {
      max-height: 100%;
      max-width: 200px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      min-width: 70px;

      .logo {
        max-height: 70px;
      }
    }

    .right-side-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      padding-left: 20px;

      .ant-menu {
        display: flex;
        justify-content: flex-end;
        flex-grow: 1;
      }
      .ant-menu-item {
        display: inline-flex;
        justify-content: center;
      }

      .ant-menu-title-content {
        color: ${(props) => props.theme.white};
        font-size: 18px;
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }
`
