import React, { memo, useCallback, useEffect, useState } from 'react'
import { Image } from 'antd'
import { options } from '../../../config/config'
interface FallBackImageProps {
  src?: string
  type?:
    | 'banner_1'
    | 'banner_2'
    | 'banner_3'
    | 'banner_4'
    | 'ProductCard'
    | 'CategoryCard'
    | 'FarmCard'
    | 'notFound'
  fallbackSrc?: string
  preview?: boolean
  onClick?: (value: any) => void
  className?: string
}

const BannerImageDefaults = {
  banner_1: options.images.defaultBannerOneImage,
  banner_2: options.images.defaultBannerTwoImage,
  banner_3: options.images.defaultBannerThreeImage,
  banner_4: options.images.defaultBannerFourImage,
  ProductCard: options.images.defaultProductImage,
  CategoryCard: options.images.defaultCategoryImage,
  FarmCard: options.images.defaultFarmImage,
  notFound: options.images.defaultImageNotFound,
}

export const FallbackImage = memo(
  ({ src, preview, type, onClick, className }: FallBackImageProps) => {
    const backendUrl = window.runConfig.backendUrl
    const location = '/client_assets'

    const isBlobUrl = src?.startsWith('blob:')
    const isPreviewImg = src?.startsWith('data:image')

    const imgSrc = isBlobUrl || isPreviewImg ? src : `${backendUrl}${src}`

    const defaultFallback = type
      ? BannerImageDefaults[type]
      : options.images.defaultImageNotFound

    return (
      <div className={className}>
        <Image
          src={imgSrc}
          fallback={`${backendUrl}${location}${defaultFallback}`}
          preview={preview}
          onClick={onClick}
        />
      </div>
    )
  }
)
