import { StyledDrawer } from './style'
import { DrawerProps } from '../Drawer'

export const DrawerShareProduct = ({
  open,
  closable,
  destroyOnClose,
  onClose,
  title,
  maskClosable,
  children,
}: DrawerProps) => {
  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      closable={closable}
      destroyOnClose={destroyOnClose}
      title={title}
      maskClosable={maskClosable}
      children={children}
      width={1000}
    ></StyledDrawer>
  )
}
