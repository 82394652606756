import { ReactNode, useEffect, useRef, useState } from 'react'
import { TabsProps } from 'antd'
import { StyledProductCard } from './style'
import { ProductData } from 'types/ProductData'
import { useIntl } from 'react-intl'
import AmountSelector from '../CartAmountSelector/CartAmountSelector'
import {
  ProductUnityPrice,
  ProductPackageUnit,
  ProductPrice,
} from '../../utils/productCardHelpers'
import { Icon } from '../Icon'
import Counter from 'public/components/DefaultLayout/Counter'
import {
  CartProduct,
  useCartDispatch,
  useCartState,
} from 'public/context/CartContext'
import { FallbackImage } from '../UploadImage/fallBack/FallBackImage'
import { useNavigate } from 'react-router-dom'

export interface ProductCard {
  actions?: Array<ReactNode>
  activeTabKey?: string
  bordered?: boolean
  defaultActiveTabKey?: string
  extra?: ReactNode
  hoverable?: boolean
  loading?: boolean
  size?: 'default' | 'small'
  tabBarExtraContent?: ReactNode
  tabProps?: TabsProps
  title?: ReactNode
  type?: 'inner' | undefined
  className?: string
  product?: ProductData
  imageUrl?: string
}

export const Card = ({
  actions,
  activeTabKey,
  bordered,
  imageUrl,
  defaultActiveTabKey,
  tabProps,
  tabBarExtraContent,
  extra,
  hoverable,
  type,
  title,
  size,
  loading,
  className,
  product,
}: ProductCard) => {
  const intl = useIntl()

  const farmNameRef = useRef<HTMLParagraphElement>(null)
  const [isTruncated, setIsTruncated] = useState(false)
  const [isHovered, setIsHovered] = useState(false)
  const cartDispatch = useCartDispatch()
  const { farmProducts } = useCartState()
  const navigate = useNavigate()

  const matchedCartProduct: CartProduct | undefined = farmProducts
    .flatMap((farm) => farm.products)
    .find((cartProduct) => cartProduct?.cartProduct?.id === product?.id)

  const amountCart = matchedCartProduct ? matchedCartProduct.amount : 0

  useEffect(() => {
    if (farmNameRef.current) {
      setIsTruncated(
        farmNameRef.current.scrollWidth > farmNameRef.current.clientWidth
      )
    }
  }, [product, isHovered])

  useEffect(() => {
    const handleResize = () => {
      if (farmNameRef.current) {
        setIsTruncated(
          farmNameRef.current.scrollWidth > farmNameRef.current.clientWidth
        )
      }
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleOnClick = () => {
    navigate(`/product/${product?.id}`)
  }

  const addToCart = () => {
    cartDispatch({
      type: 'ADD_ITEM',
      product: product,
      amount: 1,
    })
  }

  const productHasDiscount = !!(
    product?.storage_stock_discount_price &&
    product?.storage_stock_price &&
    product.storage_stock_discount_price < product.storage_stock_price
  )

  const coverImg = (
    <FallbackImage
      src={imageUrl ? imageUrl : ''}
      preview={false}
      type={'ProductCard'}
      onClick={handleOnClick}
    />
  )

  return (
    <StyledProductCard
      actions={actions}
      activeTabKey={activeTabKey}
      bordered={bordered}
      cover={coverImg}
      defaultActiveTabKey={defaultActiveTabKey}
      tabProps={tabProps}
      tabBarExtraContent={tabBarExtraContent}
      extra={extra}
      hoverable={hoverable}
      type={type}
      title={title}
      size={size}
      loading={loading}
      className={className}
    >
      <div className={'product-tags'}>
        <div className={'tags'}>
          {!!product?.storage_stock_discount_price && (
            <Icon name={'discount-tag'} />
          )}
          {product?.is_top_bought_product && (
            <div className={'tag'}>
              {intl.formatMessage({ id: 'product.top_price' })}
            </div>
          )}
        </div>
        <div className={'add-to-favorites'}>
          <Icon name={'heart-filled'} />
        </div>
      </div>

      <div className={'custom-title'}>
        <p>
          {product?.name} <ProductPackageUnit product={product} />
        </p>
      </div>

      <div>
        <div className={'price-quality-marks'}>
          <p
            className={'farm-name-container-mobile'}
            ref={farmNameRef}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            {product?.farm_name ?? product?.name}
          </p>

          <div className={'price-container-quality-marks-wraper'}>
            <div className={'price-container'}>
              <ProductPrice
                product={product}
                productHasDiscount={productHasDiscount}
              />
              <ProductUnityPrice product={product} />
            </div>

            <div className={'quality-marks-container'}>
              {product?.qualitymarks?.map((qmark, index) => {
                if (
                  !qmark.hidden &&
                  qmark.logo !== 'qualityMark/transparent.png'
                ) {
                  return (
                    <div className={'quality-mark'} key={`qmark-${index}`}>
                      <img alt={qmark.slug} src={qmark.logo} />
                    </div>
                  )
                }
                return <></>
              })}
            </div>
          </div>
        </div>

        <div className={'add-to-cart-container'}>
          {amountCart === 0 && (
            <p
              className={`farm-name-container`}
              ref={farmNameRef}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              {product?.farm_name ?? product?.farm_name}
            </p>
          )}
          {amountCart === 0 && (
            <div className={'mobile-quality-marks-container'}>
              {product?.qualitymarks?.map((qmark, index) => {
                if (
                  !qmark.hidden &&
                  qmark.logo !== 'qualityMark/transparent.png'
                ) {
                  return (
                    <div className={'quality-mark'} key={`qmark-${index}`}>
                      <img alt={qmark.slug} src={qmark.logo} />
                    </div>
                  )
                }
                return <></>
              })}
            </div>
          )}
          <div
            className={`add-to-cart-icon ${amountCart > 0 ? 'toggled' : ''}`}
          >
            <div className={'icon-buy'} onClick={() => addToCart()}>
              <Counter label={<Icon name={'shop-icon'} />} />
            </div>
          </div>
          {isTruncated && (
            <div
              className={'farm-name-hover'}
              style={{ display: isHovered ? 'flex' : 'none' }}
            >
              <p>{product?.farm_name ?? product?.name}</p>
              <div className={'rectangle-form'}></div>
            </div>
          )}

          <div
            className={'select-ammount-container'}
            style={{ display: amountCart > 0 ? 'inline' : 'none' }}
          >
            <AmountSelector
              product={product}
              amountCart={amountCart}
              unitType={product?.storage_unit}
            />
          </div>
        </div>
      </div>
    </StyledProductCard>
  )
}
