import { StyledFailedPayment } from './style'
import { Button, Icon } from '../../../../ui'
import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'

const FailedPayment = () => {
  const intl = useIntl()
  const navigate = useNavigate()
  return (
    <StyledFailedPayment>
      <div className={'container'}>
        <h1>{intl.formatMessage({ id: 'Maksājums neizdevās' })}</h1>
        <Button
          type={'primary'}
          label={intl.formatMessage({ id: 'Atpakaļ uz grozu' })}
          onClick={() => navigate('/cart')}
        />
      </div>
    </StyledFailedPayment>
  )
}

export default FailedPayment
