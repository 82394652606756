import styled from 'styled-components'

export const StyledCartAmountSelector = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-grow: 1;

  .amount-selector.productView {
    height: 62px;
  }

  .amount-selector {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${(props) => props.theme.grey};
    border-radius: 20px;
    cursor: default;

    .unit-unit-type {
      display: flex;
      gap: 5px;
    }

    button {
      background: none;
      border: none;
      padding: 6px 15px 6px 15px;
      font-weight: 500;
      font-size: 15px;
      cursor: pointer;

      &:hover {
        font-weight: 700;
      }
    }
  }
`
