import { useIntl } from 'react-intl'
import { StyledConfirmationPageWrapper } from './style'

export const ConfirmationPage = () => {
  const intl = useIntl()
  return (
    <StyledConfirmationPageWrapper>
      <div className={'content'}>
        <div className="content_inner">
          <div className="box">
            <h2 className={'title'}>
              {intl.formatMessage({ id: 'confirmation.title' })}
            </h2>
            <p className="text">
              {intl.formatMessage({ id: 'confirmation.text' })}
            </p>
            <b className="email">valdis.kanderats@llkc.lv</b>
            <img className="img" src="confirmation-email.png" alt="email" />
          </div>
        </div>
      </div>
    </StyledConfirmationPageWrapper>
  )
}
