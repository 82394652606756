import styled from 'styled-components'
import { Card } from 'antd'

export const StyledProductCard = styled(Card)`
  &.ant-card {
    border-radius: 5px;
  }

  .discount-tag {
    width: 22px;
    height: 22px;
  }

  @media only screen and (max-width: 335px) {
    .ant-card-body {
      .custom-title {
        p {
          max-height: 45px !important;
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    flex-direction: row;
    max-width: 440px;
    min-height: 136px;
    border-radius: 8px;

    .add-to-cart-container {
      border-top: none !important;
    }

    .ant-card-body {
      padding-top: 6px !important;
    }

    .ant-card-cover {
      border-radius: 0;

      img {
        height: 136px;
        width: 136px;
        border-radius: 8px 0 0 8px;
      }
    }

    .ant-card-body {
      width: 100%;

      .product-tags {
        display: none;
      }

      .price-quality-marks {
        flex-direction: column !important;

        .farm-name-container-mobile {
          display: flex !important;
          margin: 0 10px;
          overflow: hidden;
          white-space: normal;
          text-overflow: ellipsis;
          color: ${(props) => props.theme.grey};
          font-size: 14px;
        }

        .price-container-quality-marks-wraper {
          flex-direction: row !important;

          .price-container {
            align-items: start !important;
          }

          .quality-marks-container {
            display: none !important;
          }
        }
      }

      .mobile-quality-marks-container {
        display: flex;
        align-items: flex-end;
        flex-grow: 1;
        gap: 7px;

        img {
          width: 27px;
          height: 18px;
        }
      }

      .add-to-cart-container {
        .farm-name-container {
          display: none;
        }
      }
    }
  }

  max-width: 261px;
  min-width: 261px;
  max-height: 500px;
  min-height: 434px;
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  margin: 10px;
  flex-grow: 1;
  background-color: ${(props) => props.theme.white};
  font-family: ${(props) => props.theme.fontText};

  .mobile-quality-marks-container {
    display: none;
  }

  .ant-card-cover {
    img {
      border-radius: 5px 5px 0 0;
    }
  }

  .product-tags {
    position: absolute;
    display: flex;
    top: 5px;
    width: 100%;
    justify-content: space-between;
    padding: 2px 0 0 8px;
    z-index: 10;
    align-items: center;

    .tags {
      display: flex;
      flex-direction: row-reverse;
      gap: 10px;

      .tag-discount {
        font-size: 13px;
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 22px;
        height: 22px;
        background-color: ${(props) => props.theme.discountTagBg};
        border-radius: 2px;
      }

      .tag {
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.white};
        width: 75px;
        height: 22px;
        background-color: ${(props) => props.theme.topPriceBgColor};
        font-size: 13px;
        border-radius: 2px;
      }
    }

    .add-to-favorites {
      color: ${(props) => props.theme.white};
      padding-right: 7px;
    }
  }

  .ant-card-head-title {
    text-align: center;
    position: relative;
  }

  .ant-card-cover {
    border-radius: 5px 5px 0 0 !important;
    overflow: hidden;
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px 0 0 0;
    flex: 1;
    border-radius: 0 0 5px 5px !important;
    overflow: hidden;

    &::after,
    &::before {
      display: none;
    }

    .custom-title {
      margin: 0 10px;
      font-weight: 400;
      font-family: ${(props) => props.theme.fontText};

      p {
        margin: 0;
      }
    }

    .price-quality-marks {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      margin-bottom: 8px;
      font-weight: 500;

      .price-type-container {
        line-height: 18px;
      }

      .farm-name-container-mobile {
        display: none;
      }

      .price-container-quality-marks-wraper {
        display: flex;
        margin: 0 10px;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: flex-end;

        .price-container {
          display: flex;
          flex-direction: column-reverse;
          justify-content: flex-start;
          align-items: flex-end;

          .price-type-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
          }

          .price-type-container {
            display: flex;
            flex-direction: row;
            gap: 8px;

            .price {
              font-weight: 500;
            }
          }

          .price-type-second,
          .package-price {
            position: relative;
            display: flex;
            flex-direction: row;
            font-size: 13px;
            color: ${(props) => props.theme.grey};
            gap: 8px;
          }

          .price-type-second::before {
            position: absolute;
            height: 1px;
            width: 110%;
            left: -5px;
            bottom: 10px;
            transform: rotate(-5deg);
            background-color: ${(props) => props.theme.red};
            content: '';
          }

          .grey-price {
            display: flex;
            flex-direction: row;
            line-height: 18px;
            color: ${(props) => props.theme.red};
            gap: 8px;
          }
        }

        .quality-marks-container {
          display: flex;
          align-items: flex-end;
          flex-grow: 1;
          gap: 7px;

          img {
            width: 27px;
            height: 18px;
          }
        }

        .quality-mark {
          height: 18px;
        }
      }
    }

    .add-to-cart-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: center;
      padding: 7px 10px;
      border-top: 1px solid ${(props) => props.theme.lightGrey};
      width: 100%;

      .farm-name-container.toggled {
        display: none;
      }

      .select-ammount-container {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.white};

        button {
          height: 100%;
          margin: 0 10px 0 10px;
        }
      }

      p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: ${(props) => props.theme.grey};
        font-size: 16px;
        width: 100%;
        text-align: start;
      }

      .add-to-cart-icon.toggled {
        display: none;
      }

      .add-to-cart-icon {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;

        .icon-buy {
          font-size: 19px;
          display: flex;
          justify-content: center;
          color: white;
          border-radius: 40px;
          width: 100%;

          .ant-btn:hover {
            color: transparent !important;
            border-color: none !important;
            background: ${(props) => props.theme.deepSlate} !important;
          }
        }

        div {
          width: 100%;

          .counter-container {
            width: 100%;
            height: 31px;
          }
        }

        .btn-add-to-cart {
          text-align: end;
          height: 33px;

          button {
            padding: 0;
            border-radius: 50%;
            height: 33px;
            width: 33px;
          }
        }
      }
    }

    .farm-name-hover {
      display: none;
      position: absolute;
      width: 78%;
      bottom: 44px;
      border: 1px solid ${(props) => props.theme.grey};
      box-shadow: ${(props) => props.theme.boxShadow};
      z-index: 10;
      cursor: default;
      flex-wrap: wrap;

      p {
        min-height: 74px;
        padding: 12px 5px 15px 12px;
        color: ${(props) => props.theme.white};
        background-color: ${(props) => props.theme.bgColorHover};
        display: flex;
        font-family: ${(props) => props.theme.fontText};
        font-size: 16px;
        font-weight: 400;
        white-space: wrap;
      }

      .rectangle-form {
        position: absolute;
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.theme.bgColorHover};
        rotate: 45deg;
        left: 15px;
        bottom: -6px;
      }
    }

    .add-to-cart-icon:hover {
      cursor: pointer;
    }
  }
`

export const StyledFarmCard = styled(Card)`
  display: flex;
  height: 360px;
  max-width: 261px;
  min-width: 261px;
  flex-direction: column;
  flex: 1;
  font-family: ${(props) => props.theme.fontText};

  .ant-card-cover {
    padding: 23px 42px;
  }

  .ant-card-body {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding: 0;
    z-index: 5;

    .container {
      display: flex;
      flex-grow: 1;
      flex-direction: column;

      .farm-name {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        padding: 0 30px;
        flex-wrap: wrap;
        font-weight: 500;
        font-size: 16px;
      }

      .address-farm {
        font-weight: 400;
        font-size: 14px;
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        color: ${(props) => props.theme.grey};
        padding: 0 30px;

        .address {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
      }

      .qmarks-wrapper {
        width: 100%;
        border-top: 1px solid ${(props) => props.theme.lightGrey};
        padding: 0;

        .qmarks-url {
          display: flex;
          flex-grow: 1;
          align-items: center;
          justify-content: space-between;
          padding: 8px;
          line-height: 23px;

          .qmarks {
            display: flex;
            min-width: 1px;

            img {
              max-width: 30px;
            }
          }

          .url-to {
            text-decoration: underline;
            font-weight: 400;
            font-size: 14px;
            cursor: pointer;

            .rect-angle-wrap {
              position: absolute;

              .rect {
                position: relative;
                width: 26px;
                height: 26px;
                background-color: white;
                transform: rotate(45deg);
                z-index: -1;
                right: -10px;
                bottom: 5px;
              }
            }
          }
        }
      }
    }
  }
`

export const StyledCategoryCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 194px;
  width: 467px;
  border-radius: 5px;
  font-family: ${(props) => props.theme.fontText};
  overflow: hidden;

  img {
    max-width: 100%;
    max-height: 194px;
  }

  .ant-card-body {
    padding: 8px 20px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 6px;

    p {
      margin: 0;
    }
  }

  .category-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 38px;
  }

  .product-count {
    color: ${(props) => props.theme.grey};
    font-family: Albert Sans;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
  }

  @media only screen and (max-width: 440px) {
    min-height: 194px;
  }
`

export const StyledAllFarmsFarmCard = styled(Card)`
  font-family: ${(props) => props.theme.fontText};
  height: 360px;
  min-height: 120px;
  width: 261px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${(props) => props.theme.white};

  .ant-card-cover {
    min-height: 261px;
    max-height: 261px;
    min-width: 100%;
    padding-top: 100px;
  }

  .overlay-wrapper {
    display: flex;
    position: absolute;
    width: 100%;
    padding: 0 10px;

    .overlay {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      min-height: 30px;
      width: 100%;
      top: -250px;

      .top-farm {
        border-radius: 6px;
        display: flex;
        align-items: center;
        font-size: 13px;
        background-color: ${(props) => props.theme.topPriceBgColor};
        color: ${(props) => props.theme.white};
        padding: 5px;
      }

      .favorite {
        display: flex;
        font-size: 30px;
        color: ${(props) => props.theme.topPriceBgColor};
      }
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 0;

    .farms-card-content {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
      padding: 5px 0px 5px 0px;
      .farms-card-title {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        font-size: 16px;
        text-overflow: ellipsis;
        overflow: hidden;
        font-weight: 400;
        max-height: 54px;
        margin-bottom: 10px;
        padding: 0 10px;
      }

      .registered-full-address {
        color: ${(props) => props.theme.grey} !important;
      }

      .qmarks-wrapper {
        border-top: 1px solid ${(props) => props.theme.lightGrey};
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 35px;

        .qmarks {
          align-items: center;
          display: flex;
          gap: 6px;

          .qmark-fm {
            overflow: hidden;

            img {
              width: 27px;
              height: 18px;
            }
          }
        }
        .farms-card-inspect-button {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    min-width: 100%;
    min-height: 120px;
    height: auto;
    flex-direction: row;
    padding-left: 1px;
    .ant-card-cover {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      min-height: 120px;
      max-height: 120px;
      min-width: 120px;
      max-width: 120px;
    }
    .ant-card-body {
      .overlay-wrapper {
        display: none;
      }

      .farms-card-content {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding: 6px 8px 10px 0px;
        .farms-card-title {
          font-size: 16px;
          font-weight: 500;
          padding: 0 0 5px 0;
        }
        .registered-full-address {
          font-size: 14px;
        }
      }
    }
    .qmarks-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-top: none !important;
      .farms-card-inspect-button {
        display: block !important;
        text-decoration: underline;
        font-size: 14px;
        background-color: transparent;
        color: black;
        border: none;
        width: 64px;
        padding-right: 8px;

        .text {
          border-bottom: 1px solid ${(props) => props.theme.colorText};
        }
      }
    }
  }
`

export const StyledCartProductCard = styled(Card)`
  display: flex;
  flex-direction: row;
  padding: 25px 0 25px 0;
  border-bottom: solid 1px ${(props) => props.theme.borderBottom};
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0;

  .ant-card-cover {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    min-height: 115px;
    max-height: 115px;
    min-width: 115px;
    max-width: 115px;
    border-radius: 20px 20px 0 0 !important;
    overflow: hidden;

    img {
      border-radius: 5px;
    }
  }

  .ant-card-body {
    display: flex;
    flex-direction: row;
    padding: 5px 20px 5px 20px;
    width: 100%;

    .left-side-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      .cart-product-title {
        font-weight: 600;
        font-size: 16px;
        margin: 0;
      }
      .amount-selector-wrapper {
        max-width: fit-content;
      }
    }

    .right-side-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-end;

      .remove-cart-product-btn {
        background-color: transparent;
        border: 0;
        font-size: 16px;
        font-weight: 400;
        color: ${(props) => props.theme.topPriceBgColor};
      }

      .price-container {
        display: flex;
        flex-direction: column-reverse;
        justify-content: flex-start;
        align-items: flex-end;
        min-width: 100px;

        .price-type-wrapper {
          display: flex;
          flex-direction: column-reverse;
          align-items: flex-end;
        }

        .price-type-second,
        .package-price {
          position: relative;
          display: flex;
          flex-direction: row;
          font-size: 13px;
          color: ${(props) => props.theme.grey};
          gap: 8px;
        }

        .price-type-second::before {
          position: absolute;
          height: 1px;
          width: 110%;
          left: -5px;
          bottom: 10px;
          transform: rotate(-5deg);
          background-color: ${(props) => props.theme.red};
          content: '';
        }

        .grey-price {
          display: flex;
          flex-direction: row;
          line-height: 18px;
          color: ${(props) => props.theme.red};
          gap: 8px;
        }

        .price-type-container {
          display: flex;
          flex-direction: row;
          gap: 8px;

          .price {
            font-weight: 500;
          }
        }

        .grey-price,
        .package-price {
          display: flex;
          flex-direction: row;
          font-size: 13px;
          color: ${(props) => props.theme.grey};
          gap: 8px;
        }
      }
    }
  }
`

export const StyledCartCard = styled.div`
  .cart-products-content {
    display: grid;
    grid-template-columns: 0.6fr 2fr 1fr 1fr 0.5fr;
    border-radius: 5px;
    padding: 15px 20px 15px 20px;
    align-items: center;

    .cart-cell-1 {
      padding: 5px;

      img {
        width: 115px;
        height: 115px;
      }

      @media only screen and (max-width: 576px) {
        padding: 0px;

        img {
          width: 98px;
          height: 98px;
        }
      }
    }

    .cart-cell-2 {
      padding-left: 25px;
      align-self: baseline;

      .cart-product-farm-name {
        color: ${(props) => props.theme.grey};
      }

      @media only screen and (max-width: 576px) {
        padding-left: 10px;

        .cart-product-farm-name {
          display: none;
        }

        .cart-product-name {
          margin-top: 0;
        }
      }
    }

    .cart-cell-3 {
      padding-left: 10px;
      .price-container {
        .price-type-container {
          display: flex;
          flex-direction: row;
          .price {
            white-space: nowrap;
          }
        }
        .price-type-wrapper {
          .price-type-second {
            display: flex;
            line-height: 18px;
            flex-direction: row;
          }
          .price-grey {
            display: flex;
            flex-direction: row;
            font-size: 16px;
            font-weight: 400;
            color: ${(props) => props.theme.grey};
          }
        }
      }
    }

    .cart-cell-4 {
      width: 120px;

      .cart-product-amount-view {
        font-size: 16px;
        font-weight: 400;
        color: ${(props) => props.theme.bgColorHover};
      }
    }

    .cart-cell-5 {
      text-align: end;
    }

    @media only screen and (max-width: 576px) {
      grid-template-columns: 98px 2fr 1fr;
      align-items: start;

      .cart-cell-4,
      .cart-cell-5 {
        display: none;
      }
    }
  }

  .mobile-cart-cells {
    display: none;

    @media only screen and (max-width: 576px) {
      display: grid;
      grid-template-columns: repeat(2, 50%);
      padding: 10px;
      padding-top: 0;

      .cart-cell-4 div[class*='amount-selector'] {
        height: 48px;
        padding: 14px 15px;
        border-radius: 5px;
        border: 1px solid ${(props) => props.theme.grey};
        border-color: transparent;
        background-color: ${(props) => props.theme.almostWhite};
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }

      .cart-cell-5 {
        position: relative;
        height: 48px;
        padding: 14px 15px;
        background-color: ${(props) => props.theme.almostWhite};
        border-radius: 5px;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
        text-align: end;
      }

      .cart-cell-5::after {
        position: absolute;
        top: 9px;
        left: 0;
        z-index: 0;
        width: 1px;
        height: 31px;
        background-color: ${(props) => props.theme.silverGray};
        content: '';
      }
    }
  }

  div[class*='cart-delivery-button'] {
    display: none;
    padding: 0 10px 10px 10px;
  }

  @media only screen and (max-width: 576px) {
    background-color: ${(props) => props.theme.cloudGray};
    .cart-products-content {
      padding: 10px;
    }

    div[class*='cart-delivery-button'] {
      display: block;
    }

    div[class*='cart-delivery-button'] button {
      padding: 7px 0;
      width: 100%;
      height: auto;
    }

    .cart-footer {
      display: grid;
      grid-template-columns: 1.8fr 1.2fr;
      padding: 10px;
      gap: 5px;

      .cart-footer-text {
        margin: 0;
        font-size: 16px;
        font-weight: 300;
        line-height: 19.2px;
      }

      .cart-footer-link {
        text-align: end;
        color: ${(props) => props.theme.darkRed};
      }

      .cart-footer-link span {
        border-bottom: 2px solid ${(props) => props.theme.darkRed};
      }
    }
  }
`
