import DefaultLayout from '../../components/DefaultLayout';
import { Form } from '../../../ui/Form/Form';
import { DefaultThirdHeader } from '../../components/DefaultLayout/DefaultThirdHeader';
import { useIntl } from 'react-intl';
import useQueryApiClient from '../../../utils/useQueryApiClient';
import { Button, Icon, Spinner } from '../../../ui';
import { Pagination } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { ProductsTopFilter } from '../../../ui/Form/filters/ProductsTopFilter';
import { options } from '../../../config/config';
import { StyledAllFarmsPage } from './style';
import { FarmData } from '../../../types/FarmData';
import { AllFarmsFarmCard } from '../../../ui/Card/AllFarmsFarmCard';
import { Link, useNavigate } from 'react-router-dom';
import Banner from 'public/components/DefaultLayout/Banner';
import { useBannerState } from 'public/context';
import { MobileMenuDrawer } from 'ui/Drawer/Drawers/MobileMenuDrawer';
import { StyledMobileFilterFooter } from 'public/components/MobileLayout/MobileMenu/MobileFilterFooter/style';
import MobileFilter from 'public/components/MobileLayout/MobileFilter/MobileFilter';
import { FilterOptions } from 'types/FilterOptions';
import { Filter } from 'types/Filter';
import { useFilterDispatch } from 'public/context/FilterContext';
import SearchAndFilter from 'public/components/DefaultLayout/SearchAndFilter';

export const AllFarms = () => {
  const [filter, setFilter] = useState<Filter>(() => {
    const savedFilter = localStorage.getItem('farmsFilter');
    return savedFilter ? JSON.parse(savedFilter) : {};
  });
  const [total, setTotal] = useState<number>(0);
  const [farms, setFarms] = useState<FarmData[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(() => {
    const savedPage = localStorage.getItem('savedFarmsPage');
    return savedPage ? JSON.parse(savedPage) : 1;
  });
  const [pageSize, setPageSize] = useState<number>(() => {
    const savedPageSize = localStorage.getItem('savedFarmsPageSize');
    return savedPageSize ? JSON.parse(savedPageSize) : 20;
  });
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({});
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const dispatchFilter = useFilterDispatch();
  const navigation = useNavigate();

  const openCloseDrawer = useCallback(() => {
    setIsFilterOpen(!isFilterOpen);
  }, [isFilterOpen]);

  useEffect(() => {
    dispatchFilter({
      type: 'SAVE_PAYLOAD',
      payload: { isFilter: true, openCloseDrawer },
    });
  }, [dispatchFilter, openCloseDrawer]);

  const handleSubmitFilter = () => {
    localStorage.setItem('farmsFilter', JSON.stringify(filter));
    openCloseDrawer();
    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
    });
  };

  const { isLoading, appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/farms',
      data: {
        page: currentPage,
        page_size: pageSize,
        ...filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setTotal(response.farms.total);
      setFarms(response.farms.data);
      setFilterOptions(response.filters);
    },
  });

  useEffect(() => {
    if (isFilterOpen) return;

    localStorage.setItem('farmsFilter', JSON.stringify(filter));
    localStorage.setItem('savedFarmsPage', currentPage.toString());
    localStorage.setItem('savedFarmsPageSize', pageSize.toString());

    appendData({
      filter: filter,
      page: currentPage,
      page_size: pageSize,
    });
  }, [filter, currentPage, pageSize]);

  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter);
    setCurrentPage(1);
  };

  const handleShowMap = () => {
    navigation('/map', {
      state: {
        filters: filter,
        dataType: 'farms',
        page: currentPage,
        page_size: pageSize,
        farms: farms,
        total: total,
      },
    });
  };

  const intl = useIntl();

  const pageSizeOptions = options.filters.pageSizeOptions;

  const breadcrumbs = [
    {
      route: '/',
      name: intl.formatMessage({ id: 'breadcrumbs.home' }),
    },
    {
      route: 'all',
      name: intl.formatMessage({ id: 'breadcrumbs.farms' }),
    },
  ];

  const { banners } = useBannerState();

  const banner_2_data = banners.data.find(
    (banner) => banner.type === 'banner_2'
  );
  const banner_3_data = banners.data.find(
    (banner) => banner.type === 'banner_3'
  );

  const drawer = () => {
    return (
      <MobileMenuDrawer
        title={
          <div>
            <p className="title">
              {intl.formatMessage({ id: 'farms.filter' })}
            </p>
            <p className="text">
              <span className="bold-text">{total}</span>{' '}
              {intl.formatMessage({ id: 'farms.farms_and_producers' })}
            </p>
          </div>
        }
        onClose={openCloseDrawer}
        open={isFilterOpen}
        className="mobile-filter"
        footer={
          <StyledMobileFilterFooter>
            <Button
              label={intl.formatMessage({ id: 'general.select' })}
              icon={<Icon name={'mobile-filter-submit-arrow-icon'} />}
              onClick={handleSubmitFilter}
            />
          </StyledMobileFilterFooter>
        }
        closable={false}
      >
        <MobileFilter
          setFilter={setFilter}
          filter={filter}
          filterOptions={filterOptions}
        />
      </MobileMenuDrawer>
    );
  };

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledAllFarmsPage>
          <DefaultThirdHeader
            label={intl.formatMessage({ id: 'form.farms_page_title' })}
            filters={
              <ProductsTopFilter
                total={total}
                per_page={pageSize}
                setFilter={(newFilter) =>
                  handleFilterChange({ ...filter, ...newFilter })
                }
                page_size_options={pageSizeOptions}
                setPageSize={setPageSize}
                unit={'farms'}
                filters={filter}
                handleShowMap={handleShowMap}
              />
            }
            breadcrumb={breadcrumbs}
          />
          <SearchAndFilter rootClassName="laptop-search-section-box" />
          <Spinner spinning={isLoading}>
            <div className={'products-filters-container'}>
              <Form
                setFilter={handleFilterChange}
                filterOptions={filterOptions}
                filter={filter}
              />
              <div className={'products-wrapper'}>
                <div className={'products'}>
                  {farms.map((farm: FarmData, index) => (
                    <Link to={`/farm/${farm.id}`} key={`${index}-lnk`}>
                      <AllFarmsFarmCard
                        name={farm.name}
                        key={`frm-crd-${index}`}
                        imageUrl={farm.logo}
                        qmarks={farm.quality_marks}
                        registeredFullAddress={farm.registered_full_address}
                      />
                    </Link>
                  ))}
                </div>
                <div className={'pagination'}>
                  <Pagination
                    total={total}
                    showSizeChanger={false}
                    current={currentPage}
                    defaultPageSize={20}
                    pageSize={pageSize}
                    onChange={(page, pageSize) => {
                      setPageSize(pageSize);
                      setCurrentPage(page);
                    }}
                  />
                </div>
              </div>
            </div>
            {banner_2_data && banner_3_data && (
              <Banner banners={[banner_2_data, banner_3_data]} />
            )}
          </Spinner>
        </StyledAllFarmsPage>
        {drawer()}
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
