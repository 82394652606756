import { StyledLoginForm } from './style'
import { Input } from '../../Input'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'
import { Checkbox } from 'ui/Checkbox'
import { useEffect } from 'react'
import { Form } from 'antd'
import RoutesPath from 'enums/routes'
import { Link } from 'react-router-dom'

export interface RegisterProps {
  appendData: any
  errorData: null | {
    message: string
    errors: {
      [key: string]: string[]
    }
  }
}

export const RegisterForm = ({ appendData, errorData }: RegisterProps) => {
  const intl = useIntl()
  const [form] = Form.useForm()

  const handleSubmit = (values: any) => {
    appendData(values)
  }

  useEffect(() => {
    if (errorData?.errors) {
      const errors = errorData.errors

      Object.keys(errors).forEach((field) => {
        form.setFields([
          {
            name: field,
            errors: [
              errors[field][0].includes('E-pasta jau ir aizņemts.')
                ? intl.formatMessage({ id: 'register.email_error' })
                : errors[field][0],
            ],
          },
        ])
      })
    }
  }, [errorData, form, intl])

  return (
    <StyledLoginForm onFinish={handleSubmit} form={form}>
      <div className={'inputs'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'register.title' })}
        </div>
        <p className={'description'}>
          {intl.formatMessage({ id: 'register.description' })}
        </p>

        <div className={'fulname'}>
          <div>
            {intl.formatMessage({ id: 'register.name' })}
            <Input size={'large'} required name={'name'} />
          </div>
          <div>
            {intl.formatMessage({ id: 'register.surname' })}
            <Input size={'large'} required name={'surname'} />
          </div>
        </div>

        <div className={'email'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'register.e_mail' })}
          </span>
          <Input
            size={'large'}
            required
            name={'email'}
            placeholder="**********"
            validations={['required', 'email']}
          />
        </div>

        <div className={'email_again'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'register.e_mail_again' })}
          </span>
          <Input
            size={'large'}
            required
            name={'reemail'}
            placeholder="**********"
            dependencies={['email']}
            validations={['required', 'email', 'reemail']}
          />
        </div>

        <div className={'pw-wrap'}>
          <div className={'pw-first'}>
            <span className="label">
              {' '}
              {intl.formatMessage({ id: 'register.password' })}
            </span>
            <Input
              password
              size={'large'}
              required
              name={'password'}
              placeholder="**********"
              validations={['required']}
            />
          </div>
          <div className={'pw-scnd'}>
            <span className="label">
              {' '}
              {intl.formatMessage({ id: 'register.password_again' })}
            </span>
            <Input
              password
              size={'large'}
              required
              name={'password_confirmation'}
              placeholder="**********"
              dependencies={['password']}
              validations={['required', 'password_confirmation']}
            />
          </div>
        </div>

        <div className={'accept_terms'}>
          <Checkbox name={'accept_terms'} />
          <Link
            to={RoutesPath.TERMS_OF_USE}
            className="link"
            rel="noopener noreferrer"
          >
            {intl.formatMessage({ id: 'register.accept_terms' })}
          </Link>
        </div>

        <Button
          className={'cstm-btn'}
          label={intl.formatMessage({ id: 'register.register' })}
          size={'large'}
          htmlType={'submit'}
        />
        <Button
          label={intl.formatMessage({ id: 'register.use_google' })}
          className={'cstm-btn google'}
          icon={<img src={'/google.png'} />}
        />
        <div className={'no-acc-reg'}>
          {intl.formatMessage({ id: 'register.have_acc' })}
          <a href={'/login'} className="login_link">
            {intl.formatMessage({ id: 'register.login' })}
          </a>
        </div>
      </div>
    </StyledLoginForm>
  )
}
