import React, { ReactNode } from 'react'
import {
  StyledAppendDotsContainer,
  StyledAppendDotsInner,
  StyledCarousel,
  StyledCustomPaging,
  StyledDotContainer,
  StyledImage,
} from '../style'
import { ImageProps } from '../../../types/BannerData'
import { options } from '../../../config/config'
import { FallbackImage } from '../../UploadImage/fallBack/FallBackImage'

interface ProductGalleryProps {
  productImages?: ImageProps[]
  customDots?: boolean
}

export const ProductGallery = ({
  productImages,
  customDots,
}: ProductGalleryProps) => {
  const customPaging = (i: number) => (
    <StyledCustomPaging className={'custom-dot'}>
      <StyledDotContainer>
        {productImages && productImages[i] ? (
          <FallbackImage
            src={`${productImages[i]?.url_original}`}
            key={i}
            type={'ProductCard'}
            preview={false}
          />
        ) : (
          <FallbackImage
            src={productImages ? `${productImages[i].url_original}` : ''}
            type={'ProductCard'}
            preview={false}
          />
        )}
      </StyledDotContainer>
    </StyledCustomPaging>
  )

  return (
    <StyledCarousel
      customPaging={customPaging}
      appendDots={(dots: ReactNode) => (
        <StyledAppendDotsContainer>
          <StyledAppendDotsInner>{dots}</StyledAppendDotsInner>
        </StyledAppendDotsContainer>
      )}
      arrows
    >
      {productImages &&
        productImages.length > 0 &&
        productImages.map((image, index) => (
          <div key={index} className={'gallery-img-container'}>
            <FallbackImage
              src={`${image.url_original}`}
              type={'ProductCard'}
              preview={false}
            />
          </div>
        ))}
    </StyledCarousel>
  )
}
