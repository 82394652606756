import { Button, Checkbox, DatePicker, Input, Table, Tabs } from '../../../ui';
import DefaultLayout from '../../components/DefaultLayout';
import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useAuth } from '../../../hooks/useAuth';
import { StyledFarmContractsPage } from './style';
import { useForm } from 'antd/lib/form/Form';
import { Form, message } from 'antd';
import dayjs from 'dayjs';
import useQueryApiClient from '../../../utils/useQueryApiClient';

interface ContractProps {
  brand?: string;
  contract_date?: string;
  contract_nr?: string;
  created_at?: string;
  emarket: boolean;
  emarket_bank_account?: string;
  id: number;
  name?: string;
  reg_nr?: string;
  status: boolean;
  user?: any;
}

export const FarmContracts = () => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [editingContract, setEditingContract] = useState<ContractProps | null>(
    null
  );
  const [reloadCount, setReloadCount] = useState(0);

  const intl = useIntl();
  const { user } = useAuth();
  const [form] = useForm();
  const isAdmin = user?.isAdmin;

  const validations = isAdmin ? 'adminFarmBankAccount' : 'farmBankAccount';

  const stableFilter = useMemo(() => {
    return { farmId: user?.farmId };
  }, [user?.farmId]);

  const { appendData } = useQueryApiClient({
    request: {
      url: `/api/v2/contracts/${editingContract?.id}`,
      method: 'POST',
      multipart: true,
    },
    onSuccess: () => {
      handleCancelClick();
      setReloadCount((prev) => prev + 1);
      message.success(intl.formatMessage({ id: 'message.updated_contract' }));
    },
  });

  const handleEditClick = (record: ContractProps) => {
    const momentDate = dayjs(record.contract_date, 'YYYY-MM-DD');

    setEditingContract(null);
    setIsEditMode(true);
    setEditingContract(record);
    form.setFieldsValue({
      emarket_bank_account: record.emarket_bank_account,
      contract_nr: record.contract_nr,
      contract_date: momentDate,
      emarket: record.emarket,
      status: record.status,
      farm_id: user?.farmId,
    });
  };

  const handleSaveClick = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      const formData = new FormData();

      Object.keys(values).forEach((key) => {
        let value = values[key];

        if (typeof value === 'boolean') {
          value = value ? '1' : '0';
        }

        formData.append(key, value);
      });

      appendData(formData);
    } catch (error: any) {
      if (error && error.errorFields) {
        const errorMessages = error.errorFields
          .map((field: any) => field.errors.join(', '))
          .join('\n');

        message.error(intl.formatMessage({ id: `${errorMessages}` }));
      }
    }
  };

  const handleCancelClick = () => {
    form.resetFields();
    setEditingContract(null);
    setIsEditMode(false);
  };

  const dependencyItems = [
    intl,
    isEditMode,
    editingContract,
    handleEditClick,
    handleCancelClick,
    handleSaveClick,
    isAdmin,
  ];

  const columns = useMemo(() => {
    return [
      {
        title: intl.formatMessage({ id: 'contracts.contract_nr' }),
        dataIndex: 'contract_nr',
        render: (value: string, record: ContractProps) => {
          const isEditingThisRow =
            record.id === editingContract?.id && isEditMode;

          if (isEditingThisRow && isAdmin) {
            return (
              <div>
                <Input name={'contract_nr'} validations={validations} />
              </div>
            );
          }

          return value;
        },
      },
      {
        title: intl.formatMessage({ id: 'contracts.date' }),
        dataIndex: 'contract_date',
        render: (value: string, record: ContractProps) => {
          const isEditingThisRow =
            record.id === editingContract?.id && isEditMode;

          if (isEditingThisRow && isAdmin) {
            return (
              <DatePicker
                name={'contract_date'}
                format={'YYYY-MM-DD'}
                validations={validations}
              />
            );
          }

          return value;
        },
      },
      {
        title: intl.formatMessage({ id: 'contracts.bank_account_number' }),
        dataIndex: 'emarket_bank_account',
        render: (value: number, record: ContractProps) => {
          const isEditingThisRow =
            record.id === editingContract?.id && isEditMode;

          if (isEditingThisRow) {
            return (
              <div>
                <Input
                  name={`emarket_bank_account`}
                  validations={validations}
                  maxLength={21}
                />
                <Input hidden name={'farm_id'} />
              </div>
            );
          }

          return value;
        },
      },
      {
        title: intl.formatMessage({ id: 'contracts.e_market' }),
        dataIndex: 'emarket',
        render: (value: boolean, record: ContractProps) => {
          const isEditingThisRow =
            record.id === editingContract?.id && isEditMode;

          if (isEditingThisRow && isAdmin) {
            return <Checkbox name={'emarket'} />;
          }

          return <Checkbox checked={value} />;
        },
      },
      {
        title: intl.formatMessage({ id: 'contracts.status' }),
        dataIndex: 'status',
        render: (value: boolean, record: ContractProps) => {
          const isEditingThisRow =
            record.id === editingContract?.id && isEditMode;

          if (isEditingThisRow && isAdmin) {
            return <Checkbox name={'status'} />;
          }

          return <Checkbox checked={value} />;
        },
      },
      {
        title: intl.formatMessage({ id: 'contracts.actions' }),
        render: (record: ContractProps) => {
          return !isEditMode || record.id !== editingContract?.id ? (
            <div className={'actions'}>
              <Button
                label={intl.formatMessage({ id: 'general.edit' })}
                type={'primary'}
                onClick={() => handleEditClick(record)}
              />
            </div>
          ) : (
            <div className={'actions'}>
              <Button
                label={intl.formatMessage({ id: 'general.save' })}
                type={'primary'}
                onClick={handleSaveClick}
              />
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                type={'primary'}
                onClick={handleCancelClick}
              />
            </div>
          );
        },
      },
    ];
  }, dependencyItems);

  const tableElement = (
    <Table
      url={'/api/v2/contracts'}
      columns={columns}
      filter={stableFilter}
      reload={reloadCount}
    />
  );

  const items = [
    {
      key: 'contracts',
      label: intl.formatMessage({ id: 'contracts.active_contracts' }),
      children: <>{tableElement}</>,
    },
  ];

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.contracts' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <StyledFarmContractsPage>
          <Form form={form}>
            <Tabs items={items} />
          </Form>
        </StyledFarmContractsPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};
