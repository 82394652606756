import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useAuth } from 'hooks/useAuth'
import GuestOrLoginPrompt from '../GuestOrLoginPrompt'
import ProcessPurchaseForm from '../ProcessPurchaseForm'
import { StyledProcessPurchase } from './style'
import { FarmProduct } from 'public/context/CartContext'
import CartSummary from '../CartSummary/CartSummary'
import { Form } from 'antd'

interface ProcessPurchaseProps {
  farmProducts: FarmProduct[]
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  current: number
  isView: boolean
}

const ProcessPurchase = ({
  farmProducts,
  setCurrent,
  current,
  isView,
}: ProcessPurchaseProps) => {
  const intl = useIntl()
  const { user } = useAuth()
  const [form] = Form.useForm()
  const [userForm] = Form.useForm()
  const [skip, setSkip] = useState<boolean>(user?.authenticated || false)

  return (
    <StyledProcessPurchase>
      {!skip && <GuestOrLoginPrompt setSkip={setSkip} />}

      {skip && (
        <>
          <div className={'form-summary-wrapper'}>
            <div className={'form-wrapper'}>
              <div className={'form-title'}>
                {intl.formatMessage({
                  id: 'process_purchase.client_information',
                })}
              </div>
              <ProcessPurchaseForm userForm={userForm} />
            </div>
            <CartSummary
              setCurrent={setCurrent}
              farmProducts={farmProducts}
              form={form}
              userForm={userForm}
              current={current}
              isView={isView}
            />
          </div>
        </>
      )}
    </StyledProcessPurchase>
  )
}

export default ProcessPurchase
