import { Checkbox } from '../Checkbox';
import { StyledQmarkCheckboxes } from './style';
import { useIntl } from 'react-intl';
import { DownOutlined, RightOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import { LocationsFilterOptions } from 'types/FilterOptions';

interface LocationProps {
  locations: LocationsFilterOptions[] | undefined;
}
export const LocationCheckboxes = ({ locations }: LocationProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const [isEmpty, setIsEmpty] = useState<boolean>();

  const intl = useIntl();

  const handleOnClick = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (locations?.length === 0) {
      setIsOpen(false);
      setIsEmpty(true);
    } else {
      setIsOpen(true);
      setIsEmpty(false);
    }
  }, [locations]);

  return (
    <StyledQmarkCheckboxes>
      <div
        className={'qmark-filter-title'}
        onClick={!isEmpty ? handleOnClick : undefined}
      >
        <div>{intl.formatMessage({ id: 'form.select_location' })}</div>
        <div className={'icon'}>
          {isOpen ? <DownOutlined /> : <RightOutlined />}
        </div>
      </div>
      {isOpen && (
        <Checkbox name="location" options={locations} filterView={true} />
      )}
    </StyledQmarkCheckboxes>
  );
};
