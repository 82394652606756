import React, { useState } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import {
  Tabs,
  Table,
  Button,
  CustomGrid,
  Input,
  TextArea,
  Modal,
  InputNumber,
} from 'ui';
import dayjs from 'dayjs';
import { StyledButtonList } from 'styles/buttonList';
import { Form } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';

const SettingListPage = () => {
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [selectedEntry, setSelectedEntry] = useState<number>(0);
  const [reload, setReload] = useState<number>(0);
  const [isModalVisiblePage, setIsModalVisiblePage] = useState<boolean>(false);
  const [activeKey, setActiveKey] = useState<string>('global_settings');

  const [form] = Form.useForm();
  const [formSecond] = Form.useForm();

  const intl = useIntl();

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/settings/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisible,
    },
    onSuccess: (response) => form.setFieldsValue(response),
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/settings/${selectedEntry}`
        : `api/v2/settings`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/pages-category/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisiblePage,
    },
    onSuccess: (response) => formSecond.setFieldsValue(response),
  });

  const { appendData: deletePageCategory } = useQueryApiClient({
    request: {
      url: 'api/v2/pages-category/:id',
      method: 'DELETE',
    },
    onSuccess: () => setReload((old) => old + 1),
  });

  const { appendData: appendDataPage } = useQueryApiClient({
    request: {
      url: selectedEntry
        ? `api/v2/pages-category/${selectedEntry}`
        : `api/v2/pages-category`,
      method: selectedEntry ? 'PATCH' : 'POST',
    },
    onSuccess: () => onModalClose(),
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'settings.key_name' }),
      dataIndex: 'key_name',
    },
    {
      title: intl.formatMessage({ id: 'settings.value' }),
      dataIndex: 'value',
    },
    {
      title: intl.formatMessage({ id: 'settings.description' }),
      dataIndex: 'description',
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisible(true);
            }}
          />
        </>
      ),
    },
  ];

  const pageColumns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'title',
    },
    {
      title: intl.formatMessage({ id: 'general.created_by' }),
      dataIndex: 'created_by',
    },
    {
      title: intl.formatMessage({ id: 'general.published' }),
      dataIndex: 'published',
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisiblePage(true);
            }}
          />
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.delete' })}
            onClick={() => deletePageCategory([], { id: value })}
          />
        </>
      ),
    },
  ];

  const items = [
    {
      key: 'global_settings',
      label: intl.formatMessage({ id: 'settings.global_settings' }),
      children: (
        <>
          <Table
            url="/api/v2/settings"
            columns={columns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
    {
      key: 'article_categories',
      label: intl.formatMessage({ id: 'settings.article_categories' }),
      children: (
        <>
          <Table
            url="/api/v2/pages-category"
            columns={pageColumns}
            disablePagination
            reload={reload}
          />
        </>
      ),
    },
  ];

  const onModalClose = () => {
    setIsModalVisible(false);
    setIsModalVisiblePage(false);
    setSelectedEntry(0);
    form.resetFields();
    formSecond.resetFields();
    setReload((old) => old + 1);
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.global_settings' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Button
                label={intl.formatMessage({ id: 'general.create_new' })}
                type="primary"
                onClick={() =>
                  activeKey === 'global_settings'
                    ? setIsModalVisible(true)
                    : setIsModalVisiblePage(true)
                }
              />
            </StyledButtonList>
          }
          items={items}
          onChange={setActiveKey}
        />

        <Modal
          open={isModalVisible}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'navigation.global_settings' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => form.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={form} onFinish={appendData} layout="vertical">
            <Input
              name="key_name"
              label={intl.formatMessage({ id: 'settings.key_name' })}
            />
            <Input
              name="value"
              label={intl.formatMessage({ id: 'settings.value' })}
            />
            <TextArea
              name="description"
              label={intl.formatMessage({ id: 'settings.description' })}
            />
          </Form>
        </Modal>

        <Modal
          open={isModalVisiblePage}
          onCancel={onModalClose}
          title={intl.formatMessage({ id: 'navigation.global_settings' })}
          footer={
            <CustomGrid gapRow={10}>
              <Button
                label={intl.formatMessage({ id: 'general.cancel' })}
                onClick={onModalClose}
              />
              <Button
                label={intl.formatMessage({ id: 'general.submit' })}
                type="primary"
                htmlType="submit"
                onClick={() => formSecond.submit()}
              />
            </CustomGrid>
          }
        >
          <Form form={formSecond} onFinish={appendDataPage} layout="vertical">
            <Input
              name="title"
              label={intl.formatMessage({ id: 'general.name' })}
            />
            <InputNumber
              name="published"
              label={intl.formatMessage({ id: 'general.published' })}
            />
          </Form>
        </Modal>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default SettingListPage;
