import styled from 'styled-components'

export const StyledIcon = styled.div<{
  type:
    | 'small'
    | 'medium'
    | 'large'
    | 'card'
    | 'filter-icon'
    | 'avarage'
    | 'header'
    | 'any'
}>`
  width: ${(props) =>
    props.type === 'small'
      ? props.theme.iconWidthSM
      : props.type === 'medium'
        ? props.theme.iconWidthMED
        : props.type === 'large'
          ? props.theme.iconWidthLG
          : props.type === 'filter-icon'
            ? props.theme.themeiconHeightFilter
            : props.type === 'avarage'
              ? props.theme.iconWidthAvarage
              : props.theme.iconWidthCard
                ? props.type === 'header'
                : props.theme.iconWidthHeader
                  ? props.type === 'any'
                  : props.theme.iconWidthAny};

  height: ${(props) =>
    props.type === 'small'
      ? props.theme.iconHeightSM
      : props.type === 'medium'
        ? props.theme.iconHeightMED
        : props.type === 'large'
          ? 'auto'
          : props.type === 'filter-icon'
            ? props.theme.themeiconHeightFilter
            : props.type === 'avarage'
              ? props.theme.iconHeightAvarage
              : props.theme.iconHeightCard
                ? props.type === 'header'
                : props.theme.iconHeihtHeader
                  ? props.type === 'any'
                  : props.theme.iconHeihtAny};

  img {
    width: ${(props) =>
      props.type === 'small'
        ? props.theme.iconWidthSM
        : props.type === 'medium'
          ? props.theme.iconWidthMED
          : props.type === 'large'
            ? props.theme.iconWidthLG
            : props.type === 'filter-icon'
              ? props.theme.iconWidthFilter
              : props.type === 'avarage'
                ? props.theme.iconWidthAvarage
                : props.theme.iconWidthCard
                  ? props.type === 'header'
                  : props.theme.iconWidthHeader
                    ? props.type === 'any'
                    : props.theme.iconWidthAny};

    height: ${(props) =>
      props.type === 'small'
        ? props.theme.iconHeightSM
        : props.type === 'medium'
          ? props.theme.iconHeightMED
          : props.type === 'large'
            ? 'auto'
            : props.type === 'filter-icon'
              ? props.theme.themeiconHeightFilter
              : props.type === 'avarage'
                ? props.theme.iconHeightAvarage
                : props.theme.iconHeightCard
                  ? props.type === 'header'
                  : props.theme.iconHeihtHeader
                    ? props.type === 'any'
                    : props.theme.iconHeihtAny};
  }
`
