import styled from 'styled-components'
import { Button } from 'antd'

export const ProfileMenuContainer = styled.div`
  background-color: ${(props) => props.theme.white};
  padding: 16px;
  width: 280px;
  font-family: ${(props) => props.theme.profileFontFamily};
  hr {
    width: calc(100% + 62px);
    color: ${(props) => props.theme.profileLineColor};
    margin-left: -32px;
  }
`

export const WelcomeText = styled.div`
  font-size: 18px;
  font-weight: bold;
  color: ${(props) => props.theme.profileColor};
  margin-bottom: 8px;
  line-height: 1.2;
  font-family: ${(props) => props.theme.profileFontFamilySecondary};
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;

  span {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;

    color: ${(props) => props.theme.profileColor};
  }
`

export const MenuItem = styled.div`
  padding: 12px 0;
  font-size: 16px;
  color: ${(props) => props.theme.profileBlack};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  &:not(:last-child) {
    border-bottom: 1px solid #eaeaea;
  }
`

export const AddCompanyButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  height: 52px;
  border-radius: 35px;
  font-weight: bold;
  font-size: 14px;
  background-color: ${(props) => props.theme.white};
  color: ${(props) => props.theme.brand01};
  border-color: ${(props) => props.theme.profileAddCompany};
  border-width: 1px;
`
