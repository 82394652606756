import { StyledCustomImage } from './style'
import React, { useEffect, useState } from 'react'
import { UploadFile } from 'antd/es/upload/interface'
import { Spinner } from '../../Spinner'
import { useIntl } from 'react-intl'
import { Checkbox } from '../../Checkbox'
import { Radio } from '../../Radio'
import { FallbackImage } from '../fallBack/FallBackImage'

interface CustomImageProps {
  file: UploadFile
  onRemove: (file: UploadFile) => void
  onEdit: (file: UploadFile) => void
  isThumbnail: boolean
  thumbUrl?: string
  onThumbnailChange: (uid: string) => void
}

export const CustomImage = ({
  file,
  onEdit,
  onRemove,
  isThumbnail,
  onThumbnailChange,
  thumbUrl,
}: CustomImageProps) => {
  const [loading, setLoading] = useState(false)

  const intl = useIntl()

  useEffect(() => {
    setLoading(file.status === 'uploading')
  }, [file])

  return (
    <Spinner spinning={loading} dontRender>
      <StyledCustomImage>
        <div key={file.uid} className="custom-upload-list-item">
          <div className={'overlay-wrap'}>
            <div className={'overlay'}>
              <Radio
                className="checkbox-thumbnail"
                checked={isThumbnail}
                onChange={() => onThumbnailChange(file.uid)}
                label={intl.formatMessage({ id: 'images.thumbnail' })}
              />
            </div>
          </div>
          <FallbackImage
            src={thumbUrl || file.url || file.preview}
            type={'ProductCard'}
            className="custom-upload-list-item-image"
            preview={false}
          />
          <div className="custom-upload-list-item-actions">
            <div onClick={() => onEdit(file)} className={'action'}>
              {intl.formatMessage({ id: 'general.edit' })}
            </div>
            <div onClick={() => onRemove(file)} className={'action'}>
              {intl.formatMessage({ id: 'general.delete' })}
            </div>
          </div>
        </div>
      </StyledCustomImage>
    </Spinner>
  )
}
