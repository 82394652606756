import React, { useEffect } from 'react'
import { Input } from 'ui'
import { Form } from 'antd'
import { useIntl } from 'react-intl'
import { StyledSearch } from './style'

export interface SearchProps {
  placeholder?: string
  required?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  type?: string
  disabled?: boolean
  size?: 'large' | 'middle' | 'small'
  value?: string
  onSearch?: (t: string) => void
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  defaultValue?: string
  label?: string
  onClick?: React.MouseEventHandler<HTMLInputElement>
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  isReset?: boolean
  isPlaceholder?: boolean
}

export const Search = ({
  disabled,
  placeholder,
  required,
  prefix,
  suffix,
  type,
  size,
  value,
  onSearch,
  onChange,
  defaultValue,
  label,
  onClick,
  onBlur,
  isReset,
  isPlaceholder = true
}: SearchProps) => {
  const intl = useIntl()
  const [searchForm] = Form.useForm()

  const onFinish = () => {
    const { search } = searchForm.getFieldsValue()
    !!onSearch && !!search && onSearch(search)
  }

  if (isReset) {
    searchForm.resetFields()
  }

  useEffect(() => {
    if (value) {
      searchForm.setFieldValue('search', value)
    }
  }, [value]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <StyledSearch onClick={onClick}>
      <Form
        onFinish={onFinish}
        form={searchForm}
        name="search-form"
        layout="vertical"
        className={'flex-grow'}
      >
        <Form.Item name="search" noStyle>
          <Input
            disabled={disabled}
            placeholder={
              isPlaceholder ?
                !!placeholder
                  ? placeholder
                  : intl.formatMessage({
                    id: 'general.search',
                  })
                : ""
            }
            required={required}
            prefix={prefix}
            suffix={suffix}
            type={type}
            size={size}
            allowClear
            onChange={onChange}
            defaultValue={defaultValue}
            label={label}
            onBlur={onBlur}
          />
        </Form.Item>
      </Form>
    </StyledSearch>
  )
}
