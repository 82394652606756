import { useIntl } from 'react-intl';
import { Rule } from 'rc-field-form/lib/interface';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { MansLauksProduct } from '../ui/Drawer/Drawers/DrawerMansLauksProducts';

function useFormValidation() {
  const intl = useIntl();
  const form = useFormInstance();

  const nogaAvailabilityNumberCheck = (product: MansLauksProduct) => {
    return async (_: any, value: any) => {
      const mansLauksAvailability = product.amount;
      const mansLauksUnit = product.unit;
      const nonDecimalUnits = ['iep', 'gab'];

      if (value < 0) {
        return Promise.reject(
          new Error(
            intl.formatMessage({
              id: 'validation.value_must_be_positive',
            })
          )
        );
      }

      if (nonDecimalUnits.includes(mansLauksUnit) && value % 1 !== 0) {
        return Promise.reject(
          new Error(
            intl.formatMessage({
              id: 'validation.value_must_not_be_decimal',
            })
          )
        );
      }

      if (value > mansLauksAvailability) {
        return Promise.reject(
          new Error(
            intl.formatMessage({
              id: 'validation.value_bigger_than_availability',
            })
          )
        );
      }

      if (!value) {
        return Promise.reject(
          new Error(intl.formatMessage({ id: 'validation.please_enter_value' }))
        );
      }

      await Promise.resolve();
    };
  };

  const handleValidations = (validation: string): Rule => {
    switch (validation) {
      case 'required':
        return {
          required: true,
          message: intl.formatMessage({ id: 'validation.required' }),
        };
      case 'regNr':
        return {
          pattern: /^\d{11}$/,
          message: intl.formatMessage({ id: 'validation.reg_nr' }),
        };
      case 'personalCode':
        return {
          pattern: /^\d{11}$/,
          message: intl.formatMessage({ id: 'validation.personal_code' }),
        };
      case 'email':
        return {
          pattern: /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
          message: intl.formatMessage({ id: 'validation.email_not_valid' }),
        };
      case 'phoneNumber':
        return {
          pattern: /^[+]?[0-9]+$/,
          message: intl.formatMessage({
            id: 'validation.phone_number_not_valid',
          }),
        };
      case 'minZeroNumber':
        return {
          type: 'number',
          min: 0,
          message: intl.formatMessage({ id: 'general.invalid_number' }),
          required: true,
        };
      case 'requiredText':
        return {
          type: 'string',
          required: true,
          message: intl.formatMessage({ id: 'validations.required_name' }),
        };
      case 'priceGreaterThanDiscount':
        return {
          validator: async (_, value) => {
            const discount = form.getFieldValue('storage_stock_discount_price');
            if (
              value !== undefined &&
              discount !== undefined &&
              value <= discount
            ) {
              throw new Error(
                intl.formatMessage({ id: 'validation.price_must_be_greater' })
              );
            }
          },
        };
      case 'reemail':
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('email') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(intl.formatMessage({ id: 'register.reemail_mismatch' }))
            );
          },
        });
      case 'password_confirmation':
        return ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve();
            }
            return Promise.reject(
              new Error(
                intl.formatMessage({ id: 'register.password_mismatch' })
              )
            );
          },
        });
      case 'minAmountNotExceedAvailability':
        return {
          validator: async (_, value) => {
            const step = form.getFieldValue('step');
            const availability = form.getFieldValue('availability');
            if (
              typeof value === 'number' &&
              typeof step === 'number' &&
              typeof availability === 'number'
            ) {
              if (value * step > availability) {
                throw new Error(
                  intl.formatMessage({
                    id: 'validation.min_amount_exceeds_availability',
                  })
                );
              }
            }
          },
        };
      case 'pickDateIfDiscount':
        return {
          validator: async (_, value) => {
            const discount = form.getFieldValue('storage_stock_discount_price');
            if (discount > 0 && !value) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({
                    id: 'validation.date_required_if_discount',
                  })
                )
              );
            }
            return Promise.resolve();
          },
        };
      case 'correctStepForUnit':
        return {
          validator: async (_, value) => {
            const static_units = ['iep', 'gab'];
            const unit = form.getFieldValue('storage_unit');
            if (!static_units.includes(unit) && value > 0) {
              return Promise.resolve();
            }
            if (static_units.includes(unit) && value % 1 !== 0) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({ id: 'validation.decimal_number' })
                )
              );
            }
            if (value <= 0) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({ id: 'validation.negative_number' })
                )
              );
            }
          },
        };
      case 'requiredIfNoNumberValuesAdded':
        return {
          validator: async (_, value) => {
            const nutritionalValues =
              form.getFieldValue('nutritional_values') || {};
            const ingredients = form.getFieldValue('ingredients') || '';

            const hasIngredients =
              typeof ingredients === 'string' && ingredients.trim() !== '';

            const hasAtLeastOneMacro = Object.values(nutritionalValues).some(
              (macroVal) =>
                macroVal !== undefined && macroVal !== null && macroVal !== ''
            );

            if (hasIngredients || hasAtLeastOneMacro) {
              return Promise.resolve();
            }

            if (
              value === undefined ||
              value === null ||
              value === '' ||
              (typeof value === 'number' && value < 0)
            ) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({
                    id: 'validation.need_atleast_one_ingredient_or_macro',
                  })
                )
              );
            }

            return Promise.resolve();
          },
        };
      case 'farmBankAccount':
        return {
          validator: async (_, value) => {
            const emarket_bank_account = form.getFieldValue(
              'emarket_bank_account'
            );

            if (emarket_bank_account.length !== 21) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({ id: 'validation.invalid_bank_number' })
                )
              );
            }

            return Promise.resolve();
          },
        };
      case 'adminFarmBankAccount':
        return {
          validator: async (_, value) => {
            const contract_nr = form.getFieldValue('contract_nr');
            const contract_date = form.getFieldValue('contract_date');
            const emarket_bank_account = form.getFieldValue(
              'emarket_bank_account'
            );

            if (!contract_nr) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({
                    id: 'validation.contract_number_required',
                  })
                )
              );
            }

            if (!contract_date) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({
                    id: 'validation.contract_date_required',
                  })
                )
              );
            }

            if (!emarket_bank_account || emarket_bank_account.length !== 21) {
              return Promise.reject(
                new Error(
                  intl.formatMessage({
                    id: 'validation.problem_with_bank_account',
                  })
                )
              );
            }

            return Promise.resolve();
          },
        };
      case 'none':
        return { required: false };
    }

    return {};
  };

  const formValidations = (validations: string[] | string) => {
    if (typeof validations === 'string') {
      return [handleValidations(validations)];
    }

    return validations.map(handleValidations);
  };

  return { formValidations, nogaAvailabilityNumberCheck };
}

export default useFormValidation;
