import React, { CSSProperties } from 'react'
import { Drawer as AntdDrawer, FormInstance } from 'antd'

export interface DrawerProps {
  open?: boolean
  title?: React.ReactNode
  closable?: boolean
  width?: string | number
  destroyOnClose?: boolean
  children?: React.ReactNode
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
  footer?: React.ReactNode
  maskClosable?: boolean
  style?: CSSProperties
  headerStyle?: CSSProperties
  id?: string | undefined
}

export const Drawer = ({
  open,
  children,
  title,
  width = '50%',
  destroyOnClose,
  closable = true,
  onClose,
  footer,
  maskClosable,
  style,
  id = undefined,
  headerStyle,
}: DrawerProps) => {
  const headerStyleGlobal: CSSProperties = {
    minHeight: 113,
  }

  return (
    <AntdDrawer
      id={id}
      open={open}
      title={title}
      headerStyle={{ ...headerStyleGlobal, ...headerStyle }}
      width={width}
      destroyOnClose={destroyOnClose}
      closable={closable}
      onClose={onClose}
      getContainer={document.getElementById('app-root')!}
      footer={footer}
      maskClosable={maskClosable}
      style={style}
    >
      {children}
    </AntdDrawer>
  )
}
