import styled from 'styled-components';
import { Drawer as AnteDrawer } from 'antd';

export const StyledDrawer = styled(AnteDrawer)`
  .ant-tabs-nav-list {
    cursor: default;
  }

  .unit-value {
    display: flex;
    flex-direction: row;
  }

  .ant-drawer-header {
    min-height: 113px;
  }

  .ant-drawer-header-title {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;

    .ant-drawer-close {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 22px;

      svg {
        width: 22px;
        height: 22px;
      }
    }

    .ant-drawer-title {
      max-width: 300px;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
    }
  }

  .ant-drawer-body {
    .preview {
      display: flex;
      flex-wrap: wrap;
      width: auto;
      height: 800px;
    }
  }

  .ant-drawer-footer {
    .footer {
    }
  }
`;

export const StyledFooter = styled.div`
  .btns {
    display: flex;
    gap: 10px;
    justify-content: flex-end;
  }
`;

export const StyledMansLauksDrawer = styled(AnteDrawer)`
  .unit-value {
    display: flex;
    gap: 5px;
  }

  .ant-form-item-explain-error {
    width: 250px;
    font-size: 12px;
  }

  .availability-unit {
    display: flex !important;
    gap: 10px;
    .ant-form-item {
      width: 100px;
    }
  }

  .ant-drawer-header {
    min-height: 113px;
  }

  .ant-drawer-footer {
    display: flex;
    justify-content: right;
  }
`;

export const StyledCartDrawerFooter = styled.div`
  .cart-drawer-sum-wrapper {
    display: flex;
    justify-content: end;
    gap: 15px;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-family: ${(props) => props.theme.fontText};

    .cart-drawer-sum-title {
      font-weight: 400;
    }

    .cart-drawer-sum {
      font-weight: 700;
    }
  }

  button {
    height: 52px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
    letter-spacing: 0.1px;
    margin-bottom: 10px;
  }
`;
export const StyledDeliveryDrawerFooter = styled.div`
  .delivery-drawer-btn.mobile {
    display: flex;
    flex-direction: row-reverse;
    background-color: transparent;
    height: 52px;
    width: 100%;
    margin: 10px 0 10px 0;
    border: 1px solid ${(props) => props.theme.darkRed};
    color: ${(props) => props.theme.darkRed};
    font-weight: 500;
  }

  .delivery-drawer-btn {
    display: flex;
    height: 52px;
    width: 100%;
    font-weight: 700;
    font-size: 16px;
  }
`;

export const MobileMenuStyledDrawer = styled(AnteDrawer)`
  .title,
  .text {
    margin: 0;
  }

  .title {
    font-size: 16px;
    font-weight: 600;
    line-height: 28px;
    margin-bottom: 10px;
  }

  .text {
    font-size: 14px;
    line-height: 14px;

    .bold-text {
      font-weight: 600;
    }
  }

  &.mobile-menu {
    background-color: ${(props) => props.theme.brand01} !important;
    box-shadow: 0px 4px 84px 0px ${(props) => props.theme.halfBlack};
  }

  .ant-menu-item {
    color: ${(props) => props.theme.white} !important;
  }

  .ant-drawer-body {
    padding: 20px;
  }

  @media only screen and (max-width: 1200px) {
    &.mobile-menu-drawer {
      position: relative;
      margin: 0 !important;
      padding: 0;
      display: block;
    }

    &.mobile-filter {
      top: calc(100vh - 750px) !important;
    }

    &.mobile-menu {
      top: calc(100vh - 400px) !important;
    }
  }
`;

export const MyProfileStyledDrawer = styled(AnteDrawer)`
  &.mobile-menu {
    margin-top: calc(100vh - 450px) !important;
  }
`;
