import styled from 'styled-components';
import { Carousel } from 'antd';

export const StyledCarousel = styled(Carousel)`
  margin: 0 0 10px 0;
  flex-grow: 1;

  .carusel-banner-image {
    width: 100%;
  }

  img {
    width: max-content;
  }

  li.slick-active {
    img {
      border: 2px solid ${(props) => props.theme.white} !important;
    }
  }

  .slick-slide {
    display: flex !important;
    justify-content: center;

    .slick-active {
    }
  }

  .slick-slide > div {
    width: ${(props) => props.rootClassName === 'full' ? '100%' : 'auto'};
    height: ${(props) => props.rootClassName === 'full' ? '371px' : 'auto'};
  }

  .slick-slide img {
    height: ${(props) => props.rootClassName === 'full' ? '371px' : 'auto'};
    object-fit: cover;
  }

  .slick-list {
    min-height: 30px;
  }

  .custom-dot {
    height: 116px;
    width: 116px;
  }

  .banner-image-wrapper {
    padding: 50px 0 50px 0;
  }

  .ant-image {
    width: ${(props) => props.rootClassName === 'full' ? '100%' : 'auto'};
  }

  @media only screen and (max-width: 1200px) {
    .banner-image-wrapper {
      padding: 30px 0 30px 0;
    }
  }

  @media only screen and (max-width: 768px) {
    .banner-image-wrapper {
      padding: 20px 0px 20px 0px;
    }
  }

  @media only screen and (max-width: 440px) {
    margin: 0 0 20px 0;
    &:last-child {
      margin: 0 0 0px 0;
    }

    .banner-image-wrapper {
      padding: 20px 0px 20px 0px;
      height: 250px;

      a img {
        height: 250px;
        object-fit: cover;
        object-position: left;
      }
    }
  }
`;

export const StyledCustomPaging = styled('div')`
  position: relative;
  height: 116px;
  width: 116px;

  &.custom-dot {
    position: absolute;
    left: -42px;
  }
`;

export const StyledDotContainer = styled('div')`
  position: relative;
  display: flex;
  height: 100%;
  width: 100%;
`;

export const StyledImage = styled('img')`
  position: relative;
  top: 25px;
  left: 0;
  max-width: 116px;
  max-height: 116px;
  object-fit: cover;
  border-radius: 4px;
`;

export const StyledAppendDotsContainer = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  margin-top: 20px;
`;

export const StyledAppendDotsInner = styled('div')`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  gap: 110px;
  align-items: center;

  li {
    width: 24px !important;
  }
`;
