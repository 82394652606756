import styled from 'styled-components';

export const StyledMobileProfielOptions = styled.div`
  .mobile-profile-options-item-flex {
    justify-content: space-between;
  }

  .mobile-profile-options-item {
    display: flex;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};
    font-family: ${(props) => props.theme.fontText};

    .mobile-profile-options-item-start {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .order-count {
      display: inline-block;
      height: 28px;
      padding: 0 16px;
      background-color: ${(props) => props.theme.colorBorderSecondary};
      font-size: 12px;
      line-height: 28px;
      border-radius: 30px;
      color: ${(props) => props.theme.black2};
    }

    p {
      padding-left: 10px;
      margin: 0;
      font-size: 16px;
    }
  }
`;
