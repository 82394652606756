import { useIntl } from 'react-intl'
import { CSSProperties } from 'react'
import { LocationsFilterOptions } from 'types/FilterOptions'
import { Select, SelectOption } from 'ui'
import { FormInstance } from 'antd'
import { StyledLocationSelector } from './style'

interface Props {
  locations: LocationsFilterOptions[] | undefined
  isLoading?: boolean
  form?: FormInstance<any>
}

export const LocationSelector = ({ locations, form }: Props) => {
  const intl = useIntl()
  const value = form ? form.getFieldValue('location') : ''

  const style: CSSProperties = {
    minWidth: 245,
    minHeight: 45,
    marginBottom: 5,
    maxWidth: 245,
  }

  return (
    <StyledLocationSelector>
      <div className={'form-title'}>
        {intl.formatMessage({ id: 'form.location_title' })}
      </div>
      <Select
        placeholder={intl.formatMessage({ id: 'form.select_location' })}
        className={`drop-down ${!!value && 'selected'}`}
        showSearch={true}
        name={'location'}
        size={'small'}
        allowClear
        style={style}
      >
        {locations?.map((location, index) => (
          <SelectOption key={`${index}-loc`} value={location.slug}>
            {location.name}
          </SelectOption>
        ))}
      </Select>
    </StyledLocationSelector>
  )
}
