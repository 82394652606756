import { RcFile } from 'antd/es/upload/interface'
import { options } from '../config/config'
import { message, Upload } from 'antd'
import { IntlShape } from 'react-intl'

export const beforeUpload = async (file: RcFile, intl: IntlShape) => {
  const maxSizeInMB = options.images.maxSizeInMB
  const allowedFormats = options.images.allowedFormats

  if (file.size / 1024 / 1024 > maxSizeInMB) {
    message.error(
      intl.formatMessage({
        id: 'validation.max_file_size',
      })
    )
    return Upload.LIST_IGNORE
  }

  if (!allowedFormats.includes(file.type)) {
    message.error(
      intl.formatMessage({
        id: 'validation.allowed_formats',
      })
    )
    return Upload.LIST_IGNORE
  }
  return true
}

export const getMimeType = (fileName: any) => {
  const extension = fileName.split('.').pop().toLowerCase()

  const mimeTypes: any = {
    png: 'image/png',
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    gif: 'image/gif',
    bmp: 'image/bmp',
    svg: 'image/svg+xml',
  }
  return mimeTypes[extension] || 'application/octet-stream'
}
