import React, { CSSProperties, useEffect, useState } from 'react';
import { Select, SelectOption } from '../Select';
import { CategoryData } from '../../../types/CategoryData';
import { useIntl } from 'react-intl';
import useFormValidation from '../../../utils/useFormValidation';
import { Validations } from '../../../interfaces';
import { Rule } from 'rc-field-form/lib/interface';
import { useCategoryDispatch, useCategoryState } from '../../../public/context';
import { StyledCategorySelector } from './style';
import {
  findSelectedCategory,
  findSelectedSubcategory,
  getSubcategories,
  getSubSubCategories,
} from '../../../utils/categoryHelpers';

export interface categoryProps extends Validations {
  displayFlex?: boolean;
  admin?: boolean;
  rules?: Rule[];

  mainCategoryValue?: number;
  onMainCategoryChange?: (value: string) => void;
  subCategoryValue?: number;
  onSubCategoryChange?: (value: string) => void;
  subSubCategoryValue?: number;
  onSubSubCategoryChange?: (value: string) => void;
}

const styleProps: CSSProperties = {
  minWidth: 245,
  minHeight: 41,
  marginBottom: 0,
  maxWidth: 255,
};

const findCategory = (id: number | undefined, categories?: CategoryData[]) =>
  categories?.find((cat) => cat.id === id);

export const CategorySelector = ({
  admin,
  validations,
  rules,
  mainCategoryValue,
  onMainCategoryChange,
  subCategoryValue,
  onSubCategoryChange,
  subSubCategoryValue,
  onSubSubCategoryChange,
}: categoryProps) => {
  const intl = useIntl();
  const { formValidations } = useFormValidation();
  const { category } = useCategoryState();
  const dispatch = useCategoryDispatch();

  const categoriesObj: CategoryData[] = Object.values(category || []);
  const [selectedCategory, setSelectedCategory] = useState<CategoryData>();
  const [subcategories, setSubcategories] = useState<CategoryData[]>([]);
  const [selectedSubcategory, setSelectedSubcategory] =
    useState<CategoryData>();
  const [subSubCategories, setSubSubCategories] = useState<CategoryData[]>([]);
  const [selectedSubSubCategory, setSelectedSubSubCategory] =
    useState<CategoryData>();

  const isMainCategoryControlled = mainCategoryValue !== undefined;
  const isSubCategoryControlled = subCategoryValue !== undefined;
  const isSubSubCategoryControlled = subSubCategoryValue !== undefined;

  const handleMainCategoryChange = (value: string) => {
    if (onMainCategoryChange) {
      onMainCategoryChange(value);
      return;
    }

    const selected = findSelectedCategory(categoriesObj, Number(value));
    setSelectedCategory(selected);
    setSelectedSubcategory(undefined);
    setSelectedSubSubCategory(undefined);
    setSubSubCategories([]);
    setSubcategories(getSubcategories(selected));
    dispatch({
      type: 'SAVE_ACTIVE_CATEGORY',
      activeCategory: selected || null,
    });
  };

  const handleSubcategoryChangeLocal = (value: string) => {
    if (onSubCategoryChange) {
      onSubCategoryChange(value);
      return;
    }

    const selectedSub = findSelectedSubcategory(subcategories, Number(value));
    setSelectedSubcategory(selectedSub);
    setSelectedSubSubCategory(undefined);
    setSubSubCategories(getSubSubCategories(selectedSub));
  };

  const handleSubSubCategoryChangeLocal = (value: string) => {
    if (onSubSubCategoryChange) {
      onSubSubCategoryChange(value);
      return;
    }

    const selectedSubSub = findSelectedSubcategory(
      subSubCategories,
      Number(value)
    );
    setSelectedSubSubCategory(selectedSubSub);
  };

  const currentMainCategoryId = isMainCategoryControlled
    ? mainCategoryValue
    : selectedCategory?.id;
  const currentSubCategoryId = isSubCategoryControlled
    ? subCategoryValue
    : selectedSubcategory?.id;
  const currentSubSubCategoryId = isSubSubCategoryControlled
    ? subSubCategoryValue
    : selectedSubSubCategory?.id;

  useEffect(() => {
    let subCategories: CategoryData[] = [];
    let subSubCategories: CategoryData[] = [];
    if (isMainCategoryControlled) {
      const selected = findSelectedCategory(
        categoriesObj,
        mainCategoryValue as number
      );

      subCategories = getSubcategories(selected);
      setSubcategories(subCategories);

      const selectedSub = findSelectedSubcategory(
        subCategories,
        subCategoryValue as number
      );

      subSubCategories = getSubSubCategories(selectedSub);

      setSubSubCategories(subSubCategories);

      const selectedSubSub = findSelectedSubcategory(
        subSubCategories,
        subSubCategoryValue as number
      );
      setSelectedSubSubCategory(selectedSubSub);
    }
  }, []);

  return (
    <StyledCategorySelector>
      <div className={'category-sel-wrap'}>
        <div>
          <div className={admin ? 'admin' : ''}>
            <div>
              <div className={!admin ? 'form-title' : 'label'}>
                {!admin
                  ? intl.formatMessage({ id: 'form.categories_title' })
                  : intl.formatMessage({ id: 'general.group' })}
              </div>
              <Select
                placeholder={intl.formatMessage({
                  id: 'form.select_main_category',
                })}
                className="drop-down"
                showSearch={false}
                name={'main-category'}
                size={'small'}
                allowClear
                style={styleProps}
                onChange={handleMainCategoryChange}
                value={currentMainCategoryId}
                rules={validations ? formValidations(validations) : rules}
              >
                {categoriesObj.map((category) => (
                  <SelectOption key={category.id} value={category.id}>
                    {category.name}
                  </SelectOption>
                ))}
              </Select>
              {!admin && (
                <>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'form.select_sub_category',
                    })}
                    className="drop-down"
                    showSearch={false}
                    name={'sub-category'}
                    size={'small'}
                    allowClear
                    style={styleProps}
                    onChange={handleSubcategoryChangeLocal}
                    value={currentSubCategoryId}
                    disabled={!currentMainCategoryId && admin}
                    rules={validations ? formValidations(validations) : rules}
                  >
                    {subcategories.map((subcategory) => (
                      <SelectOption key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </SelectOption>
                    ))}
                  </Select>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'form.select_sub_subcategory',
                    })}
                    className="drop-down"
                    showSearch={false}
                    name={'sub-subcategory'}
                    size={'small'}
                    allowClear
                    style={styleProps}
                    onChange={handleSubSubCategoryChangeLocal}
                    value={currentSubSubCategoryId}
                    disabled={!currentSubCategoryId && admin}
                    rules={validations ? formValidations(validations) : rules}
                  >
                    {subSubCategories.map((subSubCategory) => (
                      <SelectOption
                        key={subSubCategory.id}
                        value={subSubCategory.id}
                      >
                        {subSubCategory.name}
                      </SelectOption>
                    ))}
                  </Select>
                </>
              )}
            </div>

            {admin && (
              <>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div className={!admin ? 'form-title' : 'label'}>
                    {!admin
                      ? intl.formatMessage({ id: 'form.categories_title' })
                      : intl.formatMessage({ id: 'general.category' })}
                  </div>

                  <Select
                    placeholder={intl.formatMessage({
                      id: 'form.select_sub_category',
                    })}
                    className="drop-down"
                    showSearch={false}
                    name={'sub-category'}
                    size={'small'}
                    allowClear
                    style={styleProps}
                    onChange={handleSubcategoryChangeLocal}
                    value={currentSubCategoryId}
                    disabled={!currentMainCategoryId && admin}
                    rules={validations ? formValidations(validations) : rules}
                  >
                    {subcategories.map((subcategory) => (
                      <SelectOption key={subcategory.id} value={subcategory.id}>
                        {subcategory.name}
                      </SelectOption>
                    ))}
                  </Select>
                </div>

                <div>
                  <div className={!admin ? 'form-title' : 'label'}>
                    {!admin
                      ? intl.formatMessage({ id: 'form.categories_title' })
                      : intl.formatMessage({ id: 'general.type' })}
                  </div>
                  <Select
                    placeholder={intl.formatMessage({
                      id: 'form.select_sub_subcategory',
                    })}
                    className="drop-down"
                    showSearch={false}
                    name={'sub-subcategory'}
                    size={'small'}
                    allowClear
                    style={styleProps}
                    onChange={handleSubSubCategoryChangeLocal}
                    value={currentSubSubCategoryId}
                    disabled={!currentSubCategoryId && admin}
                    rules={validations ? formValidations(validations) : rules}
                  >
                    {subSubCategories.map((subSubCategory) => (
                      <SelectOption
                        key={subSubCategory.id}
                        value={subSubCategory.id}
                      >
                        {subSubCategory.name}
                      </SelectOption>
                    ))}
                  </Select>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </StyledCategorySelector>
  );
};
