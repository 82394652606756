import React, { useEffect, useState } from 'react'
import { UploadFile } from 'antd/es/upload/interface'
import { CustomImage } from './CustomImage'
import { StyledCustomUploadList } from './style'

interface CustomUploadListProps {
  fileList: UploadFile[]
  onRemove: (file: UploadFile) => void
  onEdit: (file: UploadFile) => void
  thumbUrls: { [uid: string]: string }
}

export const CustomUploadList: React.FC<CustomUploadListProps> = ({
  fileList,
  onRemove,
  onEdit,
  thumbUrls,
}) => {
  const [selectedThumbnailUid, setSelectedThumbnailUid] = useState<
    string | null
  >(null)

  useEffect(() => {
    if (
      (!selectedThumbnailUid && fileList.length > 0) ||
      (selectedThumbnailUid &&
        !fileList.find((file) => file.uid === selectedThumbnailUid))
    ) {
      setSelectedThumbnailUid(fileList[0]?.uid || null)
    }
  }, [fileList, selectedThumbnailUid])

  const handleThumbnailChange = (uid: string) => {
    setSelectedThumbnailUid(uid)
  }

  return (
    <StyledCustomUploadList>
      {fileList.map((file) => (
        <CustomImage
          key={file.uid}
          file={file}
          onEdit={onEdit}
          thumbUrl={thumbUrls[file.uid]}
          onRemove={onRemove}
          isThumbnail={selectedThumbnailUid === file.uid}
          onThumbnailChange={handleThumbnailChange}
        />
      ))}
    </StyledCustomUploadList>
  )
}
