import React, { CSSProperties } from 'react'
import { Modal as AntdModal } from 'antd'
export interface ModalProps {
  open?: boolean
  title?: React.ReactNode
  closable?: boolean
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  width?: string | number
  footer?: React.ReactNode
  destroyOnClose?: boolean
  children?: React.ReactNode
  style?: React.CSSProperties
  className?: string
}

export const Modal = ({
  open,
  children,
  footer,
  title,
  width,
  destroyOnClose,
  closable = true,
  onCancel,
  style,
  className,
}: ModalProps) => {
  return (
    <AntdModal
      className={className}
      open={open}
      footer={footer}
      title={title}
      width={width}
      destroyOnClose={destroyOnClose}
      getContainer={document.getElementById('app-root')!}
      closable={closable}
      onCancel={onCancel}
      style={style}
    >
      {children}
    </AntdModal>
  )
}
