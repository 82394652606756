import React, {useEffect, useRef, useState} from 'react'
import {Form, message, Upload, UploadFile, UploadProps} from 'antd'
import {useIntl} from 'react-intl'
import {StyledNewProductsGallery} from './style'
import {getBase64} from '../../../utils/productCardHelpers'
import {CustomUploadList} from './CustomUploadList'
import ImgCrop from 'antd-img-crop'
import {options} from '../../../config/config'

interface NewProductsGalleryProps {
  onValidate: (isValid: boolean) => void
  onFileListChange?: (fileList: UploadFile[]) => void
  fileList: UploadFile[],
  multiple?: boolean
}

export const NewProductsGallery = (newProductProps: NewProductsGalleryProps) => {
  const {
    onValidate,
    onFileListChange,
    fileList,
    multiple = true
  } = newProductProps;

  const [thumbUrls, setThumbUrls] = useState<{ [uid: string]: string }>({})
  const [editingFile, setEditingFile] = useState<UploadFile | null>(null)
  const uploadRef = useRef<any>(null)
  const intl = useIntl()

  const allowedTypes = options.images.allowedFormats
  const maxImages = multiple ? options.images.maxImages : 1

  useEffect(() => {
    if (fileList.length === 0) {
      setThumbUrls({})
    }
  }, [fileList])

  const renderAllowedTypes = () => {
    const types = allowedTypes.map((type) => type.replace('image/', ''))

    return types.join(',')
  }

  const handleChange = async (info: { fileList: UploadFile[] }) => {

    let newList = [...info.fileList]

    newList = newList.filter((file) => {
      const isValidType = allowedTypes.includes(file.type || '')
      if (!isValidType) {
        message.error(
        	`${intl.formatMessage({
        	  id: 'images.allowed_types',
        	})}${renderAllowedTypes()}`
        )
      }
      return isValidType
    })

    if (maxImages && newList.length > maxImages) {
      message.warning(
        `${intl.formatMessage({ id: 'images.max_images' })}${maxImages}`
      )
      newList = newList.slice(0, maxImages)
    }

    const updatedThumbUrls: { [uid: string]: string } = {}
    await Promise.all(
      newList.map(async (file: UploadFile) => {
        if (!file.url && !file.thumbUrl && file.originFileObj) {
          const thumbUrl = await getBase64(file.originFileObj as File)
          updatedThumbUrls[file.uid] = thumbUrl
        } else if (file.thumbUrl) {
          updatedThumbUrls[file.uid] = file.thumbUrl
        }
      })
    )

    setThumbUrls((prev) => ({ ...prev, ...updatedThumbUrls }))
    onValidate(newList.length > 0)
    if (onFileListChange) {
      onFileListChange(newList)
    }
  }

  const onEdit = (file: any) => {
    setEditingFile(file)
    if (uploadRef) {
      uploadRef.current.click()
    }
  }

  const handleEditBeforeUpload = (file: any) => {
    if (!editingFile) return false

    const croppedFile: UploadFile = {
      uid: editingFile.uid,
      name: file.name,
      status: 'done',
      url: URL.createObjectURL(file),
      originFileObj: file,
    }

    const newFileList = fileList.map((f) =>
      f.uid === editingFile.uid ? croppedFile : f
    )

    onFileListChange && onFileListChange(newFileList)
    message.success('Image edited successfully!')
    setEditingFile(null)
    return false
  }

  const handleRemove = (file: UploadFile) => {
    const newFileList = fileList.filter((item) => item.uid !== file.uid)
    const newThumbUrls = { ...thumbUrls }

    delete newThumbUrls[file.uid]
    setThumbUrls(newThumbUrls)

    onValidate(newFileList.length > 0)
    if (onFileListChange) {
      onFileListChange(newFileList)
    }
  }

  const props: UploadProps = {
    name: multiple ? 'image' : 'logo_image',
    multiple: multiple,
    onChange: handleChange,
    fileList: fileList,
    listType: 'picture-card',
    showUploadList: false,
    onRemove: handleRemove,
  }
  const hiddenUploadProps: UploadProps = {
    name: multiple ? 'image' : 'logo_image',
    accept: 'image/*',
    multiple: multiple,
    showUploadList: false,
    fileList: editingFile ? [editingFile] : [],
    customRequest: ({ onSuccess }) => {
      onSuccess && onSuccess('ok')
    },
  }

  return (
    <div>
      <Form.Item name="images">
        <ImgCrop rotationSlider showReset>
          <StyledNewProductsGallery {...props}>
            <p className="ant-upload-drag-icon">
              <img src={'/svgs/upload-image.svg'} alt="upload icon" />
            </p>
            <p className="ant-upload-text">
              <a>{intl.formatMessage({ id: 'gallery.upload_file' })} </a>
              {intl.formatMessage({ id: 'gallery.or_here' })}
            </p>
          </StyledNewProductsGallery>
        </ImgCrop>
      </Form.Item>
      <CustomUploadList
        fileList={fileList}
        thumbUrls={thumbUrls}
        onRemove={handleRemove}
        onEdit={onEdit}
      />

      <ImgCrop rotationSlider>
        <div ref={uploadRef}>
          <Upload {...hiddenUploadProps} />
        </div>
      </ImgCrop>
    </div>
  )
}
