import styled from 'styled-components'
import { Form } from 'antd'

export const StyledLoginForm = styled(Form)`
    display: flex;
    min-height: 545px;
    background-color: ${(props) => props.theme.white};
    font-family: ${(props) => props.theme.fontText};
    border-radius: 6px;
    margin-right: 200px;
    width: 100%;
    max-width: 640px;

    
    @media only screen and (max-width: 1200px) {
        width: 100%;
    }

    .accept_terms  {
        display: flex;
        align-items: center;
        gap: 5px;

        .link {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
            color: ${(props) => props.theme.darkGrayishBlue};
        }
    }

    .ant-form-item {
        margin-bottom: 5px;

        .ant-form-item-control-input-content {
            input {
                height: 55px;
            }
        }
        
        .ant-input-suffix {
            height: 55px;
        }
    }

    .accept_terms .ant-form-item {
        margin-bottom: 0;
    }

    .inputs {
        display: flex;
        flex-direction: column;
        padding: 39px 67px 55px 70px;
        gap: 10px;
        max-width: 640px;
        width: 640px;

        .description {
            margin: 0;
            margin-bottom: 7px;
            font-size: 14px;
            font-weight: 400;
            line-height: 30px;
            color: ${(props) => props.theme.darkGrayishBlue};
        }

        .ant-input::placeholder {
            color: ${(props) => props.theme.grayishBlue};
        }

        @media only screen and (max-width: 1200px) {
            width: 100%;
        }

        .ant-input-affix-wrapper  {
            padding: 0 11px;
        }

        .ant-checkbox-wrapper,
        .label {
            color: ${(props) => props.theme.darkGrayishBlue};
        }
        
        .email , .pw {
            font-size: 14px;
            font-weight:400;
            line-height: 22px;
        }
        
        .pw-wrap {
            display: flex;
            gap: 15px
        }
            
        .fulname {
            display: flex;
            gap: 15px
        }
        
        .cstm-btn {
            margin-top: 10px;
            border-radius: 5px;
            height: 55px;
        }

        .submit {
            font-weight: 600;
            margin-top: 15px;
        }

        .google {
            border-color: ${(props) => props.theme.brand01};
            background-color: transparent;
            color: ${(props) => props.theme.brand01};
        }

        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 0px;
        }
      
        .no-acc-reg {
            margin-top: 11px;
            color: ${(props) => props.theme.dustyBlue};
            font-size: 14px;
            font-weight:400;
            line-height: 22px;
            text-align: center;
        }

        .login_link {
            display: inline-block;
            height: 16px;
            margin-left: 5px;
            color: ${(props) => props.theme.vividBlue};
            font-weight: 600;
            border-bottom: 1px solid  ${(props) => props.theme.vividBlue};
        }
    }
`