import styled from 'styled-components'
import { Form } from 'antd'

export const StyledProfileForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    min-height: 545px;
    background-color: ${(props) => props.theme.white};
    border-radius: 6px;
    margin-right: 200px;
    width: 60%;
    font-size: 16px;
    font-weight:400;

    .change-password-title {
        font-size: 24px;
        line-height: 32px;
    }

    .cstm-btn {
      margin-top: 10px;
      float: inline-end;
      width: 20em;
      border-radius: 45px !important;
      background: ${({ theme }) => theme.colorPrimary};
    }

    .ant-form-item {
        margin-bottom: 5px
    }

    .registered_addresses {
        display: none;
    }
    
    .inputs {
        display: flex;
        flex-direction: column;
        padding: 0px;
        gap: 10px;

        .collum {
            display: flex;
            gap: 15px;
            justify-content: flex-start;
        }
        .cstm-btn {
            border-radius: 5px;
            height: 55px;
            margin-bottom: 40px;
        }

        .title {
            font-weight: 600;
            font-size: 32px;
            margin-bottom: 20px;
        }
    }
    .half-collum {
      width: 100%;
    }

    @media (max-width: 800px) {
        margin-right: 0px;
        width: 100%;

        .inputs {
            .collum {
                display: block;
            }
        }
    }
}
`
