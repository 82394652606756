import styled from 'styled-components'

export const StyledProductsPage = styled('div')`
  .d-flex {
    display: flex;
  }

  .flex-rcol {
    display: flex;
    flex-direction: column;
  }

  .grey-price {
    color: ${(props) => props.theme.grey};
  }

  .admin {
    align-items: flex-end;
  }
`
