import React from 'react'
import CartWarning from '../CartWarning'
import { StyledCart, StyledCartWrapper } from './style'
import { FarmProduct } from 'public/context/CartContext'
import GroupedFarmCart from '../GroupedFarmCart/GroupedFarmCart'
import CartSummary from '../CartSummary/CartSummary'
import { useIntl } from 'react-intl'

interface CartProps {
  farmProducts: FarmProduct[]
  setCurrent: React.Dispatch<React.SetStateAction<number>>
  current: number
  isView?: boolean | undefined
}

const Cart = ({
  farmProducts,
  setCurrent,
  current,
  isView: isView,
}: CartProps) => {
  const intl = useIntl()
  return (
    <StyledCartWrapper>
      <CartWarning />
      <StyledCart isView={isView}>
        <GroupedFarmCart farmProducts={farmProducts} isView={isView} />
        <CartSummary
          farmProducts={farmProducts}
          setCurrent={setCurrent}
          current={current}
          isView={isView}
        />
      </StyledCart>
    </StyledCartWrapper>
  )
}

export default Cart
