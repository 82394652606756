import styled from 'styled-components';
import { Form } from 'antd';
export const StyledMobileFilterForm = styled(Form)`
  width: 100%;
  font-family: ${(props) => props.theme.fontText};

  .category-sel-wrap {
    display: flex;
    flex-direction: column;

    .form-title {
      display: none;
    }

    .ant-form-item {
      width: 100%;
      margin-bottom: 20px;
    }

    .ant-select-selector {
      background-color: ${(props) => props.theme.lightGrey};
      border: 1px solid ${(props) => props.theme.grey};
      min-width: 245px;
      min-height: 45px;
      border-radius: 5px;
    }
  }

  .products-navigate-list {
    display: flex;
    flex-direction: column;
    padding-bottom: 10px;

    a {
      display: block;
      padding: 10px 0;
      border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};
      font-size: 14px;
      line-height: 28px;
      font-weight: 500;
      color: ${(props) => props.theme.colorText};
    }
  }

  .ant-select {
    max-width: 100% !important;
    min-height: 54px !important;
  }

  .qmark {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    .ant-checkbox-wrapper-in-form-item {
      display: flex;
      align-items: center;
    }

    .ant-form-item {
      margin: 0;
      min-width: 100%;
    }

    @media only screen and (max-width: 786px) {
      grid-template-columns: 1fr;
    }
  }

  .qmark-filter-title {
    font-size: 14px;
    font-weight: 500;
    line-height: 28px;
    color: ${(props) => props.theme.colorText};
  }

  .ant-checkbox-wrapper-in-form-item span {
    font-size: 14px;
    font-weight: 400;
    color: ${(props) => props.theme.colorText};
    line-height: 16px;
  }

  :where(.ant-checkbox-checked).ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${(props) =>
      props.theme.activeCheckboxBackground} !important;
    border-color: ${(props) => props.theme.activeCheckboxBorder} !important;
  }
`;
