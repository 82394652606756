import React, { useEffect, useRef } from 'react'
import * as GeoMap from '@geo/novadugarsa'
import '@geo/novadugarsa/dist/novadugarsa.css'
import clusterLarge from '../../assets/cluster-large.svg'
import pin from '../../assets/pin.svg'
import zoomIn from '../../assets/zoom-in.svg'
import zoomOut from '../../assets/zoom-out.svg'
import clusterSmall from '../../assets/cluster-small.svg'
import { FarmCard } from '../Card/FarmCard'
import DOMPurify from 'dompurify'
import { flushSync } from 'react-dom'
import { createRoot } from 'react-dom/client'
import { IntlProvider, useIntl } from 'react-intl'
import { options, routes } from '../../config/config'
import { Card } from '../Card'
import { CartProvider } from '../../public/context/CartContext'

export interface MapProps {
  data: any[]
  dataType: 'farms' | 'products'
}

const MapComponent = ({ data, dataType }: MapProps) => {
  const intl = useIntl()
  const mapNodeRef: React.MutableRefObject<HTMLElement | undefined | null> =
    useRef<HTMLElement>()

  let identifiedObject: any = undefined

  const startPosition = { x: 2711797, y: 7748950, zoom: 7 }

  let popupHtml: any

  let zoomToFeature: any = null
  let padding = { left: 0, duration: 0 }

  let coordinates =
    data &&
    data.map((data) => {
      let parsedData = JSON.parse(data.farmdata)

      return {
        id: parsedData.id,
        coordinates: parsedData.coordinates,
      }
    })

  let featureData = coordinates ? coordinates : []

  useEffect(() => {
    mapNodeRef.current = document.getElementById('novadagarsa-map')
    renderMap()
  }, [])

  useEffect(() => {
    renderMap()
  }, [data])

  useEffect(() => {
    return () => {
      GeoMap.unmountMap()
    }
  }, [])

  const onChangePadding = (newPadding: any) => {
    padding = newPadding
    renderMap()
  }

  const onChangeData = (newData: any) => {
    featureData = newData
    renderMap()
  }

  const onSelectFeature = async (feature: any) => {
    identifiedObject = feature

    switch (dataType) {
      case 'farms':
        const farmData = await fetchFarmCredentials(feature.id_)
        popupHtml = createPopup(farmData)
        break
      case 'products':
        const prodData = await fetchProductsCredentials(feature.id_)
        popupHtml = createPopup(prodData)
        break
    }

    renderMap()
  }

  const onZoomToFeature = (feature: any) => {
    zoomToFeature = feature
    renderMap()
  }

  const onUnselectFeature = (objectId: any) => {
    identifiedObject = null
    popupHtml = null
    renderMap()
  }

  const fetchFarmCredentials = async (farmId: number) => {
    try {
      const response = await fetch(
        `${routes.api.baseUrl}/api/v2/farms/${farmId}`
      )
      if (!response.ok) {
        throw new Error('Network response was not ok')
      }
      const farmData = await response.json()
      return farmData
    } catch (error) {
      console.error('Failed to fetch farm credentials:', error)
      return null
    }
  }

  const fetchProductsCredentials = async (productsId: number) => {
    try {
      const response = await fetch(
        `${routes.api.baseUrl}/api/v2/products/${productsId}`
      )
      if (!response.ok) {
        throw new Error('cant fetch product')
      }
      const prodData = await response.json()
      return prodData
    } catch (e) {
      console.error('Fail to fetch')
      return null
    }
  }

  const createPopup = (farmData: any): TrustedHTML | null => {
    const div = document.createElement('div')
    const root = createRoot(div)
    flushSync(() => {
      switch (dataType) {
        case 'farms':
          root.render(
            <IntlProvider locale={intl.locale} messages={intl.messages}>
              <FarmCard farm={farmData} />
            </IntlProvider>
          )
          break
        case 'products':
          root.render(
            <IntlProvider locale={intl.locale} messages={intl.messages}>
              <CartProvider>
                <Card imageUrl={farmData.url_original} product={farmData} />
              </CartProvider>
            </IntlProvider>
          )
          break
      }
    })

    const sanitizedHtml = DOMPurify.sanitize(div.innerHTML)

    return sanitizedHtml as unknown as TrustedHTML
  }

  const renderMap = () => {
    GeoMap.setAssets({
      clusterLarge: clusterLarge,
      clusterSmall: clusterSmall,
      pin: pin,
      zoomIn: zoomIn,
      zoomOut: zoomOut,
    })

    GeoMap.renderMap(
      {
        startPosition,
        featureData,
        padding,
        zoomToFeature,
        onSelectFeature,
        onUnselectFeature,
        popupHtml,
      },
      mapNodeRef.current
    )
  }

  return <div id="novadagarsa-map" style={{ height: '100%' }}></div>
}

export default MapComponent
