import { StyledNewProductForm } from './style'
import { useIntl } from 'react-intl'
import { NewProductsGallery } from '../../UploadImage/products/NewProductsGallery'
import { UploadFile } from 'antd/es/upload/interface'
import {RequirementLogo} from "../../UploadImage/RequirementLogo";
import styled from "styled-components";

interface NewProductLogoProps {
    onValidate: (isValid: boolean) => void
    onFileListChange?: (fileList: UploadFile[]) => void
    fileList: any
}

const LogoTitle = styled.div`
    font-weight: 600;
`

export const NewProductLogo = ({onValidate, onFileListChange, fileList}: NewProductLogoProps) => {
    const intl = useIntl()

    return (
        <StyledNewProductForm>
            <LogoTitle>
                {intl.formatMessage({ id: 'general.logo_title' })}
            </LogoTitle>
            <div className={'requirements'}>
                <RequirementLogo />
                <div className={'image-upload'}>
                    <NewProductsGallery
                        onValidate={onValidate}
                        onFileListChange={onFileListChange}
                        fileList={fileList}
                        multiple={false}
                    />
                </div>
            </div>
        </StyledNewProductForm>
    )
}