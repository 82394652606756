import { StyledProductsTopFilter } from './style'
import { useIntl } from 'react-intl'
import { OrderBySelector } from '../../Select/Selectors/OrderBySelector'
import { PageSizeSelector } from '../../Select/Selectors/PageSizeSelector'
import { useEffect } from 'react'

export interface TopFilterProps {
  total?: number
  per_page?: number
  setFilter?: (fieldsValue: any) => void
  page_size_options?: number[]
  setPageSize: (value: number) => void
  unit?: 'products' | 'farms'
  filters?: {
    page_size?: number
    order_by?: string
  }
  handleShowMap?: () => void
}

export const ProductsTopFilter = ({
  total,
  setFilter,
  page_size_options,
  setPageSize,
  unit,
  filters,
  per_page,
  handleShowMap,
}: TopFilterProps) => {
  const intl = useIntl()
  const [form] = StyledProductsTopFilter.useForm()

  useEffect(() => {
    if (!!filters) {
      form.setFieldsValue(filters)
    }
  }, [filters, form])

  return (
    <StyledProductsTopFilter
      form={form}
      onFieldsChange={() => setFilter && setFilter(form.getFieldsValue())}
    >
      <div className={'catalogue-num br-r'}>
        {`${intl.formatMessage({
          id: 'top_filter.catalogue',
        })}: ${total} ${intl.formatMessage({ id: `top_filter.${unit}` })}`}
      </div>
      <div className={'filter-by br-r d-f-r'}>
        <OrderBySelector value='newest' allowClear={false} />
      </div>
      <div className={'per-page br-r d-f-r'}>
        <div className={'text'}>
          <PageSizeSelector
            page_size_options={page_size_options ?? [20, 40]}
            setPageSize={setPageSize}
            value={per_page}
          />
        </div>
      </div>

      <div className={'show-in-map'} onClick={handleShowMap}>
        {intl.formatMessage({ id: 'top_filter.show_in_map' })}
      </div>
    </StyledProductsTopFilter>
  )
}
