import { Select } from "antd";
import styled from "styled-components";

export const AntdSelect = styled(Select)`
  &.selected .ant-select-selector {
    background-color:  ${(props) => props.theme.softShadowGray} !important;
  }

  &.selected .ant-select-arrow {
    color:  ${(props) => props.theme.stepArrowColor};
  }

`