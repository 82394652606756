import DefaultLayout from '../../components/DefaultLayout'
import { useIntl } from 'react-intl'
import { StyledCartPage } from './style'
import { useCartState } from 'public/context/CartContext'
import { useEffect, useState } from 'react'
import { Icon, Steps } from 'ui'
import Cart from 'public/components/DefaultLayout/Cart'
import ProcessPurchase from 'public/components/DefaultLayout/ProcessPurchase'
import { useNavigate, useSearchParams } from 'react-router-dom'

export const CartPage = () => {
  const intl = useIntl()
  const { farmProducts } = useCartState()
  const [current, setCurrent] = useState<number>(0)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  useEffect(() => {
    const tab = searchParams.get('tab')
    if (tab) {
      setCurrent(Number(tab))
    }
  }, [searchParams])

  const steps = (
    <Steps
      type="navigation"
      current={current}
      items={[
        {
          title: intl.formatMessage({ id: 'general.basked' }),
        },
        {
          title: intl.formatMessage({ id: 'general.process_your_purchase' }),
        },
        {
          title: intl.formatMessage({ id: 'general.order_receipt' }),
        },
      ]}
    />
  )

  return (
    <DefaultLayout.PageLayout>
      <DefaultLayout.PageContent>
        <StyledCartPage>
          <div className="container">
            <div
              className="back-to-catalog"
              onClick={() => navigate('/products/all')}
            >
              <Icon name={'arrow-left'} />
              <span>
                {intl.formatMessage({ id: 'general.back_to_catalog' })}
              </span>
            </div>

            <div className={'cart-page-title'}>
              <h1>{intl.formatMessage({ id: 'cart.your_cart_title' })}</h1>
            </div>

            <div className={'cart-page-title mobile'}>
              <h1>{intl.formatMessage({ id: 'general.basked' })}</h1>
            </div>
            {steps}
            {current == 0 && (
              <Cart
                farmProducts={farmProducts}
                setCurrent={setCurrent}
                current={current}
                isView={false}
              />
            )}
            {current == 1 && (
              <ProcessPurchase
                setCurrent={setCurrent}
                farmProducts={farmProducts}
                current={current}
                isView={false}
              />
            )}
            {current == 2 && (
              <Cart
                farmProducts={farmProducts}
                setCurrent={setCurrent}
                current={current}
                isView={true}
              />
            )}
          </div>
        </StyledCartPage>
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}
