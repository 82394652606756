import { styled } from 'styled-components'

export const StyledGroupedFarmCart = styled.div`
  width: 100%;
  .cart-products-farm-title {
    display: none;
  }

  .cart-products-farm-wrapper {
    margin-top: 20px;
    .cart-products-header {
      display: grid;
      grid-template-columns: 2.6fr 1fr 1fr 0.5fr;
      font-size: 14px;
      font-weight: 300;
      font-family: ${(props) => props.theme.fontText};
      text-transform: uppercase;
      color: ${(props) => props.theme.grey};
      padding: 10px 20px 8px 20px;

      .cart-cell-4 {
        text-align: center;
      }
    }

    .cart-products-content-wrapper {
      border: solid 1px ${(props) => props.theme.mediumGrey};
      border-radius: 5px;

      .title {
        display: none;
      }
    }

    .cart-delivery-container {
      height: fit-content;
      background-color: ${(props) => props.theme.summaryBackground};
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      border-radius: 0px 0px 5px 5px;
      padding: 15px 20px 15px 20px;

      .cart-delivery-info-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .cart-delivery-info {
          width: fit-content;
          height: fit-content;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: -0.5px;
        }
      }

      .cart-delivery-button {
        .ant-btn {
          width: 350px;
          height: 52px;
          font-family: ${(props) => props.theme.fontText};
        }
      }

      .cart-delivery-button.choosen {
        height: 52px;
        border: solid 1px ${(props) => props.theme.brand02};
        background: transparent;
        color: ${(props) => props.theme.black};
        border-radius: 45px;
      }
    }
  }
  @media only screen and (max-width: 576px) {
    .cart-products-farm-title {
      margin-top: 20px;
      display: flex;
      font-weight: 600;
      font-size: 16px;
    }

    .cart-products-farm-wrapper {
      background-color: ${(props) => props.theme.cloudGray};
      border: 1px solid ${(props) => props.theme.silverGray};
      margin-top: 5px;
      border-radius: 5px;

      .cart-products-header {
        display: none;
      }

      .cart-products-content-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        border: 0;

        .title {
          display: none;
          margin: 6px 0;
          font-size: 16px;
          font-weight: 600;
        }
      }

      .cart-delivery-container {
        margin-top: -20px;
        .cart-delivery-button {
          width: 100%;
          .ant-btn {
            height: 34px;
            width: 100%;
          }
        }
        .cart-delivery-button.choosen {
          width: 35%;
          align-self: flex-start;
          border: none;
          span {
            color: ${(props) => props.theme.darkRed};
            font-size: 15px;
            font-weight: 300;
            text-decoration: underline;
          }
          img {
            display: none;
          }
        }
      }
    }
  }
`
