import { useIntl } from 'react-intl'
import { StyledTermsOfUseWrapper } from './style'
import { useNavigate } from 'react-router-dom'

export const TermsOfUse = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <StyledTermsOfUseWrapper>
      <div className={'container'}>
        <div className="page-inner">
          <h3 className="title">VISPĀRĪGIE LIETOŠANAS NOTEIKUMI</h3>
          <div className="contnet">
            <h4 className="sub-title">
              Interneta vietnes www.novadagarsa.lv vispārīgie lietošanas
              noteikumi
            </h4>

            <span>
              1. Pirms sākt lietot SIA „Latvijas Lauku konsultāciju un
              izglītības centrs”, reģistrācijas Nr. 40003347699, adrese – Rīgas
              ielā 34, Ozolnieki, Ozolnieku pagasts, Ozolnieku novads, LV-3018,
              Latvija (turpmāk – LLKC), interneta vietni www.novadagarsa.lv
              (turpmāk tekstā – vietni), lūdzam rūpīgi izlasīt šos interneta
              vietnes www.novadagarsa.lv lietošanas noteikumus (turpmāk –
              noteikumi).
            </span>

            <span>
              2. Ar interneta vietni www.novadagarsa.lv tiek saprasta LLKC
              izveidotā interneta vietne, kuras interneta adrese ir
              www.novadagarsa.lv, ar visu tajā esošo informāciju un materiāliem.
            </span>
            <span>
              3. LLKC ir tiesības jebkurā brīdī vienpusēji mainīt interneta
              vietnes saturu un lietošanas noteikumus. Šādas izmaiņas stājas
              spēkā pēc to publicēšanas interneta vietnē.
            </span>
            <span>
              4. Apmeklējot interneta vietni vai izmantojot tajā izvietoto
              informāciju, Jūs personiski vai Jūsu pārstāvētā persona, ja
              darbojaties tās vārdā, piekrīt šiem noteikumiem. Katram interneta
              vietnes lietotājam ir pienākums regulāri pārlasīt noteikumus, lai
              savlaicīgi būtu informēts par tajos veiktajām izmaiņām. Ja
              nepiekrītat noteikumiem, tad šo interneta vietni lietot aizliegts.
            </span>
            <span>
              5. Informējam, ka LLKC interneta vietnē ievadītās informācijas
              (fizisko vai juridisko personu datu) apstrādes mērķis ir vietnē
              norādīto pakalpojumu sniegšana un palīdzības sniegšana interneta
              vietnes lietošanas laikā.
            </span>
            <span>
              6. Interneta vietnes lietotājs piekrīt saņemt informāciju no
              interneta vietnes par dažāda veida aktualitātēm, ja ir saņemta
              lietotāja kā datu subjekta brīva un nepārprotama piekrišana
              (izņemot gadījumus, kad ziņu nosūtīšana lietotājam tiek veikta uz
              cita tiesiskā pamata).
            </span>
            <span>
              7. LLKC neatbild par jebkāda veida izdevumiem un zaudējumiem, kas
              radušies interneta vietnes lietošanas laikā.
            </span>
            <span>
              8. Gadījumos, ja ir neskaidrības par LLKC interneta vietnē
              ievietoto dokumentu formu aizpildīšanu, lūgums sazināties ar
              norādīto interneta vietnes www.novadagarsa.lv kontaktpersonu.
              <span>
                {' '}
                II. Interneta vietnē www.novadagarsa.lv piedāvāto pakalpojumu
                izmantošana
              </span>
            </span>
            <span>
              9. Jebkuram interneta vietnes www.novadagarsa.lv lietotājam
              vienmēr ir jārīkojas saskaņā ar interneta vietnē norādīto kārtību.
            </span>
            <span>
              10. Aizliegts interneta vietnē ievietoto informāciju izmantot
              ļaunprātīgi. Interneta vietnē piedāvātos pakalpojumus drīkst
              izmantot tikai saskaņā ar spēkā esošajiem normatīvajiem aktiem.
              LLKC ir tiesība apturēt vai pārtraukt pakalpojumu sniegšanu, ja
              interneta vietnes lietošanas laikā netiek nodrošināta atbilstība
              interneta vietnes www.novadagarsa.lv lietošanas noteikumiem.
            </span>
            <span>
              11. Lūdzam ievērot, ka pieprasīt LLKC vai tās vārdā pārstāvētai
              personai veikt izmaiņas reģistrētajā profilā, ir iespējams,
              rakstot e-pastu uz info@novadagarsa.lv no profilā norādītā e-pasta
              adreses vai, sazinoties ar LLKC, zvanot uz www.novadagarsa.lv
              tālruņiem: +371 26327041 vai +37163050220, darba dienās, darba
              laikā no 8:30 līdz 17:00.
            </span>
            <span>
              12. Lai varētu nodrošināt kataloga kopējo kvalitātes līmeni, LLKC,
              konstatējot interneta vietnē kļūdas un/vai neprecizitātes profilos
              ievadītajā informācijā, ir tiesības vienpusēji atcelt profila
              publiskošanu un pieprasīt profila informācijā labot drukas kļūdas,
              izdzēst nekvalitatīvu foto attēlu.
            </span>
            <span>
              13. Interneta vietnes lietošana nepiešķir nekādas īpašuma tiesības
              uz saturu, kam ir tiesības piekļūt reģistrētiem un nereģistrētiem
              interneta vietnes lietotājiem. LLKC patur vienpusējas īpašuma
              tiesības uz saturu, kas atrodas interneta vietnē
              www.novadagarsa.lv. Aizliegts izmantot interneta vietnē ievietotās
              informācijas saturu, nevienu interneta vietnē iekļautu zīmolu,
              logotipu, kartes saturu, saimniecību profilu informāciju, ja vien
              nav iegūta šī satura īpašnieka personīga atļauja vai cita veida
              atļauja izmantot publicēto informāciju. Aizliegts noņemt, padarīt
              nesaprotamus vai mainīt paziņojumus, kas redzami interneta vietnē
              vai tiek sniegti ar interneta vietnes starpniecību.
            </span>
            <span>
              14. Kataloga profilā attēloto QR kodu atļauts izmantot tikai
              konkrētā profila īpašniekam.
            </span>
            <span>
              15. Interneta vietnē tiek publicēti saimniecību, uzņēmumu apraksti
              un cita informācija, kas ir konkrētā uzņēmuma un vai personas
              īpašums.
            </span>
            <span>
              16. Ikvienam reģistrētajam lietotājam ir jāievēro, ka attiecīgā
              lietotāja profilā norādītajiem datiem (vārdam, uzvārdam u. c.
              informācijai) ir jābūt patiesiem. Reģistrēta lietotāja profilā
              drīkst izvietot tikai un vienīgi paša reģistrētā lietotāja datus.
              Vietnē ir aizliegta t. s. “viltus” profilu veidošana, t. i., tādu
              profilu veidošana, kuros vietnes lietotājs ietver datus nevis par
              sevi, bet gan par citu (t. sk. arī nereālu) personu vai uzņēmumu
              utt.
            </span>
            <span>
              17. Ja lietotājs profilā ievietoto savu fotogrāfiju, tad tajā ir
              jābūt atpazīstamam reģistrētajam lietotājam un skaidri redzamai
              viņa sejai.
            </span>
            <span>
              18. Katrs vietnes lietotājs ir pilnībā atbildīgs par jebkuru
              informāciju, kuru viņš ievieto vai nosūta citiem vietnes
              lietotājiem (tajā skaitā ne tikai sava profila datus,
              fotogrāfijas, e-pasta sūtījumus, video materiālus, skaņu
              ierakstus), kā arī uzņemas pilnu atbildību par sekām, kuras rodas
              šādas informācijas ievietošanas vai nosūtīšanas rezultātā.
              Lietotājam ir tiesības ievietot, augšupielādēt utt. vietnē tikai
              tādu saturu, uz kuru lietotājam ir pilnīgas un neierobežotas
              tiesības.
            </span>
            <span>
              19. Vietnē ir aizliegts ievietot vai nosūtīt citiem tās
              lietotājiem informāciju (tajā skaitā arī foto, video un audio
              materiālus), kas:
            </span>
            <span>
              19.1. aizskar vai pārkāpj trešo personu intelektuālā īpašuma
              (autortiesības u. c.) tiesības;
            </span>
            <span>19.2. aizskar personas godu un cieņu;</span>
            <span>
              19.3. aicina uz vardarbību, rasu naidu vai citu prettiesisku
              darbību veikšanu;
            </span>
            <span>
              19.4. informācija ir vulgāra, ķengājoša vai citādā veidā
              aizskaroša;
            </span>
            <span>
              19.5. satur datorvīrusus vai izveidota, lai kaitētu datora vai
              elektronisko sakaru programmatūras darbībai (drošībai);
            </span>
            <span>
              19.6. ir surogātpasts (junk mail, spam, chain letter) vai
              nesaskaņota reklāma;
            </span>
            <span>19.7. ir pornogrāfiska vai pārlieku erotiska;</span>
            <span>
              19.8. visu cita veida informāciju, kas ietekmē vai var ietekmēt
              vietnes normālu darbību un drošību.
            </span>
            <span>
              20. LLKC neatbild par vietnē ievietoto vai vietnes lietotāju
              savstarpēji nosūtīto informāciju un sekām, kuras ir radušās vai
              varētu rasties šīs informācijas ievietošanas vai nosūtīšanas
              rezultātā.
            </span>
            <span>
              21. Tāpat LLKC neatbild par vietnes pieejamību, vietnes lietotāju
              savstarpējo komunikāciju, jebkādām tehniska rakstura problēmām
              vietnes darbībā, lietotāja profila datu izmaiņām un darbībām
              profilā citas personas autorizētas vai neautorizētas piekļūšanas
              rezultātā. Visa vietnes darbība (t. sk. tajā pieejamie
              pakalpojumi) tiek piedāvāta tāda, kāda tā ir bez nekādām papildu
              garantijām no LLKC. Turklāt LLKC nekādā veidā neatbild par jebkāda
              veida zaudējumiem, kas vietnes lietotājiem ir radušies vai varētu
              rasties saistībā ar vietnes un tajā pieejamo pakalpojumu
              izmantošanu.
            </span>
            <span>
              III. Interneta vietnē www.novadagarsa.lv ievadīto datu izmantošana
              un konfidencialitāte.
            </span>
            <span>
              22. LLKC informē, ka, norādot savu e-pasta adresi, piekrītat, ka
              tā tiek iekļauta datu bāzē un uz to var tikt nosūtīts no interneta
              vietnes paziņojums/atgādinājums par uzsākto, nepabeigto profila
              noformēšanu un citu darbību veikšanu attiecīgajā profilā.
            </span>
            <span>
              23. Lietojot interneta vietni, Jūs piekrītat, ka LLKC vai jebkura
              trešā persona, kas darbojas LLKC uzdevumā, var apkopot un uzglabāt
              datus informatīvos un statistikas nolūkos, kas ļauj izsekot un
              uzskaitīt:
            </span>
            <span>23.1. tīmekļa vietņu kopējo apmeklējumu skaitu,</span>
            <span>
              23.2. katras konkrētās tīmekļa vietnes apmeklētāju skaitu,
            </span>
            <span>
              23.3. apmeklētāju interneta pakalpojumu sniedzēju domēna vārdus,
            </span>
            <span>23.4. IP adreses,</span>
            <span>
              23.5. citus datus, kuru izmantošanas mērķis tiek veikts sistēmu
              administrēšanas nolūkos, kā arī, lai kontrolētu interneta vietnes
              izmantošanu un rūpētos par tās uzlabošanu.
            </span>
            <span>
              24. Interneta vietnē apkopotos un uzkrātos datus informatīvos un
              statistikas apkopošanas nolūkos LLKC ir tiesīgs nodot arī citiem
              uzņēmumiem. Dati, kas ļautu identificēt personu, šajā procesā
              netiek atklāti un nodoti trešajām personām.
            </span>
            <span>
              25. LLKC nesaista lietotāja IP adresi un e-pasta adresi ar datiem,
              kas ļauj identificēt šo lietotāju. Tas nozīmē, ka katra lietotāja
              sesija tiks reģistrēta, taču interneta vietnes lietotājs paliks
              anonīms.
            </span>
            <span>
              26. Visi materiāli, kas tiek nosūtīti vai ievadīti interneta
              vietnē, kļūst par LLKC īpašumu, kurus LLKC kā interneta vietnes
              īpašnieks ir tiesīgs izmantot saviem nolūkiem, izņemot fizisko un
              juridisko personu datus.
            </span>
            <span>
              27. Lūdzu, ievērojiet, ka, uzrādot savu e-pasta adresi vai nosūtot
              jebkādu informāciju uz interneta vietni, esat piekritis, ka LLKC
              šos datus ir tiesīgs izmantot tikai iepriekš minētajos nolūkos,
              tajā skaitā šo noteikumu 16. punktā norādītajam mērķim.
            </span>
            <span>
              IV. Interneta vietnes www.novadagarsa.lv profila izveide un
              autortiesību aizsardzība.
            </span>
            <span>
              28. Reģistrācijai elektroniskajā katalogā ir jāaizpilda
              Pamatinformācijas anketa, atbilstoši prasībām, jāapliecina, ka
              esat iepazinies un piekrītat interneta vietnes Privātuma politikai
              un LLKC interneta vietnes www.novadagarsa.lv Vispārīgiem
              lietošanas noteikumiem.
            </span>
            <span>
              29. Uzsākot reģistrāciju, jāaizpilda pamatinformācijas anketa,
              kuru tuvāko 3 (trīs) darba dienu laikā izskata interneta vietnes
              administrācija un e-pasta vēstulē sniedz atbildi par uzņēmuma
              atbilstību/neatbilstību vai arī sazinās ar norādīto
              kontaktpersonu, ja rodas kādi jautājumi vai precizējumi.
            </span>
            <span>
              30. Katram reģistrētajam vietnes lietotājam ir pienākums neizpaust
              citām personām savus vietnes piekļuves datus. Ja vietnē tiek
              veiktas darbības ar reģistrēta lietotāja profilu, izmantojot
              pareizu lietotājvārdu un paroli, tad tiek uzskatīts, ka darbības
              attiecīgajā profilā ir veicis pats reģistrētais lietotājs.
            </span>
            <span>
              31. Profils tiks publiskots tikai ar uzņēmuma, īpašnieka datu
              apstrādes atļauju, kas nosūtīta e-pasta vēstulē.
            </span>
            <span>
              32. Interneta vietnes lietotājiem un apmeklētājiem atļauts
              izmantot šīs interneta vietnes informāciju nekomerciāliem mērķiem,
              iepriekš saskaņojot to ar www.novadagarsa.lv administratoriem.
            </span>
            <span>
              33. Jebkura informācija, tai skaitā, grafiskā, vizuālie attēli
              u.c. informācija, var tikt izmantota tikai ar LLKC un pašu
              ražotāju/uzņēmēju piekrišanu.
            </span>
            <span>
              34. Jebkura veida informācijas pārpublicēšana ir jāveic
              atsaucoties uz LLKC vietni www.novadagarsa.lv, kā arī attiecīgi
              pārpublicējumam ir jāpievieno atgriezeniska, skaidri saskatāma un
              darboties spējīga saite uz oriģinālavotu (www.novadagarasa.lv).
            </span>
            <span>
              35. Interneta vietnē ievietotās informācijas pārpublicēšana,
              kopēšana, pārveidošana vai cita veida izmantošana bez
              www.novadagarsa.lv rakstiskas atļaujas ir aizliegta.
            </span>
            <span>
              36. Interneta vietne www.novadagarsa.lv neuzņemas atbildību par
              lietotāju norādītās informācijas precizitāti, kas publicēta
              lietotāju profilos.
            </span>
            <span>
              37. Nosūtot informāciju, izveidojot interneta vietnē
              www.novadagarsa.lv profilu, Jūs nododat autortiesības
              www.novadagarsa.lv, kas patur tiesības iesūtīto informāciju
              izmantot un publicēt, pievienojot tai autora vārdu, ja tāds ir
              uzrādīts. Informācijas iesūtītājiem nav tiesību pieprasīt
              atlīdzību par norādītās informācijas izmantošanu.
            </span>
            <span>Atteikuma tiesības</span>
            <span>
              38. Pēc reģistrēšanās katalogā un pamatinformācijas anketas
              aizpildīšanas informāciju, pieteikums tiek saņemts kataloga
              administrācijā, kura katru pieteikuma rūpīgi izskata un pārbauda
              datu patiesumu un atbilstību kataloga profila prasībām.
            </span>
            <span>
              39. Kataloga administrācijai ir tiesības atteikt aizpildīt vai
              pieprasīt dzēst visu informācijas ievadi kataloga profilā, ja
              norādītā informācija neatbilst kataloga prasībām (piemēram, ja
              uzņēmējs kā juridiska persona, pašnodarbināta persona vai
              saimniecība nav reģistrēta PVD datu bāzē ar atbilstoši piešķirtu
              numuru; ja nav reģistrēta saimnieciskā darbība VID datu bāzē; ja
              tiek konstatēts, ka norādītajai kontaktpersonai nav nekādu
              saistību ar norādīto uzņēmumu vai saimniecību).
            </span>
            <span>
              40. Neskaidrību, jautājumu, precizējumu gadījumā kataloga
              administrācija sazināsies personiski ar pieteikumā norādīto
              kontaktpersonu.
            </span>
            <span>
              Par interneta vietnes www.novadagarsa.lv lietošanas noteikumiem
            </span>
            <span>
              41. Gadījumā, ja kāds no šajos noteikumos norādītajiem punktiem
              zaudēs spēku, tas neietekmēs pārējos noteikumus.
            </span>
            <span>
              42. Jebkuri strīdi, kas izriet no šiem noteikumiem vai
              pakalpojumiem, vai ir saistīti ar tiem, tiks izskatīti saskaņā ar
              normatīvajos aktos noteiktajām prasībām.
            </span>
            <span>
              43. Visas intelektuālā īpašuma tiesības attiecībā uz interneta
              vietni www.novadagarsa.lv pieder tikai un vienīgi LLKC. Šo tiesību
              pārkāpuma gadījumā vainīgā persona tiek saukta pie normatīvajos
              aktos noteiktās atbildības, kā arī ir pilnībā atbildīga par visiem
              zaudējumiem, kas ir vai varētu tikt nodarīti LLKC un trešajām
              personām.
            </span>
            <span>
              44. Informāciju par to, kā sazināties ar LLKC, skatiet interneta
              vietnes www.novadagarsa.lv sadaļā „Kontakti”.
            </span>
          </div>
        </div>
      </div>
    </StyledTermsOfUseWrapper>
  )
}
