import styled from 'styled-components'
import { Form } from 'antd'
export const StyledForm = styled(Form)`
  min-width: 245px;
  max-width: 245px;
  padding-top: 51px;
  padding-right: 40px;
  margin-bottom: 0;
  font-family: ${(props) => props.theme.fontText};

  .qmark-filter-title {
    margin-bottom: 6px;
  }

  .category-sel-wrap {
    display: flex;
    flex-direction: column;

    .ant-form-item {
      margin-bottom: 20px;
    }
  }

  .close-btn-wrapper {
    position: relative;
    .close-btn {
      cursor: pointer;
      position: absolute;
      right: -110px;
      top: -25px;
    }
  }

  .form-title {
    padding: 8px 0;
    font-weight: 600;
    font-size: 16px;
  }

  .ant-select-selector {
    background-color: ${(props) => props.theme.lightGrey};
    min-width: 245px;
    min-height: 45px;
    border-radius: 5px;
    border: 1px solid ${(props) => props.theme.grey};
  }

  .qmark {
    flex-direction: column;
    gap: 5px;
  }

  :where(.ant-select).ant-select .ant-select-arrow {
    color: ${(props) => props.theme.stepArrowColor};
  }
`
