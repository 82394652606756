import { useEffect, useState } from 'react'
import { StyledProcessPurchaseForm } from './style'
import { Form, FormInstance } from 'antd'
import { Checkbox, CustomGrid, Input, TextArea } from 'ui'
import { useIntl } from 'react-intl'
import useQueryApiClient from 'utils/useQueryApiClient'
import { StringifyOptions } from 'querystring'
import { useAuth } from 'hooks/useAuth'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import useWindowSize from 'utils/useWindowSize'

export interface PersonalInfo {
  isLegalPerson: boolean
  name?: string
  client_surname?: string
  company_name?: StringifyOptions
  registration_number?: string
  PVN_registration_number?: string
  country?: string
  city: string
  address: string
  post_index: string
  e_mail: StringifyOptions
  phone_number: StringifyOptions
  comment?: StringifyOptions
  checked?: boolean
}

interface ProcessPurchaseFormProps {
  userForm: FormInstance
}

const SESSION_STORAGE_KEY = 'processPurchaseFormValues'

const ProcessPurchaseForm = ({ userForm }: ProcessPurchaseFormProps) => {
  const intl = useIntl()
  const [_, windowWidth] = useWindowSize()
  const [isLegalPerson, setIsLegalPerson] = useState(false)
  const { user } = useAuth()

  const { refetch } = useQueryApiClient({
    request: {
      url: '/api/v2/orders/lastOrderByUser',
      method: 'GET',
      disableOnMount: true,
    },
    onSuccess: (response: PersonalInfo) => {
      userForm.setFieldsValue(response)
      sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(response))
    },
  })

  useEffect(() => {
    if (user?.authenticated || !sessionStorage.getItem(SESSION_STORAGE_KEY))
      refetch()
  }, [])

  const handleFormChange = (changedValues: any, allValues: PersonalInfo) => {
    sessionStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(allValues))
  }

  const isLegalPersonChange = (isLegalPerson: boolean) => {
    setIsLegalPerson(isLegalPerson)
  }

  const inOneRow = windowWidth > 576 ? 2 : 1

  return (
    <StyledProcessPurchaseForm>
      <Form form={userForm} layout="vertical" onValuesChange={handleFormChange}>
        <Checkbox
          onChange={(e: CheckboxChangeEvent) =>
            isLegalPersonChange(e.target.checked)
          }
          itemClassName="purchase-form-checkbox-item"
          name="checked"
          value={'process_purchase.buy_as_legal_person'}
          checked={isLegalPerson}
          label={intl.formatMessage({
            id: 'process_purchase.buy_as_legal_person',
          })}
        />
        {!isLegalPerson && (
          <CustomGrid inOneRow={inOneRow}>
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.name' })}
              name="name"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.client_surname',
              })}
              name="client_surname"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.country' })}
              name="country"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.city' })}
              name="city"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.address' })}
              name="address"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.post_index',
              })}
              name="post_index"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.e_mail' })}
              name="e_mail"
              validations={'email'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.phone_number',
              })}
              name="phone_number"
              validations={'phoneNumber'}
            />
          </CustomGrid>
        )}

        {isLegalPerson && (
          <CustomGrid inOneRow={inOneRow}>
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.company_name',
              })}
              name="company_name"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.registration_number',
              })}
              name="registration_number"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.PVN_registration_number',
              })}
              name="PVN_registration_number"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.city' })}
              name="city"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.address' })}
              name="address"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.post_index',
              })}
              name="post_index"
              validations={'required'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({ id: 'process_purchase.e_mail' })}
              name="e_mail"
              validations={'email'}
            />
            <Input
              className="purchase-form-item"
              label={intl.formatMessage({
                id: 'process_purchase.phone_number',
              })}
              name="phone_number"
              validations={'phoneNumber'}
            />
          </CustomGrid>
        )}

        <CustomGrid inOneRow={1}>
          <div className={'purchase-form-title'}>
            {intl.formatMessage({ id: 'process_purchase.comment' })}
          </div>
          <TextArea name={'comment'} />
        </CustomGrid>
      </Form>
    </StyledProcessPurchaseForm>
  )
}

export default ProcessPurchaseForm
