import styled, { css } from 'styled-components';

const sharedStyles = css`
  margin: 0;
  display: flex;
  flex-direction: column;

  .txt-icon {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .icon {
    min-width: 2px;
    padding: 0;
    display: flex;
    align-items: center;

    .ant-select-dropdown-hidden {
      display: none !important;
    }

    .opt-wrapp {
      position: absolute;
    }

    .ant-form-item {
      margin-bottom: 0;
      padding-bottom: 8px;

      .ant-select-clear {
        padding-left: 18px;
        padding-top: 3px;
      }

      .ant-select-selection-item {
        padding-right: 4px;
      }
    }
  }
`;

export const StyledOrderBySelector = styled('div')`
  ${sharedStyles}

  .ant-select-dropdown {
    display: block !important;
  }

  .ant-select-selection-placeholder {
    display: none !important;
  }

  .ant-select-item {
    display: block;
    min-width: 142px;
  }

  .ant-form-item {
    padding-bottom: 0px !important;

    .ant-form-item-control-input {
      min-height: 24px !important;
    }

    .ant-select-lg {
      height: 24px !important;
    }

    .ant-select-arrow {
      right: -2px;
      margin-top: -6px !important;
      margin-left: 4px;

      svg {
        fill: ${(props) => props.theme.stepArrowColor};
      }
    }
  }
`;

export const StyledPageSizeSelector = styled('div')`
  ${sharedStyles}

  .ant-form-item {
    padding-bottom: 0px !important;

    .ant-select-selector .ant-select-selection-item {
      line-height: 24px !important;
      height: 24px;
    }

    .ant-form-item-control-input {
      min-height: 24px !important;
    }

    .ant-select-item {
      display: block;
      min-width: 142px;
    }

    .ant-select-lg {
      height: 24px !important;
    }

    .ant-select-arrow {
      display: inline-block;
      right: -2px;
      margin-top: -7px !important;
      padding-left: 4px;

      svg {
        fill: ${(props) => props.theme.stepArrowColor};
      }
    }
  }
`;

export const StyledTopCategorySelector = styled('div')`
  ${sharedStyles};

  border: 1px solid ${(props) => props.theme};
  padding: 15px;

  .ant-tooltip {
    display: none;
  }
  .btn {
    max-width: 200px;
    display: flex;
    font-size: 14px;
  }

  .category {
    display: flex;
    flex-direction: column;

    .ant-upload-list-item-container {
      max-width: 200px;
      overflow: hidden;
    }

    .ant-form-item {
      min-width: 200px;
      max-width: 200px;

      .ant-select-dropdown {
        position: absolute;
        top: 20px;
      }
    }
  }
`;

export const StyledCategorySelector = styled('div')`
  ${sharedStyles};

  .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }

  :where(.ant-select).ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }
  .displayFlex {
    display: flex;
    gap: 10px;
  }
  .admin {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 10px;
  }
`;
export const StyledLocationSelector = styled('div')`
  .form-title {
    padding-top: 0;
  }

  .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }

  :where(.ant-select).ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }
`;

export const StyledFarmsSelector = styled('div')`
  .form-title {
    padding-top: 0;
  }

  .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }

  :where(.ant-select).ant-select-outlined:not(.ant-select-disabled):not(
      .ant-select-customize-input
    ):not(.ant-pagination-size-changer):hover
    .ant-select-selector {
    border-color: ${(props) => props.theme.borderBottom};
  }
`;
