import styled from "styled-components";

export const TestPageWrapper = styled.div`
  .popular-products {
    padding: 32px 0 60px 0;
  }

  .popular-products h1,
  .popular-categories h1 {
    font-family: 'Albert Sans';
    font-size: 42px;
    font-weight: 500;
    line-height: 38px;
  }

  .popular-categories h1 {
    margin-top: 100px;
    margin-bottom: 35px;
  }

  .banner-1 {
    background-color: ${(props) => props.theme.white};
  }

  .banner-2 {
    background-color: ${(props) => props.theme.containerBgColor};
  }

  .grid-view {
    padding: 0 !important;
    column-gap: 33px;
    row-gap: 31px;
  }

  .banner-image-wrapper {
    width: 100%;
    padding: 50px 20px;
    margin: 0 auto;
  }

  .slick-slider {
    margin-bottom: 0;
  }

  .banner-1 .slick-dots.slick-dots-bottom,
  .banner-2 .slick-dots.slick-dots-bottom {
    display: none !important;
  }

  .product-slider-container {
    padding: 0;
    padding-bottom: 10px;
  }

  .discount-items {
    background-color: ${(props) => props.theme.containerBgColor};
  }

  .discount-items .ant-card {
    margin-left: 0;
    margin-right: 0;
  }

  .discount-items .slick-slide {
    justify-content: space-between;
  }

  .discount-items .slick-dots .slick-active {
    width: 64px !important;
  }

  @media only screen and (max-width: 1200px) {
    .banner-wrapper.banner_1 {
      display: none;
    }
  }

  @media only screen and (max-width: 440px) {
    .popular-products {
      padding: 0px 0 48px 0;

      .title h1 {
        margin-top: 0;
      }

      .grid-view {
        padding-left: 0 !important;
        padding-right: 0 !important;
        row-gap: 14px;
      }

      .ant-card {
        margin-left: 0;
        margin-right: 0;
      }

      .ant-card-body {
        gap: 5px;
      }

      .ant-image {
        height: 100%;
      }

      .ant-image-img {
        height: 100%;
        min-height: 136px;
      }

      .custom-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .price-quality-marks {
        margin-bottom: 0;
        gap: 5px;
      }

      .add-to-cart-container {
        padding-top: 5px;
      }

      .price-container-quality-marks-wraper {
        height: 19px;
      }

      .price-quality-marks .price-container-quality-marks-wraper .price-container .package-price,
      .price-grey {
        display: none !important;
      }
    }

    .product-slider-container,
    .discount-items {

      .ant-card {
        height: 100%;

        .ant-image-img {
          height: 100%;
          min-height: 136px;
        }

        .custom-title {
          font-size: 16px;
          font-weight: 500;
          line-height: 18px;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-card-body .price-quality-marks .farm-name-container-mobile {
          display: -webkit-box !important;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .ant-card-body {
          gap: 5px;
        }

        .price-quality-marks {
          gap: 5px;
        }

        .price-quality-marks {
          margin-bottom: 0;
        }

        .price-grey,
        .package-price {
          display: none !important;
        }

        .price-type-second {
          line-height: 19px !important;
        }

        .add-to-cart-container {
          padding-bottom: 7px;
        }

        .ant-card-body .add-to-cart-container .add-to-cart-icon .btn-add-to-cart {
          height: 30px;
        }

        .btn-add-to-cart .ant-btn {
          width: 30px !important;
          height: 30px !important;
        }
      }
    }
  }
`
