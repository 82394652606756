import { StyledBannerPreview } from './style'
import { BannerProps, ImageProps } from '../../../types/BannerData'
import { Preview } from './Preview'
import { Button, FormInstance, message, Upload } from 'antd'
import { UploadOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { RcFile, UploadFile } from 'antd/es/upload/interface'
import { beforeUpload } from '../../../utils/imageHelpers'
import { options } from '../../../config/config'
import { Requirements } from '../Requirements'
import { ImagePlaceholder } from './ImagePlaceholder'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { set } from 'js-cookie'

interface BannerImagesProps {
  images: ImageProps[]
  form: FormInstance
  type?: 'static' | 'slider'
  banner?: BannerProps
  imagesData?: any
  setRemovedImageIds?: any
  setImagesData?: any
  onClose?: any
}

interface OnChangeProps {
  file: UploadFile
  fileList: UploadFile[]
}

export const BannerImages = ({
  images,
  type,
  form,
  banner,
  imagesData,
  setImagesData,
  setRemovedImageIds,
}: BannerImagesProps) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const intl = useIntl()
  const [tempImage, setTempImage] = useState<string | null>(null)
  const [productsCount, setProductsCount] = useState<number>(0)

  useQueryApiClient({
    request: {
      url: '/api/v2/products/get/count',
    },
    onSuccess: (response) => setProductsCount(response),
  })

  const memoizedImages = useMemo(() => images, [images])

  const existingImages = images.map((img: ImageProps) => {
    return img.id
  })

  const handleBeforeUpload = useCallback(
    (file: RcFile) => beforeUpload(file, intl),
    [intl]
  )

  const TotalProducts = () => {
    return (
      <div className={'product-count-wrapper'}>
        <div className={'productCount'}>
          <span>
            {intl.formatMessage({ id: 'banners.total_product_count' })}
          </span>
          <h1>{productsCount}</h1>
        </div>
      </div>
    )
  }

  const onUploadChange = useCallback(
    ({ file, fileList: newFileList }: OnChangeProps) => {
      const maxFiles = options.images.maxImages

      if (type !== 'static' && newFileList.length > maxFiles) {
        message.error(intl.formatMessage({ id: 'validation.max_images' }))
        return
      }

      let updatedFileList = newFileList

      if (newFileList) {
        newFileList.map((file: any) => {
          if (existingImages.includes(file.id)) {
            setRemovedImageIds((prev: any) => [...prev, file.uid])
          }
        })
      }

      if (type === 'static') {
        updatedFileList = newFileList.slice(-1)
      }

      updatedFileList = updatedFileList.map((fl: any) => {
        if (!fl.url && !fl.preview) {
          fl.preview = URL.createObjectURL(fl.originFileObj)
        }
        return fl
      })

      setFileList(updatedFileList)

      if (type === 'static' && updatedFileList[0]?.preview) {
        setTempImage(updatedFileList[0].preview)
      }

      if (setImagesData) {
        setImagesData((prevImagesData: any[]) => {
          return updatedFileList.map((fl: any) => {
            const existingImage = prevImagesData?.find(
              (img: any) => img.uid === fl.uid
            )
            return {
              uid: fl.uid,
              id: existingImage ? existingImage.id : undefined,
              name: fl.name,
              status: fl.status,
              url: fl.url || '',
              thumbUrl: fl.thumbUrl || '',
              url_to: existingImage ? existingImage.url_to : '',
              originFileObj: fl.originFileObj || fl,
            }
          })
        })
      }
    },
    [type, intl, setRemovedImageIds, setImagesData]
  )

  useEffect(() => {
    form.setFieldsValue({ images: fileList })
  }, [fileList, form])

  useEffect(() => {
    if (type === 'static' && images && images.length > 0) {
      const initialFileList: any = images.map((image, index) => ({
        uid: image.id || index.toString(),
        id: image.id,
        name: image.image_name || `Image ${index + 1}`,
        status: 'done',
        url: image.url_original,
        thumbUrl: image.url_thumbnail,
        originFileObj: null,
      }))
      setFileList(initialFileList)

      if (setImagesData) {
        setImagesData(initialFileList)
      }
    }
  }, [type, images])

  return (
    <StyledBannerPreview>
      <TotalProducts />
      {type === 'static' ? (
        <div className={'image-prew'}>
          <Preview images={images} type={banner?.type} tempImage={tempImage} />
          <Upload
            name={'images'}
            beforeUpload={handleBeforeUpload}
            fileList={fileList}
            onChange={onUploadChange}
            multiple={false}
            showUploadList={false}
          >
            <Button icon={<UploadOutlined />}>
              {intl.formatMessage({ id: 'general.upload' })}
            </Button>
          </Upload>
        </div>
      ) : (
        <div className={'wrapper-banner'}>
          <ImagePlaceholder
            images={memoizedImages}
            imagesData={imagesData}
            setImagesData={setImagesData}
            setRemovedImageIds={setRemovedImageIds}
          />
        </div>
      )}
      <Requirements />
    </StyledBannerPreview>
  )
}
