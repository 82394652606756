import { StyledLoginForm } from './style'
import { Input } from '../../Input'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'

export interface LoginProps {
  appendData?: (data: any) => void
  isLoading?: boolean
}

export const LoginForm = ({ appendData, isLoading }: LoginProps) => {
  const intl = useIntl()
  const APP_URL = window.runConfig.backendUrl

  const handleSubmit = (values: any) => {
    if (appendData) {
      appendData(values)
    }
  }

  const handleGoogleAuth = () => {
    window.location.href = `${APP_URL}/api/v2/login/google`
  }

  const [form] = StyledLoginForm.useForm()

  return (
    <StyledLoginForm form={form} onFinish={handleSubmit}>
      <div className={'inputs'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'login.title' })}
        </div>
        <div className={'email'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'login.e_mail' })}
          </span>
          <Input
            size={'large'}
            name={'email'}
            validations={['email', 'required']}
            placeholder="**********"
          />
        </div>
        <div className={'pw'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'login.password' })}{' '}
          </span>
          <Input
            password
            size={'large'}
            name={'password'}
            validations={'required'}
            placeholder="**********"
          />
        </div>

        <Button
          className={'cstm-btn submit'}
          label={intl.formatMessage({ id: 'login.title' })}
          size={'large'}
          htmlType={'submit'}
          loading={isLoading}
          disabled={isLoading}
        />
        <Button
          label={intl.formatMessage({ id: 'login.use_google' })}
          className={'cstm-btn google'}
          icon={<img src={'/google.png'} />}
          onClick={handleGoogleAuth}
        />
        <div className={'no-acc-reg'}>
          {intl.formatMessage({ id: 'login.need_acc' })}{' '}
          <a href={'/register'} className="login_link">
            {intl.formatMessage({ id: 'login.register' })}
          </a>
        </div>
      </div>
    </StyledLoginForm>
  )
}
