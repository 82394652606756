import styled from 'styled-components';

export const StyledMobileMenu = styled.div`
  display: none;
  position: fixed;
  bottom: 0;
  z-index: 1010;
  width: 100vw;
  height: 100px;
  background-color: ${({ theme }) => theme.white};
  border-radius: 15px 15px 0 0;

  .mobile-menu-container {
    display: none;
    box-shadow: 0px 4px 84px 0px ${({ theme }) => theme.boxShadowColor};

    border-radius: 15px 15px 0 0;

    :where(.ant-btn).ant-btn-variant-text:not(:disabled):not(
        .ant-btn-disabled
      ):hover {
      background-color: transparent;
    }
  }

  .mobile-menu-item {
    position: relative;
  }

  .mobile-menu-item.selected::before {
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%);
    width: 5px;
    height: 5px;
    background-color: ${({ theme }) => theme.softSilverGray};
    border-radius: 50%;
    content: '';
  }

  @media only screen and (max-width: 1200px) {
    display: block;

    .mobile-menu-container {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;

      height: 100%;

      .mobile-menu-filter {
        margin: 0 20px 20px 20px;
      }

      .mobile-menu-content {
        display: flex;
        gap: 0;

        .mobile-menu-item {
          .ant-btn {
            display: inline-flex;
            align-items: center;
            flex-direction: column;
            gap: 0;

            span {
              height: 24px;
            }
          }

          .text {
            color: ${({ theme }) => theme.neutralGray};
          }
        }

        button {
          height: 60px;
        }

        .mobile-menu-item.selected {
          .text {
            color: ${({ theme }) => theme.bgColorHover};
          }
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    display: block;

    .mobile-menu-container {
      justify-content: space-around;

      .mobile-menu-filter {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  @media only screen and (max-width: 356px) {
    .mobile-menu-container {
      .mobile-menu-content {
        .mobile-menu-item {
          .ant-btn {
            padding-right: 5px;
            padding-left: 5px;
          }
        }
      }
    }
  }
`;
