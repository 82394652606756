import styled from 'styled-components'

export const StyledDiscountItems = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-family: ${(props) => props.theme.fontText};

  .product-slider-container {
    padding: 0 0 20px 0;
  }

  .slick-dots {
    position: relative;
    padding: 30px 0 0 0;
  }

  .view-all-items-button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 0 50px 0;
  }

  .title {
    display: none;
  }

  @media only screen and (max-width: 1200px) {
    .product-slider-container {
      padding: 0 0 20px 0;
    }
  }

  @media only screen and (max-width: 440px) {
    .view-all-items-button {
      width: 100%;
      padding: 0 20px 50px 20px;
      a {
        width: 100%;
      }
    }

    .product-slider-container {
      padding: 0 0 20px 0;

      .slick-active > div {
        width: 100%;
      }
    }

    .view-all-items-button {
      button {
        width: 100%;
      }
    }

    .title {
      display: flex;
      padding: 1em 0 0 0;
      text-align: left;
      font-weight: 300;
      h1 {
        font-size: 18px;
      }
    }
  }
`
