import styled from 'styled-components'

export const StyledNewProductForm = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .ant-form-item-explain-error {
    position: absolute;
    font-size: 10px;
    z-index: 40;
  }

  .desc {
    .ant-input {
      height: 152px;
    }
  }

  .ant-upload-list {
    display: flex;
    padding-top: 30px;
    gap: 20px;
  }
  .social-icons {
    display: flex;
    gap: 20px;

    img {
      cursor: pointer;
    }
  }
  .ant-input-affix-wrapper {
    border-radius: 5px;
  }

  .ant-input {
    border-radius: 5px;
  }

  .time {
    display: flex;
    flex-direction: column;
  }

  .ant-input-number-suffix {
    .unit-suffix {
      height: 100%;
      position: absolute;
      right: 0;
      display: flex;
      min-width: 57px;
      align-items: center;
      justify-content: center;
      border-left: 1px solid ${(props) => props.theme.inputAddonBg};
      background-color: ${(props) => props.theme.inputAddonBg};
    }
  }

  .suffix {
    display: flex;
    align-items: center;
    gap: 10px;
    cursor: pointer;
  }

  .image-upload {
    min-height: 98px;

    .ant-upload-drag,
    .ant-upload-btn,
    .ant-upload-drag-container {
      display: flex;
      flex-direction: column;
      width: 431px;
      height: 100%;
      align-items: center;
      justify-content: center;

      .ant-upload-drag-icon {
        margin: 0;
      }
    }
  }

  .requirements {
    .reqs {
      display: flex;
      flex-direction: column;
      padding-top: 15px;
      padding-bottom: 15px;
      gap: 0;
      p {
        margin: 0;
      }
    }
  }

  .scnd-tr {
    min-width: 407px;
    min-height: 38px;
  }

  .title {
    font-weight: 600;
    font-size: 16px;
    margin-top: 15px;
  }

  .flex {
    min-width: 261px;
    flex-grow: 1;
    min-height: 38px;
  }

  .col-djfgr {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
  }

  .chckboxes {
    margin-bottom: 20px;
  }

  .prod-availability {
    border-top: 1px solid ${(props) => props.theme.colorBorder};
    padding-top: 25px;
    border-bottom: 1px solid ${(props) => props.theme.colorBorder};
    padding-bottom: 25px;
  }

  .additional-info {
    padding-top: 25px;

    .chkboxes {
      display: flex;
      flex-direction: row;
      gap: 400px;
      padding-top: 10px;

      .ant-checkbox-group {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
    }
  }
`
