import styled from 'styled-components'
export const ProfileLayout = styled('div')`
  background-color: ${(props) => props.theme.white};
  padding-left: 13%;
  padding-right: 13%;
  min-width: 50%;
  padding-top: 1px;

  .title {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 42px;
    font-weight: 400;
    line-height: 38px;
    letter-spacing: -0.02em;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .tab-title {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .log-out {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .ant-tabs-tab-btn {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }

  @media (max-width: 576px) {
      padding-left: 6%;
      padding-right: 6%; 
  }
`

export const OrderLayout = styled('div')`
  width: 100%;
  min-height: 25em;

  td {
    font-weight: 400;
    color: ${(props) => props.theme.black};
  }

  .title {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .order-download {
    margin-left: 5px;
    font-family: Roboto;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-family: Roboto;
    font-weight: 500;
  }

  .view-order {
    font-family: Roboto;
    font-size: 16px;
    line-height: 16px;
    text-align: left;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    font-weight: 500;
  }
`
export const ProfileInfo = styled('div')`
  .title {
    font-family: ${(props) => props.theme.profileFontFamilySecondary};
    font-size: 24px;
    font-weight: 400;
    line-height: 32px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
`
