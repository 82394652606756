import { useMemo } from 'react';
import { Filter } from 'types/Filter';
import {
  FarmsFilterOptions,
  FilterOptions,
  LocationsFilterOptions,
} from 'types/FilterOptions';
import { MobileFilterForm } from 'ui/Form/MobileFilterForm/MobileFilterForm';

interface Props {
  setFilter: React.Dispatch<React.SetStateAction<Filter>>;
  filterOptions: FilterOptions;
  filter: Filter;
}

const MobileFilter = ({ setFilter, filterOptions, filter }: Props) => {
  const handleFilterChange = (newFilter: Filter) => {
    setFilter(newFilter);
  };

  const filterOptionsData = useMemo(() => {
    return {
      ...filterOptions,
      farms: filterOptions.farms
        ? filterOptions.farms.map((farm: FarmsFilterOptions) => ({
            ...farm,
            value: farm.id,
            label: farm.name,
          }))
        : [],
      locations: filterOptions.locations
        ? filterOptions.locations.map((farm: LocationsFilterOptions) => ({
            ...farm,
            value: farm.slug,
            label: farm.name,
          }))
        : [],
    };
  }, [filterOptions]);

  return (
    <div className={'mobile-filter-container'}>
      <MobileFilterForm
        setFilter={handleFilterChange}
        filterOptions={filterOptionsData}
        filter={filter}
      />
    </div>
  );
};

export default MobileFilter;
