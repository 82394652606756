import { StyledNewProductForm } from './style'
import { Table } from '../../Table'
import { useIntl } from 'react-intl'

export const NewProductsStructureForm = () => {
  const intl = useIntl()

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.infrastructure' }),
      dataIndex: 'infrastructure',
      render: (value: any) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.availability' }),
      dataIndex: 'availability',
      render: (value: boolean) => value,
    },
  ]

  return (
    <StyledNewProductForm>
      <Table columns={columns} />
    </StyledNewProductForm>
  )
}
