import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, ColorPicker, CustomGrid, Input, Modal, Table } from 'ui';
import type { DragEndEvent } from '@dnd-kit/core';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import useQueryApiClient from 'utils/useQueryApiClient';
import dayjs from 'dayjs';
import { Form } from 'antd';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  'data-row-key': string;
}

const Row = (props: RowProps) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });

  const style: React.CSSProperties = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    cursor: 'move',
    ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
  };

  return (
    <tr
      {...props}
      ref={setNodeRef}
      style={style}
      {...attributes}
      {...listeners}
    />
  );
};

const FarmActivityStatus = ({
  setIsModalVisibleType,
  isModalVisibleType,
}: {
  setIsModalVisibleType: React.Dispatch<React.SetStateAction<boolean>>;
  isModalVisibleType: boolean;
}) => {
  const [tableData, setTableData] = useState<any>([]);
  const [selectedEntry, setSelectedEntry] = useState<number>(0);

  const intl = useIntl();
  const [form] = Form.useForm();

  const { refetch } = useQueryApiClient({
    request: {
      url: 'api/v2/farm-activity-status',
    },
    onSuccess: (response) => {
      setTableData(response);
    },
  });

  const {} = useQueryApiClient({
    request: {
      url: `api/v2/farm-activity-status/${selectedEntry}`,
      disableOnMount: !selectedEntry && !isModalVisibleType,
    },
    onSuccess: (response) => form.setFieldsValue(response),
  });

  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/farm-activity-status/sort',
      method: 'PATCH',
    },
  });

  const { appendData: appendDataUpdate } = useQueryApiClient({
    request: {
      url: `api/v2/farm-activity-status/${selectedEntry}`,
      method: 'PATCH',
    },
    onSuccess: () => onModalClose(),
  });

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setTableData((prevState: any) => {
        const activeIndex = prevState.findIndex(
          (record: any) => record.id === active?.id
        );
        const overIndex = prevState.findIndex(
          (record: any) => record.id === over?.id
        );

        const newList = arrayMove(prevState, activeIndex, overIndex);

        appendData({ items: newList });

        return newList;
      });
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.id' }),
      dataIndex: 'id',
    },
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'label',
    },
    {
      title: intl.formatMessage({ id: 'general.color' }),
      dataIndex: 'color',
      render: (value: string) =>
        value && <ColorPicker value={value} disabled />,
    },
    {
      title: intl.formatMessage({ id: 'general.text_color' }),
      dataIndex: 'text_color',
      render: (value: string) =>
        value && <ColorPicker value={value} disabled />,
    },
    {
      title: intl.formatMessage({ id: 'general.created_at' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.updated_at' }),
      dataIndex: 'updated_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      dataIndex: 'id',
      render: (value: number) => (
        <>
          <Button
            type="link"
            label={intl.formatMessage({ id: 'general.edit' })}
            onClick={() => {
              setSelectedEntry(value);
              setIsModalVisibleType(true);
            }}
          />
        </>
      ),
    },
  ];

  const onModalClose = () => {
    setIsModalVisibleType(false);
    setSelectedEntry(0);
    form.resetFields();
    refetch();
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={onDragEnd}
      >
        <SortableContext
          items={tableData.map((i: any) => i.id)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            dataSource={tableData}
            columns={columns}
            disablePagination
            components={{ body: { row: Row } }}
          />
        </SortableContext>
      </DndContext>
      <Modal
        open={isModalVisibleType}
        onCancel={onModalClose}
        title={intl.formatMessage({ id: 'settings.farm_activity_status' })}
        footer={
          <CustomGrid gapRow={10}>
            <Button
              label={intl.formatMessage({ id: 'general.cancel' })}
              onClick={onModalClose}
            />
            <Button
              label={intl.formatMessage({ id: 'general.submit' })}
              type="primary"
              htmlType="submit"
              onClick={() => form.submit()}
            />
          </CustomGrid>
        }
      >
        <Form form={form} onFinish={appendDataUpdate} layout="vertical">
          <Input
            name="label"
            label={intl.formatMessage({ id: 'general.name' })}
          />
          <ColorPicker
            name="color"
            label={intl.formatMessage({ id: 'general.color' })}
          />
          <ColorPicker
            name="text_color"
            label={intl.formatMessage({ id: 'general.text_color' })}
          />
        </Form>
      </Modal>
    </>
  );
};

export default FarmActivityStatus;
