import styled from 'styled-components';

export const StyledFarmContractsPage = styled('div')`
  .actions {
    min-width: 210px;
    display: flex;
    gap: 15px;
    justify-content: right;
  }

  .ant-table-content {
    min-height: 900px;
  }

  .ant-form-item-control-input-content {
    max-width: 150px;
  }

  .ant-form-item {
    margin: 0;
  }
`;
