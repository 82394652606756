import styled from 'styled-components'
export const ContractModalContent = styled.div`
  .horizont-line-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .horizont-line {
    border-bottom: 1px solid ${(props) => props.theme.modalHorizontalLine};
    width: 110%;
  }

  .contract-info {
    color: #646c9a;
    font-weight: bold;
  }

  .checked {
    color: ${(props) => props.theme.checked};
  }

  .minus {
    color: ${(props) => props.theme.minus};
  }
`
