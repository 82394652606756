import React, {useMemo} from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import {Tabs} from "../../../ui";

const EShopEmailListPage = () => {
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: `/api/v2/listofemailsemarket`,
    },
  })

    const items = useMemo(()=> [
        {
            key: 'e_shop_emails',
            label: intl.formatMessage({ id: 'navigation.e_shop_email' }),
            children: (
                <>
                    {!!data.length &&
                        data.map((item: any) => item.contact_email).join(', ')}
                </>
            ),
        },
    ], [data, intl])

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.e_shop_email' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
          <Tabs
              items={items}
          />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default EShopEmailListPage
