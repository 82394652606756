import { StyledNewProductForm } from './style'
import { Table } from '../../Table'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { useState, useEffect } from 'react'
import { Checkbox, Form, Input } from 'antd'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'

interface DeliveryProps {
  id: number
  type: 'parcel' | 'omniva' | 'dpd' | 'izipizi' | 'self' | 'delivery'
  price: number
  selected?: boolean
  description?: string
}

interface RequiredProps {
  farmId?: number
}

export const NewProductDeliveryForm = ({ farmId }: RequiredProps) => {
  const [deliveries, setDeliveries] = useState<DeliveryProps[]>([])
  const intl = useIntl()
  const form = useFormInstance()

  useQueryApiClient({
    request: {
      url: `api/v2/deliveries/${farmId}`,
    },
    onSuccess: (response) => {
      setDeliveries(response)
    },
  })

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.name' }),
      dataIndex: 'type',
      key: 'type',
      render: (text: string) => intl.formatMessage({ id: `delivery.${text}` }),
    },
    {
      title: intl.formatMessage({ id: 'general.status' }),
      key: 'status',
      render: (record: DeliveryProps) => (
        <Checkbox key={`${record.id}`} name={'delivery'} value={record.id} />
      ),
    },
  ]

  return (
    <StyledNewProductForm>
      <Form.Item name="delivery">
        <Checkbox.Group defaultValue={form.getFieldValue('delivery')}>
          <Table columns={columns} dataSource={deliveries} rowKey="id" />
        </Checkbox.Group>
      </Form.Item>
    </StyledNewProductForm>
  )
}
