import { StyledOrderBySelector } from './style'
import { Select, SelectOption } from '../Select'
import { DownOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

export interface props {
  value?: string
  allowClear?: boolean
}
export const OrderBySelector = ({ value, allowClear = false }: props) => {
  const intl = useIntl()

  const orderBy = [
    {
      name: 'newest',
    },
    {
      name: 'name',
    },
  ]

  return (
    <StyledOrderBySelector>
      <div className={'txt-icon'}>
        <div className={'text'}>
          {intl.formatMessage({ id: 'top_filter.filter_by' })}:
        </div>
        <div className={'icon sz-sm'}>
          <Select
            placeholder={
              <DownOutlined
                style={{
                  color: 'black',
                  fontSize: '14px',
                }}
              />
            }
            allowClear={allowClear}
            showSearch={false}
            variant={'borderless'}
            dropdownStyle={{
              minWidth: '150px',
            }}
            defaultValue={value}
            value={value}
            name={'order_by'}
            children={orderBy.map((opt, index) => (
              <SelectOption
                value={opt.name}
                title={opt.name}
                key={`${index}-${opt.name}`}
              >
                {intl.formatMessage({ id: `top_filter.${opt.name}` })}
              </SelectOption>
            ))}
          />
        </div>
      </div>
    </StyledOrderBySelector>
  )
}
