import styled from 'styled-components'

export const StyledPopularCategories = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.white};
  padding-top: 100px;

  .title {
    text-align: left;
    padding-bottom: 15px;
    font-weight: 300;

    h1 {
      font-size: 42px;
      font-weight: 500;
      line-height: 38px;
      margin: 0 0 54px;
    }
  }

  .container-category {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    flex-wrap: wrap;
  }

  @media only screen and (max-width: 768px) {
    .title {
      text-align: left;
      padding: 1em 0 0 1em;

      h1 {
        font-size: 24px;
      }
    }
  }

  @media only screen and (max-width: 440px) {
    display: none;
    padding: 0 20px;

    .ant-card-cover {
      img {
        height: 194px;
        object-fit: cover;
      }
    }

    .slick-track {
      display: flex;
      gap: 20px;
    }

    .container-category {
      padding: 0 0 5px 0;
    }

    .title {
      padding: 1em 0 0 0;

      h1 {
        font-size: 18px;
      }
    }
  }
`
