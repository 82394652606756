import React, { useState, useMemo } from 'react';
import DefaultLayout from 'admin/components/DefaultLayout';
import { useIntl } from 'react-intl';
import {
  Button,
  Tabs,
  Table,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
} from 'ui';
import Filter from 'admin/components/Filter';
import { StyledButtonList } from 'styles/buttonList';
import dayjs, { Dayjs } from 'dayjs';
import EnumSelect from '../../../Selects/EnumSelect';
import useQueryApiClient from '../../../../utils/useQueryApiClient';
import FileDownload from 'js-file-download';
import { MenuProps } from 'antd/es/menu';
import { DownOutlined } from '@ant-design/icons';
import { Pagination } from '../../../../types/Pagination';

const TransactionListPage = () => {
  const [advancedSearch, setAdvancedSearch] = useState<any>({
    status_not: ['canceled', 'delivered'],
  });
  const [paginationStatus, setPaginationStatus] = useState<Pagination>({});
  const [staticFilter, setStaticFilter] = useState({
    status_not: ['canceled', 'delivered'],
  });
  const [advancedOptionsVisible, setAdvancedOptionsVisible] =
    useState<boolean>(false);

  const intl = useIntl();

  const { appendData: exportData } = useQueryApiClient({
    request: {
      url: `api/v2/transactions/export`,
      data: {
        filter: advancedSearch,
      },
      multipart: true,
      method: 'POST',
    },
    onSuccess: (response, format) => {
      FileDownload(
        response,
        `${format}_${dayjs().format('YYYY-MM-DD')}.${format}`
      );
    },
  });

  const columns = [
    {
      title: intl.formatMessage({ id: 'transactions.date_time' }),
      dataIndex: 'created_at',
      render: (value: string) =>
        value && dayjs(value).format('DD.MM.YYYY | HH:mm'),
    },
    {
      title: intl.formatMessage({ id: 'transactions.order_nr' }),
      dataIndex: 'number',
      render: (value: string) => value || 'Nav numura',
    },
    {
      title: intl.formatMessage({ id: 'transactions.customer' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.status' }),
      dataIndex: 'status',
      render: (value: string) =>
        value && intl.formatMessage({ id: 'enum.' + value?.toUpperCase() }),
    },
    {
      title: intl.formatMessage({ id: 'transactions.price' }),
      dataIndex: 'total_sum',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'transactions.invoice' }),
      dataIndex: 'id',
      render: () => (
        <Button
          type="link" //todo add download, waiting for NOGA-165 where similar functionality is implemented
          label={intl.formatMessage({ id: 'transactions.download' })}
        />
      ),
    },
    {
      title: intl.formatMessage({ id: 'general.actions' }),
      render: () => (
        <Dropdown menu={menuPropsTable}>
          <Button
            label={intl.formatMessage({ id: 'general.actions' })}
            icon={<DownOutlined />}
            iconPosition="end"
            size="small"
          />
        </Dropdown>
      ),
    },
  ];

  const items = [
    {
      key: 'active_transactions',
      label: intl.formatMessage({ id: 'transactions.active_transactions' }),
      children: (
        <>
          <Filter
            onFinish={setAdvancedSearch}
            totalRecord={paginationStatus?.total ?? 0}
            staticFilter={staticFilter}
            customParse={{
              year: (value: Dayjs) => value?.year(),
              month: (value: Dayjs) => value?.format('MMMM'),
            }}
          >
            <DatePicker
              label={intl.formatMessage({ id: 'general.year' })}
              name="year"
              picker="year"
              format="YYYY"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.month' })}
              name="month"
              picker="month"
              format="MMMM"
            />
            <EnumSelect
              label={intl.formatMessage({ id: 'transactions.status' })}
              name="status"
              code="PurchaseStatusType"
              limited={['new', 'confirmed', 'sent']}
            />
            <Input
              label={intl.formatMessage({ id: 'transactions.order_nr' })}
              name="order_nr"
            />
            {advancedOptionsVisible && (
              <>
                <Input
                  label={intl.formatMessage({ id: 'transactions.customer' })}
                  name="customer_name"
                />
                <EnumSelect
                  label={intl.formatMessage({
                    id: 'transactions.delivery_type',
                  })}
                  name="delivery_type"
                  code="DeliveryType"
                />
                <InputNumber
                  label={intl.formatMessage({ id: 'transactions.price' })}
                  name="price"
                  precision={2}
                />
              </>
            )}
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />

            <Button
              label={intl.formatMessage({ id: 'general.advanced_filter' })}
              onClick={() => setAdvancedOptionsVisible((old) => !old)}
            />
          </Filter>
          <Table
            url="/api/v2/transactions"
            columns={columns}
            linkProps={{ url: '/admin/form' }}
            filter={advancedSearch}
            paginnationStatus={(el: object) => {
              setPaginationStatus(el);
            }}
          />
        </>
      ),
    },
    {
      key: 'old_transactions',
      label: intl.formatMessage({ id: 'transactions.old_transactions' }),
      children: (
        <>
          <Filter
            onFinish={setAdvancedSearch}
            totalRecord={paginationStatus?.total ?? 0}
            staticFilter={staticFilter}
            customParse={{
              year: (value: Dayjs) => value?.year(),
              month: (value: Dayjs) => value?.format('MMMM'),
            }}
          >
            <DatePicker
              label={intl.formatMessage({ id: 'general.year' })}
              name="year"
              picker="year"
              format="YYYY"
            />
            <DatePicker
              label={intl.formatMessage({ id: 'general.month' })}
              name="month"
              picker="month"
              format="MMMM"
            />
            <EnumSelect
              label={intl.formatMessage({ id: 'transactions.status' })}
              name="status"
              code="PurchaseStatusType"
              limited={['canceled', 'delivered']}
            />
            <Input
              label={intl.formatMessage({ id: 'transactions.order_nr' })}
              name="order_nr"
            />
            {advancedOptionsVisible && (
              <>
                <Input
                  label={intl.formatMessage({ id: 'transactions.customer' })}
                  name="customer_name"
                />
                <EnumSelect
                  label={intl.formatMessage({
                    id: 'transactions.delivery_type',
                  })}
                  name="delivery_type"
                  code="DeliveryType"
                />
                <InputNumber
                  label={intl.formatMessage({ id: 'transactions.price' })}
                  name="price"
                  precision={2}
                />
              </>
            )}
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.select' })}
              htmlType="submit"
            />

            <Button
              label={intl.formatMessage({ id: 'general.advanced_filter' })}
              onClick={() => setAdvancedOptionsVisible((old) => !old)}
            />
          </Filter>
          <Table
            url="/api/v2/transactions"
            columns={columns}
            linkProps={{ url: '/admin/form' }}
            filter={advancedSearch}
            paginnationStatus={(el: object) => {
              setPaginationStatus(el);
            }}
          />
        </>
      ),
    },
  ];

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    if (e.key === 'xlsx' || e.key === 'csv') {
      exportData({ format: e.key }, [], e.key);
    }
  };

  const dropdownItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'general.excel' }),
      key: 'xlsx',
    },
    {
      label: intl.formatMessage({ id: 'general.csv' }),
      key: 'csv',
    },
  ];

  const dropdownTableItems: MenuProps['items'] = [
    {
      label: intl.formatMessage({ id: 'enum.CONFIRMED' }),
      key: '1',
    },
    {
      label: intl.formatMessage({ id: 'enum.SENT' }),
      key: '2',
    },
    {
      label: intl.formatMessage({ id: 'transactions.completed' }),
      key: '3',
    },
  ];

  const menuProps = {
    items: dropdownItems,
    onClick: handleMenuClick,
  };

  const menuPropsTable = {
    items: dropdownTableItems,
  };

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.transactions' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
        <Tabs
          tabBarExtraContent={
            <StyledButtonList>
              <Dropdown menu={menuProps}>
                <Button
                  label={intl.formatMessage({ id: 'general.export' })}
                  type="primary"
                />
              </Dropdown>
            </StyledButtonList>
          }
          items={items}
          onChange={(key) => {
            key === 'active_transactions'
              ? setStaticFilter({ status_not: ['canceled', 'delivered'] })
              : setStaticFilter({ status_not: ['sent', 'confirmed', 'new'] });
            key === 'active_transactions'
              ? setAdvancedSearch({ status_not: ['canceled', 'delivered'] })
              : setAdvancedSearch({ status_not: ['sent', 'confirmed', 'new'] });
          }}
        />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  );
};

export default TransactionListPage;
