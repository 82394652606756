import { styled } from 'styled-components'

export const StyledCartPage = styled('div')`
  padding: 30px 240px 0 240px;
  background-color: ${(props) => props.theme.whiteBackground};
  border-radius: 15px;

  .container {
    padding: 0;
  }

  .cart-page-title {
    font-weight: 500;
    font-size: 25px;
    font-family: ${(props) => props.theme.fontText};
  }

  .back-to-catalog {
    display: none;
  }

  .cart-page-title {
    display: block;
  }

  .cart-page-title.mobile {
    display: none;
    h1 {
      font-size: 18px;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 1600px) {
    padding: 30px 140px 0 140px;
  }

  @media only screen and (max-width: 1200px) {
    padding: 20px 70px 20px 70px;
  }

  @media only screen and (max-width: 800px) {
    padding: 6px 20px 0 20px;
  }

  @media only screen and (max-width: 576px) {
    margin-top: 6px;
    border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    padding-bottom: 20px;

    .back-to-catalog {
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 37px 20px 17px 20px;
      border-bottom: 1px solid ${(props) => props.theme.lightGrayBorder};
    }

    div[class*='ant-steps'] {
      display: none;
    }

    .cart-page-title {
      display: none;
      font-size: 18px;
    }

    .cart-page-title h1 {
      margin: 12px 0;
    }

    .cart-page-title {
      display: none;
    }

    .cart-page-title.mobile {
      display: block;
    }
  }
`
