import styled from 'styled-components'
import { Modal } from '../Modal'

export const StyledCartDeliveryModal = styled(Modal)`
  .ant-modal-content {
    margin: -80px 20px 100px 20px !important;
    overflow-y: auto;
    padding: 16px;
  }
`
