import React from 'react'
import { Form, Switch as AntdSwitch, SwitchProps as Props } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'

export interface SwitchProps {
  label?: string
  rules?: Rule[]
  name?: (string | number)[] | string | number
  className?: string
  validateTrigger?: string | string[]
  noStyle?: boolean
  initialValue?: string
  disabled?: boolean
  props?: Props
}

export const Switch = ({
  disabled = false,
  name,
  label,
  rules,
  className,
  validateTrigger,
  noStyle,
  initialValue,
  ...props
}: SwitchProps) => {
  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={rules}
      className={className}
      validateTrigger={validateTrigger}
      noStyle={noStyle}
    >
      <AntdSwitch disabled={disabled} {...props} />
    </Form.Item>
  )
}
