import { StyledDrawer, StyledFooter } from './style'
import { Tabs } from '../../Tabs'
import { useIntl } from 'react-intl'
import { NewProductForm } from '../../Form/products/NewProductForm'
import { Button } from '../../Button'
import {
  NewProductBaseInfoForm,
  QmarkResponse,
} from '../../Form/products/NewProductBaseInfoForm'
import { NewProductGallery } from '../../Form/products/NewProductGallery'
import { NewProductMacros } from '../../Form/products/NewProductMacros'
import { NewProductDeliveryForm } from '../../Form/products/NewProductDeliveryForm'
import React, { CSSProperties, useEffect, useState } from 'react'
import { Form, message } from 'antd'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { NewProductsStructureForm } from '../../Form/products/NewProductsStructureForm'
import { UploadFile } from 'antd/es/upload/interface'
import { Spinner } from '../../Spinner'
import { getMimeType } from '../../../utils/imageHelpers'
import dayjs from 'dayjs'

interface DrawerCreateProductProps {
  farmId?: number
  open?: boolean
  onClose?: (e: React.MouseEvent | React.KeyboardEvent) => void
  closeDrawer?: (value: boolean) => void
  productId?: number
}

export interface CategoryOption {
  id: number
  label: string
}

export const DrawerCreateProduct = ({
  open,
  onClose,
  productId,
  closeDrawer,
  farmId,
}: DrawerCreateProductProps) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [imageValidated, setImageValidated] = useState(false)
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [mainCategoryValue, setMainCategoryValue] = useState<
    number | undefined
  >()
  const [subCategoryValue, setSubCategoryValue] = useState<number | undefined>()
  const [subSubCategoryValue, setSubSubCategoryValue] = useState<
    number | undefined
  >()
  const [stepValue, setStepValue] = useState<number | undefined>(undefined)
  const [macrosValidated, setMacrosValidated] = useState(false)

  const [form] = Form.useForm()
  const intl = useIntl()

  const isEditMode = !!productId
  const requestUrl = isEditMode
    ? `api/v2/products/${productId}?_method=PATCH`
    : 'api/v2/products'

  const title = intl.formatMessage({ id: 'general.create_product_title' })

  const { appendData: callImage } = useQueryApiClient({
    request: {
      url: '/api/v2/image',
      multipart: true,
      disableOnMount: true,
    },
    onSuccess: (response, passOnSuccess) => {
      handleRawFile(response, passOnSuccess.path, passOnSuccess.id)
    },
  })

  const { data, isLoading } = useQueryApiClient({
    request: {
      url: `api/v2/products/${productId}`,
      data: {
        asResource: true,
      },
      disableOnMount: !isEditMode,
    },
    onSuccess: (response) => handleProductForm(response),
  })

  const { appendData } = useQueryApiClient({
    request: {
      url: requestUrl,
      method: 'POST',
      multipart: true,
    },
    onSuccess: () => {
      handleSuccess()
    },
  })

  useEffect(() => {
    if (!productId) {
      form.resetFields()
    }
  }, [productId])

  const handleRawFile = (imageRawFile: any, imageUrl: string, id: string) => {
    const urlParts = imageUrl.split('/')
    const fileName = urlParts[urlParts.length - 1] || 'image.png'

    const mimeType = getMimeType(fileName)

    const file = new File([imageRawFile], fileName, { type: mimeType })

    const antdObject: any = {
      lastModified: file.lastModified,
      lastModifiedDate: new Date(file.lastModified),
      name: file.name,
      status: 'done',
      url: imageUrl,
      uid: id,
      originFileObj: file,
      type: file.type,
    }

    setFileList((prevFileList) => [...prevFileList, antdObject])
  }

  const handleProductForm = (productData: any) => {
    const rawRealisationTime = productData['realisation_time_unparsed']

    form.setFieldsValue({
      ...productData,
    })

    if (rawRealisationTime && rawRealisationTime !== 'undefined') {
      try {
        const parsedTimeString = JSON.parse(rawRealisationTime)

        const realisationTime = dayjs(parsedTimeString)

        if (realisationTime.isValid()) {
          form.setFieldsValue({ realisation_time: realisationTime })
        } else {
          console.warn('Invalid date format received:', parsedTimeString)
        }
      } catch (error) {
        console.error('Error parsing realisation_time_unparsed:', error)
      }
    }

    if (productData['step']) {
      setStepValue(Number(productData['step']))
    }

    if (productData['main-category']) {
      setMainCategoryValue(Number(productData['main-category']))
    }

    if (productData['sub-category']) {
      setSubCategoryValue(Number(productData['sub-category']))
    }

    if (productData['sub-subcategory']) {
      setSubSubCategoryValue(Number(productData['sub-subcategory']))
    }

    if (productData['options']) {
      const selectedOptions: number[] = productData['options'].map(
        (opt: CategoryOption) => opt.id
      )
      form.setFieldsValue({ options: selectedOptions })
    }

    if (productData['qualitymarks']) {
      const selectedQmarks: number[] = productData['qualitymarks'].map(
        (qmark: QmarkResponse) => qmark.id
      )
      form.setFieldsValue({ qmarks: selectedQmarks })
    }

    if (productData['is_processed'] && productData['emarket']) {
      const checkedValues = []
      if (productData['is_processed'] == true) checkedValues.push('gmo')
      if (productData['emarket'] == true) checkedValues.push('emarket')
      if (productData['hidden'] == false) checkedValues.push('hidden')
      form.setFieldsValue({ checkbox: checkedValues })
    }

    if (productData['product_deliveries']) {
      const deliveries = productData['product_deliveries'].map(
        (delivery: any) => delivery.farm_delivery_id
      )
      form.setFieldsValue({ delivery: deliveries })
    }

    if (productData.images && Array.isArray(productData.images)) {
      const mappedFiles = productData.images.map((img: any, index: number) => {
        callImage({ path: img.url_original }, [], {
          path: img.url_original,
          id: img.id,
        })
      })

      setImageValidated(mappedFiles.length > 0)
    }
  }

  const handleSuccess = () => {
    form.resetFields()
    setCurrentStep(0)
    setFileList([])
    setImageValidated(false)
    if (closeDrawer) {
      closeDrawer(false)
    }
  }

  const stepFieldMap: any = {
    add_product: [
      'name',
      'step',
      'main-category',
      'sub-category',
      'sub-subcategory',
      'storage_unit',
      'step',
      'storage_stock_count',
      'availability',
      'storage_limit_min_test',
      'storage_limit_max',
      'storage_stock_price',
      'storage_stock_discount_price',
      'realisation_time',
    ],
    add_product_infrastructure: [],
    add_product_image: [],
    add_products_delivery: [],
    add_product_base: [
      'availability',
      'storage_limit_min',
      'storage_wholesale_price',
    ],
    add_product_macros: [
      'ingredients',
      ['nutritional_values', 'kcal'],
      ['nutritional_values', 'fat'],
      ['nutritional_values', 'saturated_fat'],
      ['nutritional_values', 'carbohydrates'],
      ['nutritional_values', 'sugars'],
      ['nutritional_values', 'proteins'],
      ['nutritional_values', 'salt'],
    ],
  }

  const steps = [
    {
      key: 'add_product',
      label: intl.formatMessage({ id: 'navigation.information' }),
      content: (
        <NewProductForm
          mainCategoryValue={mainCategoryValue}
          subCategoryValue={subCategoryValue}
          subSubCategoryValue={subSubCategoryValue}
          stepValue={stepValue}
          initStepValue={isEditMode}
        />
      ),
    },
    {
      key: 'add_product_base',
      label: intl.formatMessage({ id: 'navigation.base_information' }),
      content: <NewProductBaseInfoForm />,
    },
    {
      key: 'add_product_infrastructure',
      label: intl.formatMessage({ id: 'navigation.infrastructure' }),
      content: <NewProductsStructureForm />,
    },
    {
      key: 'add_product_image',
      label: intl.formatMessage({ id: 'navigation.gallery' }),
      content: (
        <NewProductGallery
          onValidate={setImageValidated}
          onFileListChange={setFileList}
          fileList={fileList}
        />
      ),
    },
    {
      key: 'add_product_macros',
      label: intl.formatMessage({ id: 'navigation.macros' }),
      content: <NewProductMacros />,
    },
    {
      key: 'add_products_delivery',
      label: intl.formatMessage({ id: 'navigation.delivery' }),
      content: <NewProductDeliveryForm farmId={farmId} />,
    },
  ]
  const nextStep = async () => {
    try {
      await validateFields()
      if (steps[currentStep].key === 'add_product_image' && !imageValidated) {
        message.error(
          intl.formatMessage({
            id: 'validation.image_upload_required',
          })
        )
        return
      }

      setCurrentStep(currentStep + 1)
    } catch (error: unknown) {
      handleErrors(error)
    }
  }

  function isValidationError(
    error: unknown
  ): error is { errorFields: { name: string[] }[] } {
    return typeof error === 'object' && error !== null && 'errorFields' in error
  }

  const resetEverything = () => {
    form.resetFields()
    setCurrentStep(0)
    setImageValidated(false)
    setMainCategoryValue(undefined)
    setSubCategoryValue(undefined)
    setSubSubCategoryValue(undefined)
    setFileList([])
  }

  const handleCancelClick = (e: React.MouseEvent) => {
    resetEverything()
    if (onClose) {
      onClose(e)
    }
  }

  const handleErrors = (error: unknown) => {
    if (isValidationError(error)) {
      if (error.errorFields.length > 1) {
        message.error(
          intl.formatMessage({
            id: 'validation.fix_errors',
          })
        )
      } else {
        const fieldError = error.errorFields[0]
        message.error(
          intl.formatMessage({
            id: `validation.${fieldError.name[0]}`,
          })
        )
      }
    } else {
      message.error(intl.formatMessage({ id: 'validation.error' }))
    }
  }

  const validateFields = async () => {
    const currentStepKey = steps[currentStep].key
    const fieldsToValidate = stepFieldMap[currentStepKey]

    await form.validateFields(fieldsToValidate)
  }

  const prevStep = async () => {
    try {
      await validateFields()
      setCurrentStep(currentStep - 1)
    } catch (error: unknown) {
      handleErrors(error)
    }
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      const formData = new FormData()
      const values = form.getFieldsValue()

      const newImages = fileList.filter((file: any) => file?.originFileObj)

      newImages.forEach((file: any) => {
        formData.append('images[]', file?.originFileObj, file?.name)
      })

      Object.keys(values).forEach((key) => {
        if (key === 'images') return

        const value = values[key]
        if (value == null) {
          return
        }

        if (Array.isArray(value)) {
          value.forEach((value) => formData.append(`${key}[]`, value))
        } else if (typeof value === 'object') {
          formData.append(key, JSON.stringify(value))
        } else {
          formData.append(key, value)
        }
      })

      formData.set('farm_id', `${farmId}`)

      appendData(formData)
      resetEverything()
    } catch (error: any) {
      message.error(error?.message)
    }
  }

  const Footer = () => (
    <StyledFooter>
      <div className={'btns'}>
        <Button
          label={intl.formatMessage({ id: 'general.cancel' })}
          onClick={handleCancelClick}
        />
        {currentStep > 0 && (
          <Button
            label={intl.formatMessage({ id: 'general.previous' })}
            onClick={prevStep}
          />
        )}
        {currentStep < steps.length - 1 && (
          <Button
            label={intl.formatMessage({ id: 'general.next_step' })}
            type={'primary'}
            onClick={nextStep}
          />
        )}
        {currentStep === steps.length - 1 && (
          <Button
            label={intl.formatMessage({ id: 'general.submit' })}
            type={'primary'}
            onClick={handleSubmit}
          />
        )}
      </div>
    </StyledFooter>
  )

  return (
    <StyledDrawer
      open={open}
      onClose={onClose}
      footer={<Footer />}
      title={title}
      width={1000}
    >
      <Spinner spinning={isLoading} dontRender>
        <Form form={form}>
          <Tabs
            activeKey={steps[currentStep].key}
            items={steps.map((step) => ({
              key: step.key,
              label: step.label,
              children: step.content,
            }))}
          />
        </Form>
      </Spinner>
    </StyledDrawer>
  )
}
