import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ColumnsType, TableLocale } from 'antd/lib/table/interface';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { Table as AntdTable } from 'antd';
import useQueryApiClient from 'utils/useQueryApiClient';
import {
  FilterValue,
  Key,
  SortOrder,
  TablePaginationConfig,
  TableRowSelection,
} from 'antd/es/table/interface';
import { RowClassName } from 'rc-table/lib/interface';
import { AnyObject } from 'antd/es/_util/type';
import { EditableCell } from './EditableCell';
import { Pagination } from 'types/Pagination';

export interface TableProps {
  locale?: TableLocale;
  saveData?: any;
  loading?: boolean;
  rowKey?: string;
  columns: ColumnsType<any>;
  dataSource?: object[];
  size?: 'small' | 'large' | 'middle';
  rowClassName?: string | RowClassName<AnyObject> | undefined;
  onRow?: any;
  onChange?: any;
  disablePagination?: boolean;
  components?: any;
  scroll?: any;
  linkProps?: {
    url: string;
    recordKey?: string;
  };
  url?: string;
  filter?: object;
  page_size?: number;
  defaultSort?: string;
  defaultOrder?: 'asc' | 'desc';
  enableSelectedRow?: boolean;
  reload?: number;
  setSelectedRows?: any;
  setSelectedKeys?: any;
  rowSelectionFunction?: TableRowSelection<any>;
  className?: string;
  paginnationStatus?: React.Dispatch<React.SetStateAction<Pagination>>;
  options?: {};
}

interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode;
  dataIndex?: string;
  record: any;
  className: string;
  colSpan?: number;
}

interface SorterResult {
  order?: SortOrder;
  field?: string;
  columnKey?: Key;
}

export const Table = ({
  locale,
  loading,
  rowKey = 'id',
  saveData,
  columns,
  dataSource,
  size,
  rowClassName,
  onRow,
  onChange,
  disablePagination,
  components,
  scroll,
  linkProps,
  url,
  filter,
  page_size = 20,
  defaultSort,
  defaultOrder,
  enableSelectedRow,
  reload,
  setSelectedRows,
  setSelectedKeys,
  rowSelectionFunction,
  className,
  options,
  paginnationStatus,
}: TableProps) => {
  const [innerData, setInnerData] = useState<any>(dataSource || []);
  const [currentPage, setCurrentPage] = useState<number | undefined>(1);
  const [sortBy, setSortBy] = useState<string | undefined>(defaultSort);
  const [orderBy, setOrderBy] = useState<string | undefined>(defaultOrder);
  const [localPagination, setLocalPagination] = useState<any>({
    showSizeChanger: false,
    showQuickJumper: false,
  });
  const intl = useIntl();

  useEffect(() => {
    setInnerData(dataSource);
  }, [dataSource]);

  useEffect(() => {
    setSortBy(defaultSort);
  }, [defaultSort]);

  useEffect(() => {
    setOrderBy(defaultOrder);
  }, [defaultOrder]);

  const { refetch, isLoading } = useQueryApiClient({
    request: {
      url: url || '',
      data: {
        page: currentPage ?? 1,
        page_size: page_size,
        options: options,
        sort_by: sortBy,
        order_by: orderBy,
        filter: filter,
      },
      disableOnMount: true,
    },
    onSuccess: (response) => {
      setInnerData(disablePagination ? response : response.data);
      saveData?.(response.data);
      setCurrentPage(response.current_page);
      setLocalPagination({
        ...localPagination,
        lastPage: response.last_page,
        current: response.current_page,
        total: response.total,
      });
      if (currentPage !== 1 && response.data.length === 0) {
        setCurrentPage((old) => (old ? old - 1 : 1));
      }
      paginnationStatus?.({
        page: currentPage ?? 1,
        page_size: page_size,
        total: response.total,
      });
    },
  });

  useEffect(() => {
    if (url) {
      refetch();
    }
  }, [sortBy, currentPage, orderBy, filter, page_size, reload]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!locale) {
    locale = {
      emptyText: intl.formatMessage({ id: 'general.found_no_data' }),
    };
  }

  const pagination = {
    showSizeChanger: true,
    showQuickJumper: true,
    defaultPageSize: 20,
    position: ['bottomCenter'],
  };

  //TODO this is for sort
  const element = document.getElementsByClassName(
    'anticon anticon-down ant-select-suffix'
  );

  element[0]?.classList?.add('far');
  element[0]?.classList?.add('fa-angle-down');
  element[0]?.classList?.remove('anticon');
  element[0]?.classList?.remove('anticon-down');
  element[0]?.classList?.remove('ant-select-suffix');

  const getOnCell = useCallback(
    (col: any) => (record: object) => ({
      record,
      dataIndex: col.dataIndex,
      title: col.title,
      linkProps: linkProps,
    }),
    [linkProps]
  );

  const parsedColumns = useMemo(
    () =>
      columns.map((col: any) => {
        if (components) {
          return { ...col };
        }

        return {
          ...col,
          onCell: getOnCell(col),
        };
      }),
    [columns, components, getOnCell]
  );

  const onTableChange = async (
    { current }: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    { field, order }: SorterResult
  ) => {
    if (!order) {
      field = field || '';
      order = order || null;
    }

    setCurrentPage(current || currentPage);
    !defaultOrder && setOrderBy(order === 'descend' ? 'desc' : 'asc');
    !defaultSort && setSortBy(field);
  };

  const rowSelection = {
    preserveSelectedRowKeys: false,
    onChange: (selectedRowKeys: React.Key[], selectedRows: object) => {
      setSelectedRows && setSelectedRows(selectedRows);
      setSelectedKeys && setSelectedKeys(selectedRowKeys);
    },
  };

  return (
    <AntdTable
      locale={locale}
      className={className}
      loading={isLoading || loading}
      rowKey={rowKey}
      columns={parsedColumns}
      dataSource={innerData}
      size={size}
      pagination={
        !disablePagination &&
        localPagination.total > 10 && {
          ...pagination,
          ...localPagination,
          ...{ pageSize: page_size },
        }
      }
      rowClassName={rowClassName}
      onRow={onRow}
      onChange={onChange || onTableChange}
      components={{
        body: {
          cell: EditableCell,
        },
        ...components,
      }}
      showSorterTooltip={false}
      scroll={scroll}
      rowSelection={
        enableSelectedRow ? rowSelectionFunction ?? rowSelection : undefined
      }
    />
  );
};
