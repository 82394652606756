import React from 'react';
import { Select, SelectOption } from 'ui';
import { useIntl } from 'react-intl';
import useQueryApiClient from 'utils/useQueryApiClient';
import { Validations } from 'interfaces';

interface EnumProps {
  id: number;
  value: string;
}

interface EnumSelectProps extends Validations {
  name?: (string | number)[] | string | number;
  mode?: 'multiple' | 'tags';
  noStyle?: boolean;
  label?: string;
  disabled?: boolean;
  code: string;
  limited?: string[];
}

const EnumSelect = ({
  name,
  mode,
  validations,
  noStyle,
  label,
  disabled,
  code,
  limited,
}: EnumSelectProps) => {
  const intl = useIntl();

  const { data } = useQueryApiClient({
    request: {
      url: `api/v2/enums/${code}`,
    },
  });

  return (
    <Select
      mode={mode}
      label={label}
      name={name}
      validations={validations}
      noStyle={noStyle}
      disabled={disabled}
    >
      {data
        .filter((entry: EnumProps) =>
          limited?.length ? limited?.includes(entry.value) : true
        )
        .map((entry: EnumProps) => (
          <SelectOption key={entry.id} value={entry.value}>
            {intl.formatMessage({ id: 'enum.' + entry.id })}
          </SelectOption>
        ))}
    </Select>
  );
};

export default EnumSelect;
