import { StyledBannerWrapper } from './style'
import { BannerProps } from '../../../../types/BannerData'
import { BannersGallery } from '../../../../ui/Carousel/BannersGallery/BannersGallery'

interface BannerReactProps {
  banners: BannerProps[] | undefined
  className?: string
  full?: boolean
}

const Banner = ({ banners, className, full }: BannerReactProps) => {

  return (
    <StyledBannerWrapper>
      {banners && banners.length > 0
        ? banners.map((singleBanner, index) => (
          <div
            key={index}
            className={
              className ? `banner-wrapper ${className}` : 'banner-wrapper'
            }
          >
            <BannersGallery
              bannerImages={singleBanner.images}
              banner={singleBanner}
              full={full}
            />
          </div>
        ))
        : null}
    </StyledBannerWrapper>
  )
}

export default Banner
