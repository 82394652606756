import { Collapse } from 'antd';
import { CollapseProps } from 'antd/lib';
import { FarmDeliveries } from 'types/FarmData';
import { FC } from 'react';
import { FarmDeliveriesWrapper } from './style';
import WorkingTime from '../WorkingTime';

interface Props {
  daysOfWeek: string[]
  deliveries: FarmDeliveries[] | undefined
}

const openHoursTime = (openHours: string | undefined) =>
  openHours ? JSON.parse(openHours) : null

const transformAddress = (address: string | null | undefined): string => {
  if (!address) return ''
  else if (address.slice(address.length - 2).includes('.,')) return address
  else return `${address}.,`
}

const Deliveries: FC<Props> = ({ daysOfWeek, deliveries }) => {
  const items: CollapseProps['items'] = deliveries
    ? deliveries.map((delivery) => ({
      key: delivery.id,
      label: delivery.name,
      children: (
        <>
          <WorkingTime
            workingTime={openHoursTime(delivery.open_hours)}
            workingTimeTitle={`${transformAddress(delivery.address)} ${delivery.phone ? delivery.phone : ''}`}
            daysOfWeek={daysOfWeek}
          />
        </>
      ),
    }))
    : [];

  return (
    <FarmDeliveriesWrapper className="farm-partner">
      <Collapse
        expandIconPosition="end"
        className="custom-collapse"
        items={items}
      />
    </FarmDeliveriesWrapper>
  )
}

export default Deliveries
