import { CategoryData } from '../types/CategoryData'

export function findSelectedCategory(
  categories: CategoryData[],
  categoryId: number
) {
  return (
    categories.find((category) => category.id === Number(categoryId)) ||
    undefined
  )
}

export function getSubcategories(category: CategoryData | undefined) {
  return category?.subcategories || []
}

export function findSelectedSubcategory(
  subcategories: CategoryData[],
  subcategoryId: number
) {
  return (
    subcategories.find((sub) => sub.id === Number(subcategoryId)) || undefined
  )
}

export function getSubSubCategories(subcategory: CategoryData | undefined) {
  return subcategory?.subcategories || []
}
