import { useParams, useNavigate } from 'react-router-dom'
import { useState } from 'react'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { useIntl } from 'react-intl'
import { Tabs } from 'ui'
import { MyOrders } from './MyOrders'
import { Profile } from './Profile'
import { ProfileLayout } from './style'
export const MyProfilePage = () => {
  const navigate = useNavigate()
  const { tab } = useParams<{ tab: string }>()
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: `${window.runConfig.backendUrl}/api/v2/user/order?count=true`,
    },
  })

  const items = [
    {
      key: 'my_orders',
      label: (
        <span className="tab-title">{`${intl.formatMessage({ id: 'general.my_orders' })} (${data})`}</span>
      ),
      children: <MyOrders />,
    },
    {
      key: 'profile_information',
      label: (
        <span className="tab-title">
          {intl.formatMessage({ id: 'general.profile_information' })}
        </span>
      ),
      children: <Profile />,
    },
    {
      key: 'log-out',
      label: (
        <span className="log-out">
          {intl.formatMessage({ id: 'general.log_out' })}
        </span>
      ),
    },
  ]

  const { appendData } = useQueryApiClient({
    request: {
      url: '/api/v2/logout',
      method: 'POST',
    },
    onSuccess: () => (window.location.href = '/'),
  })

  const handleTabClick = (key: string) => {
    if (key === 'log-out') {
      appendData()
    }
    navigate(`/profile/${key}`)
  }

  return (
    <ProfileLayout>
      <h1 className="title">
        {intl.formatMessage({ id: 'profile.my_acount' })}
      </h1>
      <h1>
        <Tabs
          items={items}
          defaultActiveKey={tab}
          onChange={handleTabClick}
          activeKey={tab}
        />
      </h1>
    </ProfileLayout>
  )
}
