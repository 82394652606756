import styled from 'styled-components';

export const StyledBanner = styled.div`
  min-height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledBannerSlider = styled.div`
  min-height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
    height: auto;
  }
`;

export const StyledBannerWrapper = styled.div`
  .banner_1 {
    @media only screen and (max-width: 440px) {
      display: none;
    }

    .banner-image-wrapper {
      padding: 0;
    }
  }

  .banner-wrapper {
    padding: 0 20px;
  }

  .desktop-banner {
    @media only screen and (max-width: 440px) {
      display: none;
    }
  }

  .mobile-banner {
    @media only screen and (min-width: 440px) {
      display: none;
    }
  }
`;
