import styled from 'styled-components'

interface FarmGaleriesWrapperProps {}

export const FarmPartnersWrapper = styled.div<FarmGaleriesWrapperProps>`
  @media (max-width: 440px) {
    padding-top: 23px;
    padding-bottom: 20px;
  }

  .mobile-farm-partners {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 19px;

    @media (max-width: 440px) {
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: auto;
      gap: 20px;
    }

    .ant-image {
      display: none;

      @media (max-width: 440px) {
        display: block;
      }
    }

    .partner-image {
      border: 1px solid #e1e1e1;
      border-radius: 5px;

      @media (max-width: 440px) {
        display: block;
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .partner-item:nth-child(odd) .partner-name {
      background-color: #f5f5f5;
    }

    .partner-item:nth-child(even) .partner-name {
      background-color: #ffffff;

      @media (max-width: 440px) {
        background-color: #f5f5f5;
      }
    }

    .partner-name {
      display: block;
      padding: 8px 10px;
      font-size: 16px;
      font-weight: 500;
      color: #2a1a1a;

      @media (max-width: 440px) {
        margin-top: 10px;
      }
    }
  }
`
