import { StyledLoginPage } from './style'
import { RegisterForm } from '../../../ui/Form/auth/RegisterForm'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'

export const Register = () => {
  const navigate = useNavigate()
  const [errorData, setErrorData] = useState(null)
  const { appendData } = useQueryApiClient({
    request: {
      url: 'api/v2/register',
      method: 'POST',
    },
    onSuccess: (response) => {
      if (response) {
        navigate('/confirmation')
      }
    },
    onError: (error) => {
      setErrorData(error)
    },
  })

  return (
    <StyledLoginPage>
      <div className={'content'}>
        <RegisterForm appendData={appendData} errorData={errorData} />
      </div>
    </StyledLoginPage>
  )
}
