import { useIntl } from 'react-intl'
import { StyledSuccessfulRegistrationPageWrapper } from './style'
import { useNavigate } from 'react-router-dom'
import { Button } from 'ui'

export const SuccessfulRegistrationPage = () => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <StyledSuccessfulRegistrationPageWrapper>
      <div className={'content'}>
        <div className="content_inner">
          <div className="box">
            <h2 className={'title'}>
              {intl.formatMessage({ id: 'successful_registration.title' })}
            </h2>
            <p className="text">
              {intl.formatMessage({ id: 'successful_registration.title' })}
            </p>
            <div className="btns">
              <Button
                type="text"
                className="btn "
                label={intl.formatMessage({
                  id: 'successful_registration.main_btn',
                })}
                onClick={() => navigate('/')}
              />
              <Button
                type="text"
                className="btn add-business"
                label={intl.formatMessage({
                  id: 'successful_registration.add_business',
                })}
                onClick={() => navigate('/registration-company')}
              />
            </div>
          </div>
        </div>
      </div>
    </StyledSuccessfulRegistrationPageWrapper>
  )
}
