import React, { useCallback, useEffect, useRef, useState } from 'react'
import { StyledGroupedFarmCart } from './style'
import { CartCard } from 'ui/Card/CartCard'
import { useIntl } from 'react-intl'
import { Button, Drawer, Icon, Modal } from 'ui'
import { notification } from 'antd'
import {
  FarmProduct,
  useCartDispatch,
  useCartState,
} from 'public/context/CartContext'
import DeliveryForm from '../DeliveryForm/DeliveryForm'
import { Form } from 'antd'
import useQueryApiClient from 'utils/useQueryApiClient'
import { StyledDeliveryForm } from '../DeliveryForm/style'
import { StyledDeliveryDrawerFooter } from 'ui/Drawer/Drawers/style'
import useWindowSize from 'utils/useWindowSize'
import { StyledCartDeliveryModal } from 'ui/Modal/style'
interface GroupedFarmCartProps {
  farmProducts: FarmProduct[]
  isView?: boolean | undefined
}

const GroupedFarmCart = ({ farmProducts, isView }: GroupedFarmCartProps) => {
  const intl = useIntl()
  const [isCartDrawerOpen, setIsCartDrawerOpen] = useState<boolean>(false)
  const dispatchCart = useCartDispatch()
  const { priceTotal } = useCartState()
  const [_, innerWidth] = useWindowSize()
  const productIds: number[] = []
  const [notificationApi, contextHolder] = notification.useNotification()

  const [deliveryForm] = Form.useForm()

  const deliverySubmitCallbacks = useRef<(() => void)[]>([])

  const { data } = useQueryApiClient({
    request: {
      url: `api/v2/products/cart/refetch`,
      method: 'POST',
      data: {
        farmProducts: farmProducts,
        priceTotal: priceTotal,
      },
      enableOnMount: true,
    },
    onSuccess: (response) => {
      if (response.cartUpdated) openNotificationWithIcon()
      dispatchCart({
        type: 'REFETCH_CART_PRODUCTS',
        farmProducts: response.farmProducts,
        priceTotal: response.priceTotal,
      })
    },
  })

  farmProducts.forEach((farmProduct) => {
    farmProduct.products.forEach((cartProduct) => {
      if (cartProduct.cartProduct?.id !== undefined) {
        productIds.push(cartProduct.cartProduct.id)
      }
    })
  })

  const noCloseDeliveryDrawer = () => {
    deliverySubmitCallbacks.current.forEach((callback) => callback())
    setIsCartDrawerOpen(false)
  }

  const registerSubmitCallback = (submitCallback: () => void) => {
    deliverySubmitCallbacks.current.push(submitCallback)
  }

  const closeForm = () => {
    noCloseDeliveryDrawer()
  }

  const openNotificationWithIcon = () => {
    notificationApi['warning']({
      message: intl.formatMessage({ id: 'cart.updated_message' }),
      description: intl.formatMessage({ id: 'cart.updated_description' }),
      showProgress: true,
      pauseOnHover: true,
      duration: 10,
    })
  }

  const submitDeliveryFooter: JSX.Element = (
    <StyledDeliveryDrawerFooter>
      <Button
        className={
          innerWidth < 567
            ? 'delivery-drawer-btn' + ' mobile'
            : 'delivery-drawer-btn'
        }
        label={
          innerWidth < 567
            ? intl.formatMessage({ id: 'general.add_delivery' })
            : intl.formatMessage({ id: 'general.choose_delivery' })
        }
        type="primary"
        onClick={closeForm}
        icon={innerWidth < 567 ? <Icon name={'arrow-right-red'} /> : null}
      />
    </StyledDeliveryDrawerFooter>
  )

  return (
    <StyledGroupedFarmCart>
      {contextHolder}
      <div className="cart-products-container">
        {farmProducts.map((farmGroup) => (
          <>
            <div className={'cart-products-farm-title'}>
              {farmGroup.farm_name}
            </div>
            <div className="cart-products-farm-wrapper" key={farmGroup.farm_id}>
              <div className="cart-products-header">
                <div className="cart-cell-1">
                  {intl.formatMessage({ id: 'cart.products' })}
                </div>
                <div className="cart-cell-2">
                  {intl.formatMessage({ id: 'delivery.price' })}
                </div>
                <div className="cart-cell-3">
                  {intl.formatMessage({ id: 'cart.amount' })}
                </div>
                <div className="cart-cell-4">
                  {intl.formatMessage({ id: 'cart.totlal' })}
                </div>
              </div>
              <div className="cart-products-content-wrapper">
                {farmGroup.products.map((product) => (
                  <div
                    className="cart-product show-sum-card"
                    key={product.cartProduct?.id}
                  >
                    <h3 className="title">{product.cartProduct?.farm_name}</h3>
                    <CartCard product={product} isView={isView} />
                  </div>
                ))}
                <div className="cart-delivery-container">
                  {farmGroup.selectedDelivery ? (
                    <div className="cart-delivery-info">
                      {farmGroup.selectedDelivery.firmName !==
                      intl.formatMessage({ id: 'process_purchase.self' }) ? (
                        <>
                          <div>
                            {`${farmGroup.selectedDelivery.firmName}${
                              farmGroup.selectedDelivery.price
                                ? ` - ${farmGroup.selectedDelivery.price.toFixed(2)} €`
                                : ''
                            }`}
                          </div>
                          <div>{farmGroup.selectedDelivery.name}</div>
                        </>
                      ) : (
                        <>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_first_part',
                            })}
                          </div>
                          <div>
                            {intl.formatMessage({
                              id: 'process_purchase.buy_locally_placeholder_second_part',
                            })}
                          </div>
                        </>
                      )}
                    </div>
                  ) : (
                    <div className="cart-delivery-info" />
                  )}
                  {!isView && (
                    <div
                      className={
                        farmGroup.selectedDelivery
                          ? 'cart-delivery-button choosen'
                          : 'cart-delivery-button'
                      }
                    >
                      <Button
                        icon={
                          farmGroup.selectedDelivery ? (
                            <Icon name={'car-delivery-black'} />
                          ) : (
                            <Icon name={'car-delivery'} />
                          )
                        }
                        type={farmGroup.selectedDelivery ? 'text' : 'primary'}
                        label={
                          farmGroup.selectedDelivery
                            ? intl.formatMessage({
                                id: 'cart_summary.change_delivery',
                              })
                            : intl.formatMessage({
                                id: 'general.choose_delivery',
                              })
                        }
                        onClick={() => setIsCartDrawerOpen(true)}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        ))}
      </div>

      {innerWidth > 1200 ? (
        <Drawer
          id={'delivery-drawer'}
          width={512}
          open={isCartDrawerOpen}
          onClose={noCloseDeliveryDrawer}
          title={intl.formatMessage({ id: 'general.choose_delivery' })}
          footer={submitDeliveryFooter}
        >
          <StyledDeliveryForm>
            <Form form={deliveryForm}>
              {farmProducts.map((farmProduct, index) => (
                <DeliveryForm
                  deliveryForm={deliveryForm}
                  farmProduct={farmProduct}
                  key={index}
                  registerSubmitCallback={registerSubmitCallback}
                />
              ))}
            </Form>
          </StyledDeliveryForm>
        </Drawer>
      ) : (
        <StyledCartDeliveryModal
          open={isCartDrawerOpen}
          closable={true}
          onCancel={noCloseDeliveryDrawer}
          footer={submitDeliveryFooter}
        >
          <StyledDeliveryForm>
            <Form form={deliveryForm}>
              {farmProducts.map((farmProduct, index) => (
                <DeliveryForm
                  deliveryForm={deliveryForm}
                  farmProduct={farmProduct}
                  key={index}
                  registerSubmitCallback={registerSubmitCallback}
                />
              ))}
            </Form>
          </StyledDeliveryForm>
        </StyledCartDeliveryModal>
      )}
    </StyledGroupedFarmCart>
  )
}

export default GroupedFarmCart
