import { StyledNewProductForm } from './style'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import { InputNumber } from '../../InputNumber'
import { Form } from 'antd'
import { useForm } from 'antd/lib/form/Form'

export const NewProductMacros = () => {
  const intl = useIntl()
  const [form] = useForm()

  return (
    <StyledNewProductForm>
      <div className={'ingridients'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'macros.ingredients' })}
        </div>
        <Input
          name={'ingredients'}
          placeholder={intl.formatMessage({
            id: 'general.please_add_ingredients',
          })}
          initialValue={''}
          validations={'requiredIfNoNumberValuesAdded'}
        />
      </div>

      <div className={'macros-wrap'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'macros.title' })}
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.kcal' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'kcal']}
            />
          </div>
          <div className={'fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.fat' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'fat']}
            />
          </div>
          <div className={'saturated-fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.saturated_fat' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'saturated_fat']}
            />
          </div>
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.carbohydrates' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'carbohydrates']}
            />
          </div>
          <div className={'fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.sugars' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'sugars']}
            />
          </div>
          <div className={'saturated-fat'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.protein' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'proteins']}
            />
          </div>
        </div>
        <div className={'macros col-djfgr'}>
          <div className={'kcal'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'macros.salt' })}
            </div>
            <InputNumber
              className={'flex'}
              validations={'requiredIfNoNumberValuesAdded'}
              name={['nutritional_values', 'salt']}
            />
          </div>
        </div>

        <div className={'allergens'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'macros.allergens' })}
          </div>
          <Input style={{ minHeight: 100 }} name={'allergens'} />
        </div>

        <div className={'conditions'}>
          <div className={'label'}>
            {intl.formatMessage({ id: 'macros.storing_conditions' })}
          </div>
          <Input style={{ minHeight: 100 }} name={'storage_conditions'} />
        </div>
      </div>
    </StyledNewProductForm>
  )
}
