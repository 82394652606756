import React from 'react'
import { StyledHeader } from './style'
import { Menu } from 'antd'
import { useIntl } from 'react-intl'
import { Button, Icon } from '../../../../ui'
import { useNavigate } from 'react-router-dom'

const DefaultHeader = () => {
  const intl = useIntl()
  const logoSrc = 'NG-peleks-1.png'

  const navigate = useNavigate()

  const handleNavigateMainPage = () => {
    navigate(`/`)
  }

  const items = [
    {
      key: 'main',
      label: intl.formatMessage({ id: 'navigation.main' }),
    },
    {
      key: 'current_events',
      label: intl.formatMessage({ id: 'navigation.current_events' }),
    },
    {
      key: 'contests',
      label: intl.formatMessage({ id: 'navigation.contests' }),
    },
    {
      key: 'recipes',
      label: intl.formatMessage({ id: 'navigation.recipes' }),
    },
    {
      key: 'events',
      label: intl.formatMessage({ id: 'navigation.events' }),
    },
    {
      key: 'contacts',
      label: intl.formatMessage({ id: 'navigation.contacts' }),
    },
  ]

  return (
    <StyledHeader>
      <div className="container">
        <div className="content">
          <div className="left-side-header">
            <a onClick={() => handleNavigateMainPage()}>
              <Icon name={logoSrc} type={'large'} svg={false} />
            </a>
          </div>
          <div className="right-side-header">
            <Menu
              mode="horizontal"
              defaultSelectedKeys={['2']}
              items={items}
              style={{ flex: 1, minWidth: 0 }}
            />
            <Button
              type="primary"
              label={intl.formatMessage({ id: 'general.e-market' })}
              size={'small'}
            />
          </div>
        </div>
      </div>
    </StyledHeader>
  )
}

export default DefaultHeader
