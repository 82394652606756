import React, { CSSProperties } from 'react'
import { Form, Input as AntdInput } from 'antd'
import { Rule } from 'rc-field-form/lib/interface'
import useFormValidation from '../../utils/useFormValidation'
import { Validations } from '../../interfaces'

export interface InputProps extends Validations {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: (event: React.ChangeEvent<HTMLInputElement>) => void
  placeholder?: string
  required?: boolean
  prefix?: React.ReactNode
  suffix?: React.ReactNode
  type?: string
  size?: 'large' | 'middle' | 'small'
  value?: string | number
  defaultValue?: string
  maxLength?: number
  allowClear?: boolean
  label?: string
  rules?: Rule[]
  name?: (string | number)[] | string | number
  className?: string
  validateTrigger?: string | string[]
  noStyle?: boolean
  initialValue?: string
  disabled?: boolean
  addonAfter?: string | React.ReactNode
  password?: boolean
  style?: CSSProperties
  dependencies?: string[]
  hidden?: boolean
  readOnly?: any
}

export const Input = ({
  disabled = false,
  placeholder,
  name,
  required,
  prefix,
  suffix,
  type,
  onChange,
  size = 'large',
  value,
  onBlur,
  style,
  defaultValue,
  maxLength = 255,
  allowClear,
  label,
  rules,
  className,
  validateTrigger,
  noStyle,
  initialValue,
  addonAfter,
  validations,
  password,
  dependencies,
  hidden,
  readOnly = false
}: InputProps) => {
  const { formValidations } = useFormValidation()

  return (
    <Form.Item
      initialValue={initialValue}
      label={label}
      name={name}
      rules={validations ? formValidations(validations) : rules}
      className={className}
      validateTrigger={validateTrigger}
      noStyle={noStyle}
      dependencies={dependencies}
      hidden={hidden}
    >
      {password ? (
        <AntdInput.Password
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          prefix={prefix}
          suffix={suffix}
          type={type}
          onChange={onChange}
          size={size}
          value={value}
          onBlur={onBlur}
          defaultValue={defaultValue}
          maxLength={maxLength}
          allowClear={allowClear}
          addonAfter={addonAfter}
        />
      ) : (
        <AntdInput
          disabled={disabled}
          placeholder={placeholder}
          required={required}
          prefix={prefix}
          suffix={suffix}
          type={type}
          onChange={onChange}
          size={size}
          value={value}
          onBlur={onBlur}
          defaultValue={defaultValue}
          maxLength={maxLength}
          allowClear={allowClear}
          addonAfter={addonAfter}
          readOnly={readOnly}
        />
      )}
    </Form.Item>
  )
}
