import React from 'react'
import DefaultLayout from 'admin/components/DefaultLayout'
import { useIntl } from 'react-intl'
import {Tabs} from 'ui'
import useQueryApiClient from 'utils/useQueryApiClient'

const FarmEmailListPage = () => {
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: `/api/v2/listofemails`,
    },
  })

    const items = [
        {
            key: 'farm_users',
            label: intl.formatMessage({ id: 'navigation.farm_email' }),
            children: (
                <>
                    {!!data.length &&
                        data.map((item: any) => item.contact_email).join(', ')}
                </>
            ),
        },
    ]

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.farm_email' }),
        },
      ]}
    >
      <DefaultLayout.PageContent>
          <Tabs
            items={items}
          />
      </DefaultLayout.PageContent>
    </DefaultLayout.PageLayout>
  )
}

export default FarmEmailListPage
