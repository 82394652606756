import { ImageProps } from '../../../types/BannerData'
import { StyledBannerImagePreview } from './style'
import { FallbackImage } from '../fallBack/FallBackImage'
import { useIntl } from 'react-intl'
import React from 'react'

interface PreviewProps {
  images: ImageProps[]
  type: 'banner_1' | 'banner_2' | 'banner_3' | 'banner_4' | undefined
  tempImage?: string | null
}

export const Preview = ({ images, type, tempImage }: PreviewProps) => {
  const intl = useIntl()

  if (tempImage) {
    return (
      <StyledBannerImagePreview>
        <FallbackImage src={tempImage} type={type} />
      </StyledBannerImagePreview>
    )
  }

  return (
    <StyledBannerImagePreview>
      {images.length >= 1 ? (
        images.map((image, index) => {
          return (
            <div key={index}>
              <FallbackImage src={`/${image.url_original}`} type={type} />
            </div>
          )
        })
      ) : (
        <div>
          <FallbackImage src={''} type={type} />
        </div>
      )}
    </StyledBannerImagePreview>
  )
}
