import styled from 'styled-components';
import { Form } from 'antd';

export const StyledProductsTopFilter = styled(Form)`
  font-family: ${(props) => props.theme.fontText};
  display: flex;
  flex-direction: row;
  padding-right: 0px;
  align-items: center;
  font-size: 14px;
  font-weight: 400;

  .icon {
    height: 24px;

    .ant-select-selector {
      padding-left: 0 !important;
    }
  }

  .icon .ant-select-selection-item {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }

  .icon .ant-form-item-control-input-content {
    padding-left: 4px;
  }

  .icon .ant-form-item {
    padding-bottom: 0;
  }

  .br-r {
    border-right: 1px solid ${(props) => props.theme.borderBottom};
    padding-right: 20px;
    padding-left: 20px;
  }

  .catalogue-num {
    padding-left: 0;
  }

  .sz-sm {
    font-size: 14px;
  }

  .d-f-r {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  .show-in-map {
    padding-left: 20px;
    text-decoration: underline;
  }

  .ant-select-selector {
    padding-left: 5px;
  }

  .ant-select-selection-item {
    font-size: 14px;
    font-weight: 400;
  }

  .ant-select-arrow {
    height: 11px;
  }

  .filter-by .ant-select-selection-item {
    display: none !important;
  }
`;
