import styled from 'styled-components';

export const StyledAllNewProducts = styled('div')`
  .product-name-and-qmarks-container {
    padding-top: 25px !important;

    .product-name {
      margin-top: 0 !important;
    }
  }

  .ant-pagination-item {
    margin-right: 0;
  }

  .ant-pagination-prev {
    margin-right: 24px;
  }

  .ant-pagination-next {
    margin-left: 24px;
  }

  .discount-items {
    display: none;
  }

  .banners {
    padding-bottom: 60px;
    background-color: ${({ theme }) => theme.white};

    .slick-slider {
      margin-bottom: 0;

      .banner-image-wrapper {
        padding: 0;
      }
    }

    .slick-dots-bottom {
      bottom: -20px;
    }

    .banner-wrapper {
      padding: 21px 0;
    }
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      .products-wrapper {
        padding-right: 0px !important;
      }
    }
    .form-container {
      display: none !important;
    }
    .products-wrapper {
      padding-right: 0 !important;
      .products {
        padding: 0 !important;
        justify-content: center !important;
      }
      .category_name {
        text-align: center;
        display: block !important;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }

  .popular-categories {
    padding-bottom: 75px;
    padding-top: 84px;
  }

  .ant-pagination-prev {
    margin-inline-end: 24px !important;
  }

  .ant-pagination-next {
    margin-inline-start: 24px !important;
  }

  .ant-pagination-item {
    margin-right: 0;
  }

  @media only screen and (max-width: 440px) {
    .products-container {
      background-color: ${({ theme }) => theme.inputGrey};
    }

    .popular-categories {
      padding-bottom: 0;
      padding-top: 0;
    }

    .mobile-product-category {
      background-color: ${({ theme }) => theme.brightGrey};
    }

    .discount-items {
      display: block;

      .slick-dots {
        padding-top: 35px;
      }

      .view-all-items-button {
        margin-top: 10px;
        padding-bottom: 20px;
      }

      .custom-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .ant-card-body .price-quality-marks .farm-name-container-mobile {
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .banners {
      padding-top: 25px;
      padding-bottom: 0px;
      background-color: ${({ theme }) => theme.brightGrey};

      .desktop-banner {
        display: block;
      }
      .slick-dots {
        display: none !important;
      }

      .banner-wrapper {
        padding-bottom: 0;
      }
    }

    .ant-pagination-prev {
      margin-inline-end: 6px !important;
    }

    .ant-pagination-next {
      margin-inline-start: 6px !important;
    }

    .products-filters-container {
      .products-wrapper {
        .products {
          justify-content: center !important;
          padding: 0 10px !important;

          .ant-card-body {
            gap: 5px;
          }

          .ant-image {
            height: 100%;
          }

          .ant-image-img {
            height: 100%;
            min-height: 136px;
          }

          .custom-title {
            font-size: 16px;
            font-weight: 500;
            line-height: 18px;
            display: -webkit-box !important;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .farm-name-container-mobile {
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .price-quality-marks {
            margin-bottom: 0;
            gap: 5px;
          }

          .add-to-cart-container {
            padding-top: 5px;
          }

          .price-container-quality-marks-wraper {
            height: 19px;
          }

          .price-quality-marks
            .price-container-quality-marks-wraper
            .price-container
            .package-price,
          .price-grey {
            display: none !important;
          }
        }

        .category_name {
          text-align: start;
          padding: 0 20px;
        }
      }
    }

    .ant-pagination-prev {
      margin-right: 6px;
    }

    .ant-pagination-next {
      margin-left: 6px;
    }
  }

  .products-filters-container {
    display: flex;
    flex-direction: row;

    .ant-spin-nested-loading {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .products-wrapper {
      .products {
        display: flex;
        flex-wrap: wrap;
        padding-top: 40px;
        justify-content: end;
      }

      .pagination {
        display: flex;
        justify-content: center;
        padding: 50px 0;
      }
    }
  }
`;
