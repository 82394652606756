import { useEffect } from 'react'

const CookieConsent = () => {
  useEffect(() => {
    if (!document.getElementById('cookieyes')) {
      const script = document.createElement('script')
      script.id = 'cookieyes'
      script.type = 'text/javascript'
      script.src =
        'https://cdn-cookieyes.com/client_data/f3d24eb9689fe11cacd329d5/script.js'
      script.async = true
      document.head.appendChild(script)
    }

    return () => {
      const existingScript = document.getElementById('cookieyes')
      if (existingScript) {
        document.head.removeChild(existingScript)
      }
    }
  }, [])

  return null
}

export default CookieConsent
