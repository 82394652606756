import { StyledLoginForm } from './style'
import { Input } from '../../Input'
import { Button } from '../../Button'
import { useIntl } from 'react-intl'

export interface CompanyRegistrationFormProps {
  appendData?: (data: any) => void
  isLoading?: boolean
}

export const CompanyRegistrationForm = ({
  appendData,
  isLoading,
}: CompanyRegistrationFormProps) => {
  const intl = useIntl()

  const handleSubmit = (values: any) => {
    if (appendData) {
      appendData(values)
    }
  }

  const [form] = StyledLoginForm.useForm()

  return (
    <StyledLoginForm form={form} onFinish={handleSubmit}>
      <div className={'inputs'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'registration_company.title' })}
        </div>
        <p className="description">
          {intl.formatMessage({ id: 'registration_company.text' })}
        </p>
        <div className={'pw'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'registration_company.company_name' })}
          </span>
          <Input
            size={'large'}
            name={'company_name'}
            validations={['required']}
            placeholder="**********"
          />
        </div>
        <div className={'pw'}>
          <span className="label">
            {' '}
            {intl.formatMessage({
              id: 'registration_company.registration_number',
            })}
          </span>
          <Input
            size={'large'}
            name={'registration_number'}
            validations={['required']}
            placeholder="**********"
          />
        </div>
        <div className={'pw'}>
          <span className="label">
            {' '}
            {intl.formatMessage({ id: 'registration_company.PVD_number' })}{' '}
          </span>
          <Input
            password
            size={'large'}
            name={'PVD_number'}
            validations={'required'}
            placeholder="**********"
          />
        </div>

        <Button
          className={'cstm-btn submit'}
          label={intl.formatMessage({
            id: 'registration_company.create_account',
          })}
          size={'large'}
          htmlType={'submit'}
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </StyledLoginForm>
  )
}
