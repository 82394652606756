import { Carousel } from '../../../../ui';
import { useIntl } from 'react-intl';
import { StyledMobileProductCategoriesCarousel } from './style';
import { CategoryData } from 'types/CategoryData';

interface MobileProductCategoriesCarouselProps {
  popularCategory?: CategoryData[];
}

const MobileProductCategoriesCarousel = ({
  popularCategory,
}: MobileProductCategoriesCarouselProps) => {
  const intl = useIntl();

  return (
    <StyledMobileProductCategoriesCarousel className="mobile-product-category">
      <div className={'title'}>
        <h1>{intl.formatMessage({ id: 'popular_categories.title' })}</h1>
        <Carousel popularCategory={popularCategory} autoplay={true} />
      </div>
    </StyledMobileProductCategoriesCarousel>
  );
};

export default MobileProductCategoriesCarousel;
