import { StyledThirdHeader } from './style'
import React from 'react'
import { BreadCrumbData, QualityMarkData } from '../../../../types/ProductData'

export interface ThirdHeaderProps {
  label: string
  breadcrumb?: BreadCrumbData[]
  qmarks?: QualityMarkData[]
  unitType?: React.ReactNode
  filters?: React.ReactNode
}

export const DefaultThirdHeader = ({
  label,
  breadcrumb,
  qmarks,
  unitType,
  filters,
}: ThirdHeaderProps) => {
  return (
    <StyledThirdHeader>
      <div className={'product-name-bread-wrapper d-flex flex-col'}>
        <div className="container">
          <div className={'product-name-and-qmarks-container d-flex flex-row'}>
            <div className={'product-name'}>
              {label}
              {unitType && unitType}
            </div>
            <div className={'qmarks'}>
              {qmarks?.map((qmark) => (
                <img
                  alt={qmark.name}
                  src={qmark.logo}
                  key={qmark.slug}
                  title={qmark.description}
                />
              ))}
            </div>
          </div>
          <div className={'breadcrumbs-container'}>
            <div>
              {breadcrumb?.map((bread, index) => (
                <span key={index}>
                  <a href={bread.route}>{bread.name}</a>
                  {index < breadcrumb.length - 1 && (
                    <span className="breadcrumbs-icon">/</span>
                  )}
                </span>
              ))}
            </div>
            <div className={'filters-top-third'}>{filters}</div>
          </div>
        </div>
      </div>
    </StyledThirdHeader>
  )
}
