import { StyledNewProductForm } from './style'
import { Input } from '../../Input'
import { useIntl } from 'react-intl'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { InputNumber } from '../../InputNumber'
import { Checkbox, Form } from 'antd'
import { useEffect, useState } from 'react'
import { CategoryOption } from '../../Drawer/Drawers/DrawerCreateProduct'

export interface QmarkResponse {
  id: number
  label: string
}
interface OptionsResponse {
  id: number
  label: string
}

interface NewProductBaseInfoFormProps {
  optionsInput?: CategoryOption[]
}

export const NewProductBaseInfoForm = ({
  optionsInput,
}: NewProductBaseInfoFormProps) => {
  const [options, setOptions] = useState([])
  const [qmarks, setQmarks] = useState([])
  const intl = useIntl()

  const { data } = useQueryApiClient({
    request: {
      url: 'api/v2/products/store/options',
      data: {
        view: 'admin',
      },
    },
    onSuccess: (response) => {
      setOptions(response.options)
      setQmarks(response.qmarks)
    },
  })

  useEffect(() => {}, [optionsInput])

  return (
    <StyledNewProductForm>
      <div className={'availability'}>
        <div className={'label'}>
          {intl.formatMessage({ id: 'general.availability' })}
        </div>
        <Input name={'availability'} />
      </div>

      <div className={'prod-availability'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.product_viable_in_mass' })}
        </div>
        <div className={'min-price col-djfgr'}>
          <div className={'min'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.min_amount' })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              validations={'required'}
              name={'storage_limit_min'}
              initialValue={0}
            />
          </div>
          <div className={'price'}>
            <div className={'label'}>
              {intl.formatMessage({ id: 'general.bulk_price' })}
            </div>
            <InputNumber
              className={'scnd-tr'}
              validations={'minZeroNumber'}
              name={'storage_wholesale_price'}
              initialValue={0}
            />
          </div>
        </div>
      </div>
      <div className={'additional-info'}>
        <div className={'title'}>
          {intl.formatMessage({ id: 'general.additional_information' })}
        </div>
        <div className={'chkboxes'}>
          <Form.Item name={'qmarks'}>
            <Checkbox.Group>
              {qmarks.map((qmark: QmarkResponse, index: number) => (
                <Checkbox value={qmark.id} name={'qmark'} key={index}>
                  {qmark.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>

          <Form.Item name={'options'}>
            <Checkbox.Group>
              {options.map((option: OptionsResponse, index: number) => (
                <Checkbox value={option.id} name={'option'} key={index}>
                  {option.label}
                </Checkbox>
              ))}
            </Checkbox.Group>
          </Form.Item>
        </div>
      </div>
    </StyledNewProductForm>
  )
}
