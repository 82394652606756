import { StyledMansLauksDrawer } from './style'
import { ProductData } from '../../../types/ProductData'
import { Table } from '../../Table'
import { useIntl } from 'react-intl'
import { Select, SelectOption } from '../../Select'
import { Input } from '../../Input'
import useFormInstance from 'antd/es/form/hooks/useFormInstance'
import { Form, message } from 'antd'
import { useEffect, useState } from 'react'
import { Button } from '../../Button'
import { useForm } from 'antd/lib/form/Form'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import useFormValidation from '../../../utils/useFormValidation'

export interface MansLauksProduct {
  amount: number
  category: string
  id: string
  name: string
  type: string
  unit: string
}

interface props {
  products: ProductData[]
  mansLauksProducts: MansLauksProduct[]
  open: boolean
  onClose?: () => void
}

export const DrawerMansLauksProducts = ({
  products,
  mansLauksProducts,
  open,
  onClose,
}: props) => {
  const [selectedMansLauks, setSelectedMansLauks] = useState<{
    [key: string]: MansLauksProduct | undefined
  }>({})
  const intl = useIntl()
  const [form] = useForm()

  const { nogaAvailabilityNumberCheck } = useFormValidation()

  useEffect(() => {
    if (open) {
      const nextSelected: { [key: number]: MansLauksProduct | undefined } = {}
      const nextFormValues: Record<string, any> = {}

      products.forEach((product) => {
        if (product.manslauks_id) {
          const found = mansLauksProducts.find(
            (mlProd) => mlProd.id === product.manslauks_id
          )
          if (found) {
            nextSelected[product.id] = found

            nextFormValues[`mans_lauks_product_${product.id}`] = found.id
          }

          if (product.availability !== null) {
            nextFormValues[`noga_availability_${product.id}`] =
              product.availability
          }
        }
      })

      setSelectedMansLauks(nextSelected)

      form.setFieldsValue(nextFormValues)
    }
  }, [open, products, mansLauksProducts, form])

  const { appendData } = useQueryApiClient({
    request: {
      url: `/api/v2/products/count`,
      method: 'POST',
    },
    onSuccess: () => {
      return message.success(
        intl.formatMessage({ id: 'message.successful_update' })
      )
    },
  })

  const onSelectMansLauksProduct = (value: string, productId: number) => {
    const selectedProd = mansLauksProducts.find(
      (product) => product.id === value
    )
    setSelectedMansLauks((prev) => ({
      ...prev,
      [productId]: selectedProd,
    }))
  }

  const handleSubmit = async () => {
    try {
      await form.validateFields()
      const values = form.getFieldsValue()

      const updates = products
        .map((product) => {
          const nogaAvailability = values[`noga_availability_${product.id}`]
          if (!nogaAvailability) {
            return null
          }

          const selectedMlProduct = selectedMansLauks[product.id]
          return {
            product_id: product.id,
            manslauks_count: selectedMlProduct?.amount ?? null,
            manslauks_unit: selectedMlProduct?.unit ?? null,
            noga_availability: nogaAvailability,
            noga_unit: values[`noga_unit_${product.id}`],
            manslauks_id: selectedMlProduct?.id,
          }
        })
        .filter(Boolean)

      appendData(updates)
    } catch (err: unknown) {
      message.error(intl.formatMessage({ id: 'message.fix_errors' }))
    }
  }

  const RenderMansLauksSelector = (record: ProductData) => {
    return (
      <Select
        name={`mans_lauks_product_${record.id}`}
        allowClear
        showSearch
        onChange={(value: any) => onSelectMansLauksProduct(value, record.id)}
        value={selectedMansLauks[record.id]?.id}
      >
        {mansLauksProducts.map((prod: MansLauksProduct) => (
          <SelectOption key={prod.id} value={prod.id}>
            {prod.name}
          </SelectOption>
        ))}
      </Select>
    )
  }

  const RenderMansLauksAvailability = (record: MansLauksProduct) => {
    const selectedProd = selectedMansLauks[record.id]
    if (!selectedProd) return null

    return (
      <div className={'unit-value'}>
        <div className={'value'}>{selectedProd.amount}</div>
        <div className={'unit'}>{selectedProd.unit}</div>
      </div>
    )
  }

  const RenderNogaAvailability = (record: MansLauksProduct) => {
    const selectedProd = selectedMansLauks[record.id]
    if (!selectedProd) return null

    return (
      <div className={'availability-unit'}>
        <div>
          <Input
            name={`noga_availability_${record.id}`}
            type={'number'}
            rules={[{ validator: nogaAvailabilityNumberCheck(selectedProd) }]}
          />
        </div>
        <div>
          <Select name={'noga_unit'} initialValue={selectedProd.unit}>
            <SelectOption>{selectedProd.unit}</SelectOption>
          </Select>
        </div>
      </div>
    )
  }

  const Footer = () => {
    return (
      <Button
        type={'primary'}
        label={intl.formatMessage({ id: 'general.save' })}
        onClick={handleSubmit}
      />
    )
  }

  const columns = [
    {
      title: intl.formatMessage({ id: 'general.noga_products' }),
      dataIndex: 'name',
      render: (value: string) => value,
    },
    {
      title: intl.formatMessage({ id: 'general.mans_lauks_products' }),
      render: (record: ProductData) => RenderMansLauksSelector(record),
    },
    {
      title: intl.formatMessage({ id: 'general.mans_lauks_availability' }),
      render: (record: MansLauksProduct) => RenderMansLauksAvailability(record),
    },
    {
      title: intl.formatMessage({ id: 'general.noga_availability' }),
      render: (record: MansLauksProduct) => RenderNogaAvailability(record),
    },
  ]

  return (
    <StyledMansLauksDrawer
      open={open}
      onClose={onClose}
      width={1000}
      footer={<Footer />}
    >
      <Form form={form}>
        <Table columns={columns} dataSource={products} />
      </Form>
    </StyledMansLauksDrawer>
  )
}
