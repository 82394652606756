import { StyledMobileFilterForm } from './style';
import { CategorySelector } from '../../Select/Selectors/CategorySelectors';
import { QualityMarkCheckboxes } from '../../Checkbox/Checkboxes/QualityMarkCheckboxes';
import { AdditionalOptionsCheckboxes } from '../../Checkbox/Checkboxes/AdditionalOptionsCheckboxes';
import { DeliveriesCheckboxes } from '../../Checkbox/Checkboxes/DeliveriesCheckboxes';
import { useEffect } from 'react';
import { Filter } from 'types/Filter';
import { FilterOptions } from 'types/FilterOptions';
import { Link } from 'react-router-dom';
import RoutesPath from 'enums/routes';
import { LocationCheckboxes } from 'ui/Checkbox/Checkboxes/LocationCheckboxes';
import { FarmsCheckboxes } from 'ui/Checkbox/Checkboxes/FarmsCheckboxes';
import { useIntl } from 'react-intl';

interface Props {
  setFilter: (newFilter: Filter) => void;
  filterOptions: FilterOptions;
  filter: Filter;
}
export const MobileFilterForm = ({
  setFilter,
  filterOptions,
  filter,
}: Props) => {
  const [form] = StyledMobileFilterForm.useForm();
  const intl = useIntl();

  useEffect(() => {
    if (!!filter) {
      form.setFieldsValue(filter);
    }
  }, [form, filter]);

  return (
    <div className={'form-container'}>
      <StyledMobileFilterForm
        layout={'vertical'}
        onFieldsChange={() => setFilter(form.getFieldsValue())}
        form={form}
      >
        <div className={'categories-form'}>
          <CategorySelector />
          <div className="products-navigate-list">
            <Link to={RoutesPath.PRODUCTS_ALL_DISCOUNTED}>
              {intl.formatMessage({ id: 'general.sales' })}
            </Link>
            <Link to={RoutesPath.PRODUCTS_ALL_DISCOUNTED}>
              {intl.formatMessage({ id: 'general.news' })}
            </Link>
          </div>
          <FarmsCheckboxes farms={filterOptions?.farms} />
          <LocationCheckboxes locations={filterOptions?.locations} />
          <QualityMarkCheckboxes qmarks={filterOptions?.qmark} />
          <AdditionalOptionsCheckboxes services={filterOptions?.services} />
          <DeliveriesCheckboxes delivery={filterOptions?.delivery} />
        </div>
      </StyledMobileFilterForm>
    </div>
  );
};
