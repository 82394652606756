import { Button, Spinner, Table } from '../../../ui'
import React, { useCallback, useMemo, useState } from 'react'
import { useIntl } from 'react-intl'
import DefaultLayout from '../../components/DefaultLayout'
import { StyledBannersPage } from './style'
import { EditBannerDrawer } from '../../../ui/Drawer/Drawers/EditBannerDrawer'
import { BannerProps, ImageProps } from '../../../types/BannerData'
import { message } from 'antd'
import useQueryApiClient from '../../../utils/useQueryApiClient'
import { StyledImagePreviews } from '../../../ui/UploadImage/products/style'
import { FallbackImage } from '../../../ui/UploadImage/fallBack/FallBackImage'
import dayjs from 'dayjs'

export const BannersPage = () => {
  const [isOpenDrawer, setIsOpenDrawer] = useState<boolean>(false)
  const [banner, setBanner] = useState<BannerProps | null>(null)
  const [banners, setBanners] = useState<BannerProps[] | null>(null)
  const [updateSuccess, setUpdateSuccess] = useState(false)

  const intl = useIntl()

  const { appendData: updateBanner, isLoading } = useQueryApiClient({
    request: {
      url: `/api/v2/banners/update/${banner?.id}`,
      method: 'POST',
      multipart: true,
    },
    onSuccess: async (updatedBanner) => {
      if (updatedBanner instanceof Blob) {
        const textData = await updatedBanner.text()
        const jsonData = JSON.parse(textData)
        const updatedBannerData = jsonData

        setBanners(
          (prevBanners) =>
            prevBanners &&
            prevBanners.map((b) =>
              b.id === updatedBannerData.id ? { ...b, ...updatedBannerData } : b
            )
        )
        message.success(intl.formatMessage({ id: 'message.updated_banner' }))
      } else {
        const updatedBannerData = updatedBanner

        setBanners(
          (prevBanners) =>
            prevBanners &&
            prevBanners.map((b) =>
              b.id === updatedBannerData.id ? { ...b, ...updatedBannerData } : b
            )
        )
        message.success(intl.formatMessage({ id: 'message.updated_banner' }))
      }

      setUpdateSuccess(true)
      handleClose()
    },
    onError: (response) => {
      message.error(response)
    },
  })

  const { data } = useQueryApiClient({
    request: {
      url: '/api/v2/banners',
      method: 'GET',
    },
    onSuccess: (response) => {
      setBanners(response.data)
    },
  })

  const handleEdit = useCallback((value: BannerProps) => {
    setBanner(value)
    setIsOpenDrawer(true)
  }, [])

  const handleClose = useCallback(() => {
    setBanner(null)
    setIsOpenDrawer(false)
  }, [])

  const Actions = useCallback(
    (record: BannerProps) => (
      <Button
        label={intl.formatMessage({ id: 'general.edit' })}
        onClick={() => handleEdit(record)}
      />
    ),
    [handleEdit, intl]
  )

  const ImagePreviews = useCallback(
    (images: ImageProps[], banner: BannerProps) => (
      <StyledImagePreviews>
        {banner.images.length >= 1 ? (
          images.map((image) => (
            <FallbackImage
              key={image.url_original || image.id}
              src={image.url_original}
              type={banner?.type}
            />
          ))
        ) : (
          <FallbackImage src={''} type={banner.type} />
        )}
      </StyledImagePreviews>
    ),
    []
  )

  const columns = useMemo(
    () => [
      {
        title: intl.formatMessage({ id: 'general.name' }),
        dataIndex: 'title',
        render: (value: string) => value,
      },
      {
        title: intl.formatMessage({ id: 'banners.images' }),
        dataIndex: 'images',
        render: (value: ImageProps[], banner: BannerProps) =>
          ImagePreviews(value, banner),
      },
      {
        title: intl.formatMessage({ id: 'general.updated_at' }),
        dataIndex: 'updated_at',
        render: (value: string) => value && dayjs(value).format('DD.MM.YYYY'),
      },
      {
        title: intl.formatMessage({ id: 'general.actions' }),
        render: (_: any, record: BannerProps) => Actions(record),
      },
    ],
    [Actions, ImagePreviews, intl]
  )

  return (
    <DefaultLayout.PageLayout
      breadcrumbs={[
        {
          title: intl.formatMessage({ id: 'navigation.beginning' }),
        },
        {
          title: intl.formatMessage({ id: 'navigation.banners' }),
        },
      ]}
    >
      <Spinner spinning={isLoading}>
        <DefaultLayout.PageContent>
          <StyledBannersPage>
            <Table
              columns={columns}
              dataSource={banners ? banners : undefined}
            />
            <EditBannerDrawer
              open={isOpenDrawer}
              onClose={handleClose}
              banner={banner ? banner : null}
              updateBanner={updateBanner}
              updateSuccess={updateSuccess}
              isLoading={isLoading}
              setUpdateSuccess={setUpdateSuccess}
            />
          </StyledBannersPage>
        </DefaultLayout.PageContent>
      </Spinner>
    </DefaultLayout.PageLayout>
  )
}
