import styled from 'styled-components';

export const StyledPopularProducts = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.containerBgColor};

  .title {
    text-align: left;
    font-weight: 300;

    h1 {
      font-family: ${(props) => props.theme.fontText};
    }

    .products-title {
      margin-top: 0;
    }
  }

  .grid-view {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .ant-card {
    margin: 0;
  }

  @media only screen and (max-width: 1600px) {
    .grid-view {
      padding: 0px 50px 0px 50px !important;
    }
  }

  @media only screen and (max-width: 768px) {
    .grid-view {
      padding: 0px 0px 0px 0px !important;
    }

    .title {
      padding: 1em 0 0 0;
      h1 {
        font-size: 24px;
      }
    }
  }

  @media only screen and (max-width: 594px) {
    .grid-view {
      justify-content: center;
    }
  }

  @media only screen and (max-width: 440px) {
    .grid-view {
      padding: 0px 10px 0px 10px !important;
    }

    .title {
      padding: 1em 0 0 0;
      h1 {
        font-size: 18px;
      }
    }
  }
`;
