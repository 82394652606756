import { StyledPageSizeSelector } from './style'
import { Select, SelectOption } from '../Select'
import { DownOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

export interface props {
  page_size_options: number[]
  setPageSize: (size: number) => void
  value?: number
}
export const PageSizeSelector = ({
  page_size_options,
  setPageSize,
  value,
}: props) => {
  const intl = useIntl()

  return (
    <StyledPageSizeSelector>
      <div className={'txt-icon'}>
        <div className={'text'}>
          {`${intl.formatMessage({ id: 'top_filter.per_page' })}:
          `}
        </div>
        <div className={'icon sz-sm'}>
          <Select
            placeholder={
              <DownOutlined
                style={{
                  color: 'black',
                  fontSize: '14px',
                }}
              />
            }
            showSearch={false}
            variant={'borderless'}
            value={value}
            defaultValue={20}
            onChange={(newPageSize: number) => setPageSize(newPageSize)}
            dropdownStyle={{
              minWidth: '150px',
            }}
            children={page_size_options.map((opt: number, index: number) => (
              <SelectOption value={opt} key={`${index}-${opt}`}>
                {opt}
              </SelectOption>
            ))}
          />
        </div>
      </div>
    </StyledPageSizeSelector>
  )
}
