import { useIntl } from 'react-intl'
import { useNavigate } from 'react-router-dom'
import { Icon } from 'ui'
import { StyledMobileProfielOptions } from './style'

interface MobileProfielProps {
  openCloseProfileDrawer: () => void
  orderCount: number
}

const MobileProfielOptions = ({
  openCloseProfileDrawer,
  orderCount,
}: MobileProfielProps) => {
  const intl = useIntl()
  const navigate = useNavigate()

  return (
    <StyledMobileProfielOptions>
      <div
        className={
          'mobile-profile-options-item mobile-profile-options-item-flex'
        }
        onClick={() => {
          openCloseProfileDrawer()
          navigate(`profile/my_orders`)
        }}
      >
        <div
          className={
            'mobile-profile-options-item-start mobile-profile-options-item-flex'
          }
        >
          <Icon name={'mobile-my-deliveries-icon'} />
          <p>{intl.formatMessage({ id: 'general.my_orders' })}</p>
        </div>
        <span className="order-count">{orderCount}</span>
      </div>
      <div
        className={
          'mobile-profile-options-item  mobile-profile-options-item-flex'
        }
        onClick={() => {
          openCloseProfileDrawer()
          // TODO: Redirect to Wish list: NOGA-169
        }}
      >
        <div className={'mobile-profile-options-item-start'}>
          <Icon name={'hearts-icon'} />
          <p>{intl.formatMessage({ id: 'general.whish_list' })}</p>
        </div>
        <span className="order-count">
          10 {/* // TODO: Wish list number is fulfilled at NOGA-169 */}
        </span>
      </div>
      <div
        className={'mobile-profile-options-item'}
        onClick={() => {
          openCloseProfileDrawer()
          navigate(`profile/profile_information`)
        }}
      >
        <Icon name={'profile-icon'} />
        <p>{intl.formatMessage({ id: 'general.profile_information' })}</p>
      </div>
      <div
        className={'mobile-profile-options-item'}
        onClick={() => {
          openCloseProfileDrawer()
          navigate(`profile/log-out`)
        }}
      >
        <Icon name={'logout-icon'} />
        <p>{intl.formatMessage({ id: 'general.logout_second' })}</p>
      </div>
    </StyledMobileProfielOptions>
  )
}

export default MobileProfielOptions
