import styled from 'styled-components'

export const StyledDeliveryForm = styled.div`
  margin-bottom: 28px;

  .delivery-options-container {
    margin-bottom: 24px;
    .pick-delivery-header {
      height: fit-content;
      width: 100%;
      padding: 16px 0 14px 21px;
      font-size: 18px;
      font-weight: 500;
      border-bottom: 1px solid ${(props) => props.theme.borderBottom};
    }

    .delivery-options-wrapper {
      margin: 22px 25px 0 18px;
      .delivery-radio-group {
        .ant-form-item {
          padding-bottom: 15px;
          border-bottom: 1px solid ${(props) => props.theme.borderBottom};
          .ant-radio-group {
            display: flex;
            flex-direction: column;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .delivery-options-container {
      margin-bottom: 0;
      .pick-delivery-header {
        padding: 0 0 16px 0;
        border-bottom: none;
      }
      .delivery-options-wrapper {
        margin: 0;
        .delivery-radio-group {
          .ant-form-item {
            margin-bottom: 10px;
            padding-bottom: 0;
            border-bottom: none;
          }
        }
      }
    }
  }
`
