import styled from 'styled-components';
export const StyledAllFarmsPage = styled('div')`
  .products-filters-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    max-width: 1482px;
    padding: 0 20px;
    margin: 0 auto;
    gap: 50px;

    .ant-spin-nested-loading {
      width: 100%;
      display: flex;
      justify-content: center;
    }

    .products-wrapper {
      width: 100%;

      .products {
        display: flex;
        flex-wrap: wrap;
        grid-template-columns: repeat(4, 1fr);
        padding-top: 40px;
        justify-content: space-between;
        gap: 33px;

        .ant-card {
          height: 379px;
        }

        .qmarks-wrapper {
          padding: 11px 10px 6px 10px;
          height: auto;
          min-height: 36px;

          .qmark-fm {
            height: 18px;
          }
        }

        .ant-card-cover {
          padding-left: 1px;
          padding-right: 1px;
        }

        .registered-full-address {
          display: none;
        }
      }

      .pagination {
        display: flex;
        justify-content: center;
        padding: 50px 0;
      }
    }

    .ant-select-arrow {
      color: ${(props) => props.theme.stepArrowColor};
    }
  }

  .ant-pagination-item-active,
  .ant-pagination-item-active:hover {
    background-color: ${(props) => props.theme.brand01};
    border-color: ${(props) => props.theme.brand01};
  }

  .ant-pagination-prev {
    margin-inline-end: 0;
    margin-right: 24px;
  }

  .ant-pagination-next {
    margin-left: 16px;
  }

  .ant-pagination-item-link span {
    color: ${(props) => props.theme.paginationLink};
  }

  .ant-pagination-item-active a,
  .ant-pagination-item-active:hover a {
    color: ${(props) => props.theme.white};
  }

  @media only screen and (max-width: 1200px) {
    .products-filters-container {
      justify-content: center;

      .form-container {
        display: none;
      }

      .form-container {
        padding: 0 20px 0 20px;
      }

      .products-wrapper {
        padding: 0 20px 0 20px;

        .products {
          justify-content: center;
        }
      }
    }
  }

  @media only screen and (max-width: 440px) {
    background-color: ${(props) => props.theme.brightGrey};

    .products-filters-container {
      .products-wrapper {
        padding: 0;

        .products {
          gap: 19px;

          .ant-card {
            height: auto;
            align-items: center;
          }

          .farms-card-content {
            padding-left: 13px;

            .farms-card-title {
              margin-bottom: 5px;
              padding-left: 0;
              padding-bottom: 0;
              font-size: 16px;
              font-weight: 500;
              line-height: 15px;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .qmarks-wrapper {
              height: auto;
            }

            .qmarks {
              max-width: 160px;
              height: 18px;
              overflow: hidden;
              flex-wrap: wrap;
            }
          }

          .registered-full-address {
            display: block;
            margin-bottom: 13px;
            font-size: 14px;
            font-weight: 400;
            line-height: 19px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          a {
            display: block;
            width: 100%;
          }
        }
      }
    }


  }
`;
