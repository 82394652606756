import styled from 'styled-components'

export const StyledSearchAndFilter = styled.div`
  .filter-open-btn {
    display: flex;
    height: auto;
    padding: 11px 14px !important;
    border-radius: 5px;
  }

  .filter-open-btn:hover {
    background: ${({ theme }) => theme.bgColorHover} !important;
    border-color: ${({ theme }) => theme.colorBorder} !important;
    color: ${({ theme }) => theme.white} !important;
  }

  .search-section {
    width: 100%;
    height: 75px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    background-color: ${({ theme }) => theme.inputGrey};

    .ant-input-affix-wrapper {
      border: 1px solid ${({ theme }) => theme.bgColorHover};
    }
  }

  @media only screen and (max-width: 1200px) {
    .search-section {
      padding: 0 20px;
    }

    .search-section-fixed {
      position: fixed !important;
      top: 0px !important;
      z-index: 100;
    }
  }
`
