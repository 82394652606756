import styled from 'styled-components'

export const StyledThirdHeader = styled('div')`
  &.bread-wrapper {
    border-bottom: 1px solid ${(props) => props.theme.borderBottom};
  }

  .breadcrumbs-icon {
    padding: 0 5px;
  }

  .ant-select-selector,
  .ant-select,
  .ant-form-item-control-input {
    height: 24px !important;
    min-height: 24px !important;

    .ant-select-selection-item {
      line-height: 14px !important;
      padding-top: 0 !important;
    }
  }

  @media only screen and (max-width: 1200px) {
    display: none;
  }

  .product-name-bread-wrapper {
    flex-grow: 1;
    border-bottom: 1px solid ${(props) => props.theme.borderBottom};
    padding-bottom: 13px;

    .breadcrumbs-container {
      display: flex;
      justify-content: space-between;

      .breadcrumb {
        cursor: pointer;
      }

      div {
        display: flex;
        align-items: center;

        span {
          a {
            font-size: 14px;
            text-decoration: none;
            color: ${(props) => props.theme.black};
          }
        }
      }
    }

    .product-name-and-qmarks-container {
      justify-content: space-between;
      align-items: center;
      padding: 32px 0 0 0;

      .product-name {
        font-weight: 500;
        font-size: 42px;
        line-height: 38px;
        margin: 0 0 9px;
      }

      .qmarks {
        img {
          width: 36px;
          height: 24px;
        }
      }
    }
  }
`
